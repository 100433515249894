<template>
  <div
    class="top-16 flex h-full w-full min-w-[250px] flex-col text-balance px-7 py-5 xl:sticky"
    v-bind:class="[{ '!max-w-full': is_mobile_viewport }]">
    <div
      v-if="label"
      class="text-xl font-semibold">
      {{ label }}
    </div>
    <div class="overflow-auto overscroll-contain px-1 pt-2.5">
      <transition-group
        v-if="list_refs?.length"
        appear
        name="slide-up"
        tag="ul"
        class="space-y-3 text-sm lg:text-lg/tight"
        v-bind:class="[
          { 'list-disc  pl-4': !no_decoration },
          { 'columns-3': is_mobile_viewport && $route.name === 'bulletins' }
        ]">
        <template
          v-for="ref in list_refs"
          v-bind:key="ref.target_id">
          <li
            class="cursor-pointer hover:underline"
            v-bind:class="text_class"
            v-scroll-to="vscroll_param(`#${ref.target_id}`)">
            {{ ref.label }}
          </li>
          <ul
            v-bind:key="`ul_${ref.target_id}`"
            v-if="ref.sub_list"
            class="space-y-1.5 pl-4"
            v-bind:class="{ 'list-[circle]': !no_decoration }">
            <template
              v-for="r in ref.sub_list"
              v-bind:key="r.target_id">
              <li
                class="cursor-pointer hover:underline"
                v-bind:class="text_class"
                v-scroll-to="vscroll_param(`#${r.target_id}`)">
                {{ r.label }}
              </li>
            </template>
          </ul>
        </template>
      </transition-group>

      <div
        class="leading-tight text-slate-500"
        v-else>
        Aucune rubrique disponible pour cette recherche
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useVueScrollTo } from '@/js/composable/vscrollto';
  const { vScrollTo, vscroll_param } = useVueScrollTo();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';

  const store = useStore();
  const { is_mobile_viewport } = storeToRefs(store);

  defineProps({
    list_refs: Array,
    label: {
      type: String,
      default: 'Rubriques'
    },
    no_decoration: {
      type: Boolean
    },
    text_class: String
  });
</script>
