<template>
  <div class="app-container">
    <app-modal
      no_trigger_button
      v-bind:static_display="show_avertissement"
      v-on:close="show_avertissement = false"
      max_width="max-w-screen-lg">
      <template v-slot:header>Avertissement</template>
      <template v-slot:main>
        <p class="px-8 text-lg">
          La doctrine publiée sur SIDONI
          <strong>
            avant le 1er janvier 2024 n’est pas à jour de la recodification du
            code de commerce
          </strong>
          telle qu’elle ressort de l’ordonnance n°2023-1142 du 6 décembre 2023
          relative à la publication et à la certification d’informations en
          matière de durabilité et aux obligations environnementales, sociales
          et de gouvernement d’entreprise des sociétés commerciales, du décret
          n°2023-1394 du 30 décembre 2023 pris en application de ladite
          ordonnance et des arrêtés du 28 décembre 2023.
        </p>
      </template>
      <template v-slot:footer>
        <div class="p-4">
          <div class="mb-5 flex w-full items-center justify-center">
            <button
              v-on:click="closeAndSetFutureDisplay"
              class="inline-flex w-48 max-w-full items-center justify-center gap-3 truncate rounded bg-[#004787] px-3 py-2.5 text-base leading-none text-white shadow hover:bg-[#004787]/90">
              OK
            </button>
          </div>
          <div class="w-full">
            <app-input-checkbox
              label="Ne plus afficher"
              v-bind:value="hide_avertissement_checkbox"
              v-on:input="
                hide_avertissement_checkbox = !hide_avertissement_checkbox
              " />
          </div>
        </div>
      </template>
    </app-modal>
    <!-- <app-svg-icons /> -->
    <app-update />

    <!-- Tailwind breakpoints reference -->
    <div
      v-if="env_mode === 'development'"
      class="fixed bottom-5 left-5 z-50 flex w-12 items-center justify-center rounded-full bg-black/75 px-2.5 py-1.5 text-xs leading-none text-white *:font-bold">
      <span class="inline-flex sm:hidden">XS</span>
      <span class="hidden sm:inline-flex md:hidden">SM</span>
      <span class="hidden md:inline-flex lg:hidden">MD</span>
      <span class="hidden lg:inline-flex xl:hidden">LG</span>
      <span class="hidden xl:inline-flex 2xl:hidden">XL</span>
      <span class="hidden 2xl:inline-flex">2XL</span>
    </div>

    <app-nav-top
      ref="navbar"
      class="print:hidden" />

    <main
      role="main"
      v-bind:class="[is_mobile_viewport ? 'pt-[50px]' : 'pt-[120px]']"
      class="print:pt-0">
      <app-hero v-if="hasHero" />
      <router-view v-slot:default="{ Component }">
        <transition
          name="fade-router"
          mode="out-in">
          <div
            v-bind:key="
              route.matched[0]?.name === 'doc'
                ? route.matched[0].name + route.params.doc_id
                : route.path
            ">
            <component v-bind:is="Component" />
          </div>
        </transition>
      </router-view>
    </main>

    <app-nav-scroll-top />
  </div>
  <app-footer class="footer" />
</template>

<script setup>
  const env_mode = computed(() => import.meta.env.MODE);

  import { computed, onBeforeMount, ref, watch } from 'vue';

  import AppFooter from '@/js/web-component/app-footer.vue';
  import AppHero from '@/js/web-component/app-hero.vue';
  import AppNavScrollTop from '@/js/web-component/app-nav-scroll-top.vue';
  import AppNavTop from '@/js/web-component/app-nav-top.vue';
  // import AppSvgIcons from '@/js/web-component/app-svg-icons.vue';
  import AppUpdate from '@/js/web-component/app-update.vue';
  import AppInputCheckbox from './web-component/app-input-checkbox.vue';
  import AppModal from './web-component/app-modal.vue';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { is_mobile_viewport, freeze_body_scroll } = storeToRefs(store);

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { useMatomo } from '@/js/composable/use_matomo.js';
  onBeforeMount(async () => {
    const matomo = await useMatomo();
    matomo.trackPageView();
  });

  const hasHero = computed(() => {
    return ['welcome'].includes(route.name);
  });

  const navbar = ref(null);

  import { useStorage } from '@vueuse/core';
  const hide_avertissement = useStorage('hide_avertissement', false);

  const hide_avertissement_checkbox = ref(false);
  const show_avertissement = ref(!hide_avertissement.value);

  function closeAndSetFutureDisplay() {
    show_avertissement.value = false;
    hide_avertissement.value = hide_avertissement_checkbox.value;
  }

  watch(route, () => {
    const should_hide = JSON.parse(localStorage.getItem('hide_avertissement'));
    hide_avertissement.value = should_hide;

    setTimeout(() => {
      show_avertissement.value = !hide_avertissement.value;
      hide_avertissement_checkbox.value = !show_avertissement.value;
    }, 1000);
  });

  watch(
    () => freeze_body_scroll.value,
    (freeze) => {
      if (freeze) {
        document.scrollingElement.classList.add('overflow-hidden');
      } else {
        document.scrollingElement.classList.remove('overflow-hidden');
      }
    }
  );
</script>

<style lang="scss">
  html,
  body {
    height: 100%;
    @apply scroll-smooth;
  }

  #app {
    font-size: 16px;
    color: theme('colors.neutral.700');
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  .app-container {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
</style>
