import { MIXED_TYPES } from '@/js/data/labels';

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const useLabelGetter = (label) => {
    return MIXED_TYPES[label] || capitalizeFirstLetter(label);
};

export function useExtractFacetList(uri) {
    const searchParams = new URLSearchParams(uri);
    const list = [];
    for (const [key, value] of searchParams) {
        const regex = new RegExp(/^f\[(.*)\]$/i);
        if (regex.test(key)) {
            const replace_regex = /f?[[\]']/g;
            const type = key.replace(replace_regex, '');

            const values = value.split(',').map((v) => {
                return { name: v, type };
            });
            list.push(...values);
        } else if (key === 'sort') {
            list.unshift({ name: value, type: key });
        }
    }
    return list;
}
