import config_access from '@/js/static_config_access.js';
import { defineStore } from 'pinia';
import { computed, nextTick, ref } from 'vue';

import { useTailwindBreakpoints } from '@/js/composable/use-tailwind-breakpoints.js';
import { useWindowScroll } from '@vueuse/core';

import logger from 'loglevel';

export const useStore = defineStore('store', () => {
    const { y } = useWindowScroll();
    const is_navbar_sticky = computed(() => {
        return Math.floor(y.value) > 0;
    });

    const { breakpoints } = useTailwindBreakpoints();
    const is_mobile_viewport = computed(() => {
        return breakpoints.w < breakpoints.screens.lg;
    });
    const freeze_body_scroll = ref(false);

    const DEFAULT_SEARCH_FACETS = { type: [] };
    const DEFAULT_SORT_FILTER = 'RELEVANCE';

    const viewer_mode_status = config_access.viewer_mode ? true : null;

    const authenticated = ref(viewer_mode_status);
    // oidc_mode is retrieved by lib/auth.js from the account_info cookie,
    // and used to adapt app-menu-account because all applications
    // don't support OIDC yet/at all.
    const oidc_mode = ref(false); // temporary, soon everything will be OIDC
    const fullname = ref(null);
    const privileged = ref(viewer_mode_status);
    const referrer = ref(null);
    const granter = ref(null);
    const hide_limited_access_message = ref(null);
    const home_doclist_active_tab = ref('documents');
    const responses_todo = ref(null);
    const requests_pending = ref(null);
    const requests_refused = ref(null);
    const requests_granted = ref(null);
    const requests_expired = ref(null);
    const requests_revoked = ref(null);
    const is_aglae = ref(null);
    const is_agenor = ref(null);
    const needs_training = ref(null);
    const viewer_mode = ref(config_access.viewer_mode);
    const search_current_value = ref(null);
    const search_selected_type = ref(null);
    const search_current_facets_list = ref(null);
    const search_query_params_facets = ref(DEFAULT_SEARCH_FACETS);
    const search_current_sort = ref(DEFAULT_SORT_FILTER);
    // On initialize is_debug_on avec la valeur par courante de loglevel (cf. src/js/page/support.vue pour method de chgt d'état)
    const is_debug_on = ref(logger.getLevel() <= logger.levels.DEBUG);

    async function actionResetFacets() {
        search_query_params_facets.value = DEFAULT_SEARCH_FACETS;
        search_current_sort.value = DEFAULT_SORT_FILTER;
        // We should wait nextTick to make sure the query state est à jour avant qu'un nouvelle recherche puisse se lancer
        await nextTick();
    }

    function toggleFreezeBodyScroll(value) {
        freeze_body_scroll.value = value;
    }

    return {
        authenticated,
        oidc_mode,
        fullname,
        privileged,
        referrer, // wether user is cac or not
        granter, // wether user can give VIP accesses to anyone with an account
        hide_limited_access_message,
        home_doclist_active_tab,
        responses_todo,
        requests_pending,
        requests_refused,
        requests_granted,
        requests_expired,
        requests_revoked,
        is_aglae,
        is_agenor,
        is_debug_on,
        needs_training,
        viewer_mode,
        search_current_value,
        search_selected_type,
        search_current_facets_list,
        search_query_params_facets,
        search_current_sort,
        is_navbar_sticky,
        is_mobile_viewport,
        freeze_body_scroll,
        actionResetFacets,
        toggleFreezeBodyScroll
    };
});
