<template>
  <Listbox
    v-model="selectedChoice"
    as="div"
    class="relative flex w-24 cursor-pointer items-center justify-between rounded border border-slate-200 bg-white">
    <ListboxButton
      as="div"
      class="flex w-full justify-between gap-3 py-3 pl-4 pr-3.5 text-xs font-normal leading-tight text-slate-500"
      >{{ selectedChoice.name }}

      <font-awesome-icon
        icon="chevron-down"
        class="mt-[1px] text-[#004787]"
        fixed-width />
    </ListboxButton>

    <transition name="fade-slide">
      <ListboxOptions
        class="absolute left-0 top-10 z-50 w-full overflow-auto rounded border border-slate-200 bg-white p-1 text-base shadow-lg focus:outline-none">
        <ListboxOption
          v-for="choice in choices"
          v-bind:key="choice.id"
          v-bind:value="choice"
          v-bind:disabled="choice.unavailable"
          class="px-3 py-1.5 text-xs text-slate-600 hover:bg-slate-100">
          {{ choice.name }}
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>

<script setup>
  import { ref, watchEffect } from 'vue';

  import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions
  } from '@headlessui/vue';

  const choices = [
    { id: 1, name: 'ET', elastic_value: 'ET' },
    { id: 2, name: 'OU', elastic_value: 'OU' },
    { id: 3, name: 'EXCLURE', elastic_value: 'SAUF' }
  ];

  const props = defineProps({ init_num: [Number, String] });
  const selectedChoice = ref(
    props.init_num ? choices[props.init_num] : choices[0]
  );

  const emit = defineEmits(['update:modelValue']);
  watchEffect(() => {
    emit('update:modelValue', selectedChoice.value);
  });
</script>
