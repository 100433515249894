<template>
  <app-link
    v-if="!no_target && target"
    v-bind:to="target"
    class="flex h-6 min-w-[10rem] text-xs">
    <div
      v-if="label"
      class="flex w-full items-center justify-center rounded px-3 text-center font-bold leading-none tracking-tight text-white"
      v-bind:class="[
        type_label,
        is_border ? 'default is-border' : 'default is-plain'
      ]">
      <span class="mt-0.5 w-full truncate">{{ label }}</span>
    </div>
  </app-link>
  <div
    v-else-if="no_target || (!target && label)"
    class="flex h-6 min-w-[10rem] text-xs">
    <div
      class="flex w-full items-center justify-center rounded px-3 text-center font-bold leading-none tracking-tight text-white"
      v-bind:class="[
        type_label,
        is_border ? 'default is-border' : 'default is-plain'
      ]">
      <span class="mt-0.5 w-full truncate">{{ label }}</span>
    </div>
  </div>
</template>

<script setup>
  import AppLink from './app-link.vue';

  import labels from '../data/labels';

  import { onBeforeMount, ref } from 'vue';

  import { useChangeCase } from '@vueuse/integrations/useChangeCase';

  const props = defineProps({
    item: String,
    categorie: [String, Array],
    is_border: Boolean,
    no_target: Boolean
  });

  const label = ref(null);
  const target = ref(null);
  const type_label = ref(null);

  // eslint-disable-next-line complexity
  function fetchLabelInfo() {
    if (props.categorie.includes('type')) {
      label.value = labels.labelTypeDoc[props.item]; // si label n'est pas défini, rien ne sera affiché
      type_label.value = props.item;
      if (props.item === 'norme_exe_pro') {
        target.value = '/normes-exercice-professionnel';
      } else if (props.item === 'ni') {
        target.value = '/notes-information';
      } else {
        target.value = '/types/' + props.item;
      }
    } else if (props.categorie.includes('secteur')) {
      label.value = labels.labelTypeSecteur[props.item]; // si label n'est pas défini, rien ne sera affiché
      type_label.value = 'label-default';
      target.value = '/secteurs/' + props.item;
    } else if (
      props.categorie.includes('source') ||
      props.categorie.includes('classe')
    ) {
      if (props.item == 'dmf' || props.item == 'eip') {
        type_label.value = 'eip';
        label.value = 'Dpt. EIP';
        target.value = '/eip';
      } else if (props.item == 'outils') {
        type_label.value = props.item;
        label.value = 'Outils';
        target.value = 'outils';
      } else if (props.item == 'rebond') {
        type_label.value = props.item;
        label.value = 'Rebond';
        target.value = null;
      } else {
        type_label.value = props.item;
        label.value =
          useChangeCase(props.categorie, 'sentenceCase').value +
          ' : ' +
          props.item;
        target.value = null;
      }
    } else if (props.categorie.includes('outils')) {
      // dasherize pour avoir le nom de la classe
      type_label.value =
        'outil ' + useChangeCase(props.item, 'paramCase').value;
      label.value = props.item;
      target.value = null;
    } else if (props.categorie.includes('statut')) {
      // type_label = props.item;
      label.value = labels.labelStatut[props.item];
      target.value = null;
    } else if (props.categorie.includes('sort')) {
      type_label.value = 'sort';
      label.value = labels.labelSorterFilter[props.item];
      target.value = null;
    } else {
      type_label.value = props.item;
      label.value =
        useChangeCase(props.categorie, 'sentenceCase').value +
        ' : ' +
        props.item;
      target.value = null;
    }
  }

  onBeforeMount(() => {
    fetchLabelInfo();
  });
</script>

<style lang="scss" scoped>
  @function get($var) {
    @return map-get($custom-properties, $var);
  }

  $custom-properties: (
    'is-border': v-bind(is_border)
  );

  @each $key, $value in $custom-properties {
    :root {
      --#{$key}: #{$value};
    }
  }
  .default.is-border {
    @apply bg-white text-[#004787];
  }
  .default.is-plain {
    @apply bg-[#004787];
  }

  @mixin label-color($color, $bck-color) {
    &.is-border {
      color: $bck-color;
      background-color: $color;
    }
    &.is-plain {
      color: $color;
      background-color: $bck-color;
    }
  }

  .sort {
    @include label-color(transparent, #495672);
  }

  .ancienne_norme {
    @include label-color(white, #339);
  }

  .archive {
    @include label-color(white, #d20f00);
  }

  .avis_technique {
    @include label-color(white, #495672);
  }

  .chronique_cec {
    @include label-color(white, #39c);
  }

  .chronique_cej {
    @include label-color(white, #369);
  }

  .chronique_cep {
    @include label-color(white, #3cf);
  }

  .chronique_cnp {
    @include label-color(white, #9cf);
  }

  .communication_recue {
    @include label-color(white, #f93);
  }

  .communique_cncc {
    @include label-color(white, #360);
  }

  .exemple_outil {
    @include label-color(white, #660);
  }

  .jurisprudence {
    @include label-color(white, #fc9);
  }

  .legislation {
    @include label-color(white, #963);
  }

  .norme_exe_pro {
    @include label-color(white, #399);
  }

  .norme_isa {
    @include label-color(black, #ebdbde);
  }

  .nouveau {
    @include label-color(white, #e34705);
  }

  .ouvrage {
    @include label-color(white, #9cc);
  }

  .pack-pe {
    @include label-color(white, #13a143);
  }

  .pratique_pro {
    @include label-color(white, #efd24c);
  }

  .reponse_ministerielle {
    @include label-color(white, #c63);
  }

  .support {
    @include label-color(white, #64992c);
  }

  .norme_isa {
    @include label-color(white, #594ea2);
  }

  .vie_du_conseil {
    @include label-color(white, #935ec8);
  }

  .eip {
    @include label-color(white, #3f69a2);
  }

  .rebond {
    @include label-color(white, #ff8888);
  }

  .autre_support_institutionnel,
  .bpp,
  .etude_juridique,
  .guide_pro,
  .ni,
  .pack,
  .rapport_institutionnel,
  .video {
    @include label-color(white, theme('colors.amber.500'));
  }
</style>
