<template>
  <label
    v-bind:for="name_id"
    class="group inline-flex cursor-pointer items-center gap-2 py-1.5 pl-1.5 pr-3"
    v-bind:class="[
      { 'is-checked bg-[#1C58D914]': checkbox },
      { '!cursor-not-allowed': disabled }
    ]">
    <div class="relative flex items-center rounded-full">
      <input
        v-bind:id="name_id"
        tabindex="1"
        v-bind:disabled="disabled"
        type="checkbox"
        class="border-blue-gray-200 peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border transition-all checked:border-[#4060AF] checked:bg-[#4060AF]"
        v-model="checkbox"
        v-on:keyup.enter="checkbox = !checkbox" />
      <div
        class="pointer-events-none absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3.5 w-3.5"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1">
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"></path>
        </svg>
      </div>
    </div>
    <div
      class="relative flex w-full select-none items-center gap-2.5 leading-snug text-[#3F43508F] hover:text-inherit group-[.is-checked]:text-[#4060AF]">
      <div
        class="inline-flex h-full flex-1 items-stretch first-letter:uppercase">
        {{ useLabelGetter(label) }}
      </div>
      <div class="inline-flex h-full items-stretch">{{ extra_info }}</div>
    </div>
  </label>
</template>

<script setup>
  import { useLabelGetter } from '@/js/composable/label_getter.js';
  import { onBeforeMount, onMounted, onUpdated, ref, watch } from 'vue';

  const props = defineProps({
    name_id: { type: String },
    label: { type: String },
    extra_info: { type: [Number, String] },
    value: { type: Boolean },
    disabled: { type: Boolean }
  });

  const checkbox = ref(false);
  onBeforeMount(() => {
    checkbox.value = props.value;
  });
  onUpdated(() => {
    checkbox.value = props.value;
  });

  const is_loaded_once = ref(false);
  onMounted(() => {
    is_loaded_once.value = true;
  });

  const emit = defineEmits(['input']);
  watch(checkbox, (newValue) => {
    if (is_loaded_once.value) emit('input', newValue);
  });
</script>
