<template>
  <div
    v-if="wrapped_source"
    class="mt-4 flex bg-[#D5E0ED] p-5 text-sm text-[#004787] sm:my-8 lg:pl-10 lg:text-lg">
    <span
      class="wrapper"
      v-html="wrapped_source"></span>
  </div>
</template>

<script setup>
  import { useHighlightWrap } from '@/js/composable/highlight_wrapping';
  const props = defineProps({
    source: String
  });
  const wrapped_source = useHighlightWrap(props.source);
</script>

<style lang="scss" scoped>
  .wrapper {
    :deep(hit) {
      @apply bg-white/40;
    }
  }
</style>
