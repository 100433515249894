<template>
  <footer
    class="flex h-full w-full flex-wrap items-center justify-center gap-6 bg-white p-12 lg:h-14 lg:flex-nowrap lg:justify-between print:hidden">
    <div
      class="w-full text-center text-base font-normal text-sky-800 lg:text-left">
      <app-contact-link />
    </div>
    <div
      class="w-full text-center text-base font-normal text-sky-800 lg:text-right">
      © {{ year_current }} CNCC — {{ app_version }}
    </div>
  </footer>
</template>

<script setup>
  import version_json from '../../version.json';

  import sync from '@/js/lib/sync.js';

  import { onBeforeMount, ref } from 'vue';
  import AppContactLink from './app-contact-link.vue';

  const year_current = new Date().getFullYear();
  const app_version = ref(version_json.version);

  onBeforeMount(async () => {
    app_version.value = await sync.fetchVersion();
  });
</script>
