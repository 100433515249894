const CTX_SOURCES = {
    server: 'server',
    server_alt: 'server_alt',
    search: 'search'
};

const mapper_index = {
    [CTX_SOURCES.server]: {
        attachments: 'pieceJointes',
        attachments_mapper: {
            url: 'slug',
            titre: 'name',
            type: 'mimeType'
        },
        outils_mapper: {
            nom: 'slug',
            // eslint-disable-next-line no-dupe-keys
            uri: 'name'
        },
        date_publication: 'datePublication',
        description: 'descriptionLongue',
        description_courte: 'descriptionCourte',
        'liens-outils': 'outils',
        mots_cles: 'motsCles',
        nom: 'slug'
    },
    [CTX_SOURCES.server_alt]: { nom: 'slug' },
    [CTX_SOURCES.search]: { url: 'slug' }
};

export const useDocHarmonizer = (doc) => {
    const getSlug = (val) => val.split('/').pop();
    const getDocPath = (doc_slug) => `/docs/${doc_slug}`;
    const getLinkPath = (doc_slug, link_slug) =>
        `/docs/${doc_slug}/attachments/${link_slug}`;

    let harmonized_doc = {};

    let ctx_source;

    switch (true) {
        case 'meta' in doc:
            // console.log('--- FOUND meta ---');
            ctx_source = CTX_SOURCES.server;
            break;
        case 'id' in doc:
            // console.log('--- FOUND id ---');
            ctx_source = CTX_SOURCES.search;
            break;
        default:
            // console.log('--- FOUND default ---');
            ctx_source = CTX_SOURCES.server_alt;
    }

    if (ctx_source === CTX_SOURCES.server) {
        const { meta, ...rest } = doc;

        // On applati le sous-objet meta au niveau racine
        // => { ...meta, ...rest }
        for (const [key, value] of Object.entries({ ...meta, ...rest })) {
            if (key in mapper_index[ctx_source]) {
                // On renome certaines clefs d'objet et on normalize la propriété slug
                const replace_key = [mapper_index[ctx_source][key]];
                harmonized_doc[replace_key] =
                    replace_key === 'slug' ? getSlug(value) : value;
            } else {
                harmonized_doc[key] = value;
            }
            // On normalize le path d'url à partir du slug
            harmonized_doc.url = getDocPath(harmonized_doc.slug);
        }

        if (harmonized_doc.pieceJointes.length) {
            harmonized_doc.pieceJointes = harmonized_doc.pieceJointes.map(
                (link) => {
                    const new_link = {};
                    const links_mapper =
                        mapper_index[ctx_source].attachments_mapper;

                    for (const [key, value] of Object.entries(link)) {
                        if (links_mapper[key]) {
                            // On renome certaines clefs d'objet et on normalize la propriété slug
                            const replace_key = links_mapper[key];
                            new_link[replace_key] =
                                replace_key === 'slug' ? getSlug(value) : value;
                        } else {
                            new_link[key] = value;
                        }

                        // On normalize le path d'url à partir du slug de lien
                        new_link.url = getLinkPath(
                            harmonized_doc.slug,
                            new_link.slug
                        );
                    }
                    return new_link;
                }
            );
        }
        if (harmonized_doc.outils?.length) {
            harmonized_doc.outils = harmonized_doc.outils.map((link) => {
                const new_link = {};
                const links_mapper = mapper_index[ctx_source].outils_mapper;

                for (const [key, value] of Object.entries(link)) {
                    if (links_mapper[key]) {
                        // On renome certaines clefs d'objet et on normalize la propriété slug
                        const replace_key = links_mapper[key];

                        if (replace_key === 'slug') {
                            new_link[replace_key] = getSlug(value);
                        } else if (replace_key === 'name') {
                            new_link[replace_key] =
                                value.split('/').pop().toUpperCase() +
                                `.${link.extension}`;
                        } else {
                            new_link[replace_key] = value;
                        }
                    } else {
                        new_link[key] = value;
                    }

                    // On normalize le path d'url à partir du slug de lien
                    new_link.url = link.uri;
                }
                return new_link;
            });
        }
    } else if (ctx_source === CTX_SOURCES.search) {
        const add_highlight_boolean = (target) => {
            harmonized_doc[target] = harmonized_doc[target].map((item) => {
                item.highlight = true;
                return item;
            });
        };

        const merge_with_highlight = (target, byKey) => {
            const source_array = harmonized_doc[target];
            const highlight_array = harmonized_doc[`${target}Highlight`];

            const idxs = highlight_array
                .map((elem, index) => {
                    const idx = source_array.findIndex((e) => {
                        return e[byKey] === elem[byKey];
                    });

                    return { source_index: idx, highlight_index: index };
                })
                .sort();
            // console.dir(target, idxs);

            idxs.forEach(({ source_index, highlight_index }) => {
                source_array[source_index] = highlight_array[highlight_index];
            });
            return source_array;
        };

        /* -- 1/ Traitements sur le Doc -- */

        // On initialize harmonized_doc
        harmonized_doc = doc;

        // On ajoute la propriété slug
        harmonized_doc[mapper_index[ctx_source].url] = harmonized_doc.url;
        // On normalize le path d'url à partir du slug
        harmonized_doc.url = getDocPath(harmonized_doc.slug);

        /* -- 2/ Traitements sur pieceJointes -- */

        // On tag pieceJointesHighlight avec un boolean {highlight : true}
        add_highlight_boolean('pieceJointesHighlight');
        // On merge dans pieceJointes le delta avec pieceJointesHighlight
        merge_with_highlight('pieceJointes', 'slug');

        // On normalize des données dans pieceJointes
        harmonized_doc.pieceJointes?.map(
            (link) =>
                (
                    // On normalize le path d'url à partir du slug de lien
                    (link.url = getLinkPath(harmonized_doc.slug, link.slug)),
                    // On normalize le mimeType
                    (link.mimeType = link.mimeType.replace(/\W/g, '-'))
                )
        );

        /* -- 3/ Traitements sur outils -- */

        // On tag outilsHighlight avec un boolean {highlight : true}
        add_highlight_boolean('outilsHighlight');
        // On merge dans outils le delta avec outilsHighlight
        merge_with_highlight('outils', 'url');

        // On normalize des données dans pieceJointes
        harmonized_doc.outils = harmonized_doc.outils?.map((outil) => {
            // On normalize le mimeType
            const pieceJointes = outil.pieceJointes && outil.pieceJointes[0];

            if (pieceJointes) {
                (pieceJointes.url = getLinkPath(outil.url, pieceJointes.slug)),
                    (pieceJointes.mimeType = pieceJointes.mimeType.replace(
                        /\W/g,
                        '-'
                    ));
            }
            return { ...outil, ...pieceJointes };
        });
    } else if (ctx_source === CTX_SOURCES.server_alt) {
        harmonized_doc = { ...doc };

        // On ajoute la propriété slug
        harmonized_doc[mapper_index[ctx_source].nom] = harmonized_doc.nom;
        // On normalize le path d'url à partir du slug
        harmonized_doc.url = getDocPath(harmonized_doc.slug);
    }

    return harmonized_doc;
};
