/**
 * This module groups together all communications and synchronization with the
 * online service.
 */

import axios from 'axios';
import logger from 'loglevel';
import moment from 'moment';

// cf. http://en.wikipedia.org/wiki/List_of_HTTP_header_fields#Requested-With
const headers_xhr = { 'X-Requested-With': 'XMLHttpRequest' };

const CHECK_UPDATES_INITIAL_DELAY = 1 * 1000; // 1s
const CHECK_UPDATES_SUBSEQUENT_DELAY = 10 * 60 * 1000; // 10min

/**
 * Fetches a Doc object corresponding to the given id (raw JSON)
 *
 * @param {string} id of the doc
 * @returns {Function} a promise returning a JSON metadata object
 */
function fetchHasOuvrage(id) {
    return axios
        .get('/docs/ouvrages/' + id, {
            headers: headers_xhr
        })
        .then((res) => {
            return res.data;
        });
}

/**
 * Fetches a Doc object corresponding to the given id (raw JSON)
 *
 * @param {string} id of the doc
 * @returns {Function} a promise returning a JSON metadata object
 */
function fetchDoc(id) {
    return axios
        .get('/docs/' + id + '?view=metadata', {
            headers: headers_xhr
        })
        .then((res) => {
            return { id, ...res.data };
        });
}

/**
 * Fetches a highlighted version of the HTML content passed as body
 * (in response highlighted terms are surronded by a <hit>*</hit> tag)
 *
 * @returns {Function} a promise
 */
function fetchDocHighlight(body) {
    return axios.post('/highlight', body);
}

/**
 * Fetches the information
 *
 * @param (String) url - the online service relative url
 * @param {Array} criteria - Objects name/value pairs
 * @returns {Function} a promise returning a list
 */
function fetchDefs(url, criteria) {
    if (criteria && criteria.length) {
        url +=
            '?' +
            criteria
                .map((criterion) => {
                    return `${criterion.name}=${criterion.val}`;
                })
                .join('&');
    }
    return axios
        .get(url, {
            // Tell the server that this request is an XMLHttpRequest request
            // (because the server serves content differently on simple HTTP
            // requests vs AJAX requests).
            headers: headers_xhr
        })
        .then((res) => {
            return res.data;
        });
}

/**
 * Fetches a list of doc definitions corresponding to the given criteria
 *
 * @param {Array} criteria - Objects name/value pairs
 * @returns {Function} a promise returning a list
 */
function fetchDocDefs(criteria) {
    return fetchDefs('/docs', criteria);
}

/**
 * @returns {Function} a promise
 */
function fetchSearchResult(body, params) {
    return axios.post('/search', body, params);
}

/**
 * Fetches the user stored queries corresponding to the given criteria
 *
 * @param {Array} criteria - Objects name/value pairs
 * @returns {Function} a promise returning a list
 */
function fetchSearchQueries(criteria) {
    return fetchDefs('/search_queries', criteria);
}

/**
 * @returns {Function} a promise
 */
function saveSearchQuery(new_item) {
    return axios.post('/search_queries', new_item);
}

/**
 * @returns {Function} a promise
 */
function deleteSearchQuery(search_query_id) {
    return axios.delete('/search_queries/' + search_query_id);
}

/**
 * @returns {Function} a promise
 */
function delay(ms) {
    return new Promise((resolve) => setTimeout(() => resolve(), ms));
}

/**
 * @param {string} version the version of the client-side reader
 * @param {Function} action the function to execute if the update is needed
 * @param {number} [initial_delay] to use instead of the default production
 *     value
 * @returns {Function} a promise
 */
function checkUpdate(clientside_version, action, initial_delay) {
    initial_delay =
        initial_delay !== undefined
            ? initial_delay
            : CHECK_UPDATES_INITIAL_DELAY;
    return delay(initial_delay).then(() => {
        return checkUpdateLoop(clientside_version, action);
    });
}

/**
 * @param {string} version the version of the client-side reader
 * @param {Function} action the function to execute if the update is needed
 * @returns {Function} a promise
 */
function checkUpdateLoop(clientside_version, action) {
    return fetchVersion().then((serverside_version) => {
        logger.debug('Server-side version:', serverside_version);

        if (clientside_version != serverside_version) {
            action();
        } else {
            logger.debug(
                'No need to update, next update check: ' +
                    moment()
                        .add(CHECK_UPDATES_SUBSEQUENT_DELAY, 'ms')
                        .format('YYYY-MM-DD HH:mm')
            );
            return delay(CHECK_UPDATES_SUBSEQUENT_DELAY).then(() => {
                return checkUpdateLoop(clientside_version, action);
            });
        }
    });
}

/**
 * @returns {Function} a promise
 */
function fetchInfo() {
    return axios.get('/info');
}

/**
 * @returns {Function} a promise
 */
function fetchVersion() {
    // Make sure the version file is never retrieved from cache
    return axios.get('/version.json?_=' + new Date().getTime()).then((res) => {
        return res.data.version;
    });
}

/**
 * @returns {Function} a promise
 */
function fetchUserSelf() {
    return axios.get('/users/self');
}

/**
 * @returns {Function} a promise
 */

const BASE_FETCH_OPTIONS = {
    // Send cookies
    credentials: 'same-origin',

    // Send and receive JSON
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
};

function fetchAccessGranter() {
    return axios.get('/users/access/grant', BASE_FETCH_OPTIONS);
}

/**
 * @returns {Function} a promise
 */
function postAccessGranter(item) {
    let options = {
        method: 'POST',
        body: JSON.stringify(item)
    };

    options = Object.assign(options, BASE_FETCH_OPTIONS);

    return fetch('/users/access/grant', options).then((res) => {
        return res.json();
    });
}

/**
 * @returns {Function} a promise
 */
function deleteAccessGranter(item) {
    let options = {
        method: 'DELETE',
        body: JSON.stringify(item)
    };

    options = Object.assign(options, BASE_FETCH_OPTIONS);

    return fetch('/users/access/grant', options).then((res) => {
        return res.json();
    });
}

export default {
    fetchHasOuvrage,
    fetchDoc,
    fetchDocHighlight,
    fetchDocDefs,
    fetchSearchResult,
    fetchSearchQueries,
    deleteSearchQuery,
    saveSearchQuery,
    checkUpdate,
    fetchInfo,
    fetchVersion,
    fetchUserSelf,
    fetchAccessGranter,
    postAccessGranter,
    deleteAccessGranter
};
