import { onMounted, onUnmounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import urls from '../lib/urls';

export const useGetUrl = (url_name) => {
    let to;
    if (window.location.pathname == '/unauthenticated') {
        // Rather redirect to the welcome page than to the unauthenticated page
        to = urls.getBaseUrl();
    } else {
        to = window.location;
    }
    const options = { to };

    const url = urls.getUrl(url_name, options);
    const parsed_url = new URL(url);

    return parsed_url.href;
};

export const useBuildUrl = (path) => {
    return urls.buildUrl(path);
};

export const useLoginUrl = () => {
    const login_url = ref(null);
    const route = useRoute();
    watchEffect(() => {
        const path = route.query?.from ? route.query.from : route.path;
        login_url.value = { path, query: { auth: true, ...route.query } };
    });
    return login_url;
};

export function useIsWindowScrolled(offset = 100) {
    const isScrolled = ref(false);
    // a composable can update its managed state over time.
    function update() {
        isScrolled.value = Math.round(this.pageYOffset) > offset;
    }

    // a composable can also hook into its owner component's
    // lifecycle to setup and teardown side effects.
    onMounted(() => window.addEventListener('scroll', update));
    onUnmounted(() => window.removeEventListener('scroll', update));

    // expose managed state as return value
    return isScrolled;
}

export const isSameArray = (a, b) =>
    a.length === b.length && a.every((element, index) => element === b[index]);

export const query_object_to_flat_array = (facets) => {
    if (!facets) {
        return facets;
    }
    return Object.values(facets).flat().sort();
};
