<template>
  <div
    v-if="ref_doc"
    v-bind:class="{
      'bg-l-2 border-l-[#D66C54]': ref_doc.statut === 'archive'
    }">
    <app-link
      v-bind:to="route_to_doc"
      class="group flex flex-col rounded-lg border border-[#EEF1F9] px-5 py-4 transition hover:shadow hover:shadow-[#EEF1F9]"
      v-bind:class="[
        ref_doc.statut === 'archive' ? 'bg-[#eeeade]/60' : 'bg-white'
      ]">
      <div
        v-if="is_updated || ref_doc.statut === 'archive'"
        class="mb-2 flex items-center">
        <div
          v-if="is_updated"
          class="flex items-center justify-center self-end rounded-sm border border-[#D66C54] bg-[#D66C54] px-1.5 py-0.5">
          <div class="text-xs font-bold leading-none text-white">
            Mis à jour
          </div>
        </div>
        <div
          v-if="ref_doc.statut === 'archive'"
          class="rounded-sm bg-[#D66C54] px-2.5 py-1.5 text-xs font-bold leading-none text-white">
          Archive
        </div>
      </div>
      <div class="mt-1 hover:text-[#004787]">
        <div
          class="mb-2 line-clamp-2 font-bold lg:line-clamp-none lg:text-[20px]"
          v-html="ref_doc.titre"></div>
        <div
          class="mb-2 line-clamp-2 text-[14px] leading-[140%] lg:line-clamp-none"
          v-bind:class="{ 'blur-[2px]': is_protected }"
          v-html="format_description"></div>
      </div>

      <div
        v-if="has_extra_infos"
        v-on:click.prevent.stop
        class="cursor-auto">
        <hr class="h-[2px] w-full bg-[#333] opacity-[0.2]" />
        <div
          class="mt-4 flex w-full flex-wrap items-start justify-between gap-2 space-y-2 self-stretch lg:flex-nowrap lg:space-y-0">
          <div class="flex w-auto flex-grow flex-wrap gap-6">
            <app-doc-attachments-list
              v-if="ref_doc.pieceJointes?.length"
              v-bind:key="'pj'"
              v-bind:links="ref_doc.pieceJointes"
              v-bind:format_func="format_link"
              v-bind:route_to_doc="route_to_doc"
              label="Pièce(s) jointe(s)"
              v-bind:is_blur="is_protected" />

            <app-doc-attachments-list
              v-if="ref_doc.outils?.length"
              v-bind:key="'ol'"
              v-bind:links="ref_doc.outils"
              v-bind:format_func="format_outil"
              v-bind:route_to_doc="route_to_doc"
              label="Outil(s) lié(s)"
              v-bind:label_path="'/outils-lies'"
              v-bind:is_blur="is_protected" />
          </div>
          <div
            class="flex w-auto flex-shrink-0 flex-col items-end justify-start gap-2">
            <div
              v-if="ref_doc.datePublication"
              class="whitespace-nowrap text-xs font-normal leading-none tracking-tight text-gray-700 opacity-60">
              {{
                useToFrDate(ref_doc.datePublication, 'Date de publication :')
              }}
            </div>

            <div class="flex w-full items-start justify-end gap-3">
              <app-doc-statut
                v-bind:is_public="ref_doc.diffusion === 'public'"
                class="!w-auto !bg-transparent" />
            </div>
          </div>
        </div>
        <div
          class="mt-1.5 flex flex-wrap items-center justify-end gap-2 space-x-3">
          <template
            v-for="type_key in ref_doc.type"
            v-bind:key="type_key">
            <app-label
              v-bind:item="type_key"
              categorie="type" />
          </template>
        </div>
        <div
          v-if="store.is_debug_on && ref_doc.score"
          class="mb-1 inline-flex items-center gap-2 text-sm text-slate-400 underline">
          <span>Score Elasticsearch : {{ ref_doc.score }}</span>
          <app-modal
            v-if="ref_doc.scoreDetails"
            max_width="max-w-screen-2xl">
            <template v-slot:button>
              <font-awesome-icon
                v-tooltip="{
                  content: `<div class='text-center max-w-sm w-full text-sm'>
              Cliquez pour visualiser le détails de calcul du score
            </div>`,
                  distance: 15,
                  html: true
                }"
                icon="circle-info"
                class="text-[#004787]"
                fixed-width />
            </template>
            <template v-slot:header>
              <div class="flex w-full flex-wrap items-center">
                Détails du score ElasticSearch:
              </div>
            </template>
            <template v-slot:main>
              <pre class="px-8">{{ ref_doc.scoreDetails }}</pre>
            </template>
          </app-modal>
        </div>
      </div>
    </app-link>
  </div>
  <div v-else>ECHEC DE CHARGEMENT...</div>
</template>

<script setup>
  import { computed, ref } from 'vue';

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { LoremIpsum } from 'lorem-ipsum';
  const lorem = new LoremIpsum();

  import { useToFrDate } from '@/js/composable/date_and_time_helpers.js';
  import { useDocHarmonizer } from '@/js/composable/doc_harmonizer.js';

  import AppDocAttachmentsList from '@/js/web-component/app-doc-attachments-list.vue';
  import AppDocStatut from '@/js/web-component/app-doc-statut.vue';
  import AppLabel from '@/js/web-component/app-label.vue';
  import AppLink from '@/js/web-component/app-link.vue';
  import AppModal from '@/js/web-component/app-modal.vue';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { privileged } = storeToRefs(store);

  const props = defineProps({
    doc: Object
  });
  const ref_doc = props.doc ? useDocHarmonizer(props.doc) : [];

  const is_updated = ref(false);
  const is_public = computed(() => {
    return ref_doc.diffusion === 'public';
  });

  const is_protected = !privileged.value && !is_public.value;

  const has_extra_infos = computed(() => {
    return Object.keys(ref_doc).some((key) =>
      ['pieceJointes', 'diffusion', 'datePublication'].includes(key)
    );
  });

  const route_to_doc = computed(() =>
    route.name === 'search-result'
      ? {
          path: ref_doc.url,
          query: route.query
        }
      : ref_doc.url
  );

  const format_description = computed(() => {
    const description = ref_doc.descriptionLongue || ref_doc.descriptionLongue;
    return is_protected
      ? description > 0
        ? lorem.generateWords(description)
        : null
      : description;
  });

  const format_link = (link) => {
    link.name =
      is_protected && Number.isInteger(link.name)
        ? lorem.generateWords(link.name)
        : link.name;

    return link;
  };

  const format_outil = (outil) => {
    outil.name =
      is_protected && Number.isInteger(outil.name)
        ? lorem.generateWords(outil.name)
        : outil.name;

    return outil;
  };
</script>
