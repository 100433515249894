<template>
  <div
    class="flex w-full items-center"
    v-tooltip.right="
      is_text_truncated && {
        content: `<div class='text-xs/none'>${link.name}</div>`,
        html: true,
        distance: 10
      }
    ">
    <div
      class="flex-0"
      v-bind:class="icon_size">
      <component
        v-bind:is="
          map_icon[link.mimeType || link.extension] || map_icon['default']
        "
        class="mx-auto h-full w-full max-w-xs data-[id=default]:fill-slate-300 data-[id=image]:fill-blue-300" />
      <!-- {{ link.mimeType || link.extension }} -->
    </div>
    <p
      ref="ref_label"
      v-if="link?.name || link?.titre"
      class="max-w-full whitespace-normal text-pretty font-normal leading-tight tracking-tight"
      v-bind:class="[text_style, text_has_no_space(link.titre || link.name)]">
      <span v-html="capitalizeFirstLetter(link.titre || link.name)" />
    </p>
  </div>
</template>

<script setup>
  import IconImage from '@/assets/img/icon-image.svg?skipsvgo';
  import IconExcel from '@/assets/img/icon-msexcel.svg?skipsvgo';
  import IconPowerPoint from '@/assets/img/icon-mspowerpoint.svg?skipsvgo';
  import IconWord from '@/assets/img/icon-msword.svg?skipsvgo';
  import IconPdf from '@/assets/img/icon-pdf.svg?skipsvgo';
  import IconDefault from '@/assets/img/icon-unknown-file.svg?skipsvgo';
  import IconZip from '@/assets/img/icon-zip.svg?skipsvgo';

  import { computed, ref } from 'vue';
  import { capitalizeFirstLetter } from '../composable/label_getter';

  const map_icon = {
    zip: IconZip,
    'application-zip': IconZip,
    pdf: IconPdf,
    'application-pdf': IconPdf,
    doc: IconWord,
    docx: IconWord,
    'application-msword': IconWord,
    'application-vnd-openxmlformats-officedocument-wordprocessingml-document':
      IconWord,
    'application-rtf': IconWord,
    xls: IconExcel,
    xlsx: IconExcel,
    'application-vnd-openxmlformats-officedocument-spreadsheetml-sheet':
      IconExcel,
    'application-vnd-ms-excel': IconExcel,
    ppt: IconPowerPoint,
    pps: IconPowerPoint,
    pptx: IconPowerPoint,
    'application-vnd-ms-powerpoint': IconPowerPoint,
    'application-vnd-openxmlformats-officedocument-presentationml-presentation':
      IconPowerPoint,
    'image-jpeg': IconImage,
    'image-png': IconImage,
    default: IconDefault
  };

  const ref_label = ref(null);

  const is_text_truncated = computed(() => {
    return (
      ref_label?.value &&
      ref_label.value.clientWidth < ref_label.value.scrollWidth
    );
  });

  defineProps({
    link: Object,
    icon_size: {
      type: String,
      default: 'w-16'
    },
    text_style: {
      type: String,
      default: 'text-inherit'
    },
    truncable: Boolean
  });

  const text_has_no_space = (str) => {
    const regex = /\s+/g;
    const split = str.split(regex).length - 1;

    return !split ? 'break-all' : '';
  };
</script>
