<template>
  <app-search-accueil
    class="container my-5"
    v-if="!no_searchbar" />

  <div class="flex w-full flex-wrap items-start">
    <div
      class="flex h-full w-full flex-wrap pb-16 lg:flex-nowrap lg:gap-24"
      v-bind:class="{ 'container mt-8': !no_full_page }">
      <aside
        v-if="!no_aside"
        class="flex w-full flex-1 flex-col lg:min-w-[300px]"
        v-bind:class="{
          'lg:min-w-[470px]': ['normes-exercice-professionnel'].includes(
            route.name
          )
        }">
        <div
          class="space-y-3 lg:sticky lg:top-24"
          v-bind:class="{ 'v-bind--max-height': !is_mobile_viewport }">
          <app-rubrique-titre
            v-if="label"
            v-bind:titre="label"
            class="text-[32px]" />

          <slot name="extra-aside"></slot>
          <template v-if="!is_entry_page">
            <div
              class="inline-flex flex-wrap items-baseline gap-1 text-[#3F3F3D]">
              <span>Retrouvez ici </span>
              <span class="font-semibold text-[#004787]">
                {{ getting_data ? ' ... ' : list_count }}
              </span>
              <span>{{
                route.name === 'outils' ||
                route.params.page_id === 'exemple_outil'
                  ? 'outils'
                  : `document${list_count > 1 ? 's' : ''}`
              }}</span>
            </div>
            <form
              v-on:reset="resetList()"
              v-on:submit.prevent
              class="flex h-12 w-full items-center overflow-hidden rounded-md border border-[#DBE1F2] focus-within:ring-2 focus-within:!ring-slate-300">
              <div class="flex h-full flex-grow items-center bg-white pr-3">
                <input
                  ref="local_searchbar"
                  v-focus
                  v-model="filtering_search"
                  type="search"
                  placeholder="Rechercher dans la page"
                  class="flex h-full flex-grow truncate pl-3 outline-none" />

                <button
                  v-bind:disabled="filtering_search.length <= 0"
                  type="reset"
                  class="flex h-4 w-4 items-center justify-center">
                  <IconRemove
                    class="h-full w-full fill-[#A1B4CA] opacity-0 transition-opacity duration-500 hover:fill-slate-600"
                    v-bind:class="{
                      'opacity-100': filtering_search.length > 0
                    }" />
                </button>
              </div>
              <div class="flex h-full items-center justify-center">
                <button
                  type="submit"
                  class="flex h-full w-12 items-center justify-center bg-slate-100">
                  <IconSearch class="h-6 w-full fill-[#A1B4CA]" />
                </button>
              </div>
            </form>

            <app-rubrique-scrollto
              v-if="scrollable_rubriques"
              ref="rubrique_scrollto_overflow"
              class="h-full overflow-auto overscroll-contain"
              v-bind:list_refs="scrollable_rubriques" />
          </template>

          <div
            v-if="is_mobile_viewport && display_choices_list"
            v-on:click="expand_list = !expand_list"
            class="flex cursor-pointer items-center justify-between border-b border-[#3F435029] pb-1.5 pr-1.5 pt-4 uppercase text-[#A1B4CA] hover:text-[#577ca7]">
            <span>Sélectioner une rubrique</span>
            <font-awesome-icon
              v-bind:icon="!expand_list ? 'caret-right' : 'caret-down'"
              fixed-width
              size="sm" />
          </div>
          <div
            v-if="display_choices_list"
            ref="filters_overflow"
            class="h-full overflow-auto overscroll-contain">
            <slot
              name="aside"
              v-bind:is_expand="expand_list"></slot>
          </div>
        </div>
      </aside>

      <section
        ref="content_list"
        class="flex w-full flex-col">
        <slot name="section"></slot>
        <div
          v-if="is_entry_page"
          class="flex h-full flex-col items-center justify-center gap-10">
          <div class="text-center text-2xl text-slate-500 lg:text-4xl">
            Choisissez une catégorie pour commencer
          </div>
          <div class="w-16">
            <IconListcheck class="fill-slate-500/50" />
          </div>
        </div>
        <app-spinner v-else-if="getting_data" />
        <template v-else>
          <div class="sticky top-20 z-50 my-4 flex w-full justify-end">
            <Listbox v-model="sort_value">
              <ListboxButton
                class="flex w-full items-baseline gap-3 rounded border bg-white/70 px-5 py-1.5 text-[#787E8E] backdrop-blur-sm lg:w-auto">
                <span>Trier&nbsp;: </span>
                <span v-html="`${activeFilter(sort_value).name}`"></span>
                <IconVectorDown />
              </ListboxButton>

              <ListboxOptions
                class="absolute right-0 top-10 z-50 w-64 cursor-pointer overflow-hidden rounded-md bg-white shadow-md">
                <ListboxOption
                  v-for="filter in filters"
                  v-bind:key="filter.id"
                  v-bind:value="filter.value"
                  as="template"
                  v-slot:default="{ active }"
                  v-bind:disabled="filter.value === 'DEFAULT'">
                  <li
                    v-bind:class="{
                      'text-slate-300': filter.value === 'DEFAULT',
                      'bg-neutral-100': filter.value !== 'DEFAULT' && active,
                      'bg-white text-[#3F4350]': !active
                    }"
                    class="p-3"
                    v-html="filter.name" />
                </ListboxOption>
              </ListboxOptions>
            </Listbox>
          </div>

          <app-doc-list
            v-if="list_type === 'single_sublist'"
            v-bind:documents="rubrique_list"
            v-bind:max_item_to_display="current_max_item_nb" />

          <template v-else-if="list_type === 'multiple_sublist'">
            <template
              v-if="list_count"
              v-for="rubrique in rubrique_list"
              v-bind:key="rubrique.type">
              <template v-if="'sub_list' in rubrique">
                <app-rubrique-label
                  v-bind:id="rubrique.type"
                  v-bind:label="rubrique.label"
                  class="lg:!text-3xl" />
                <template
                  v-for="sub in rubrique.sub_list"
                  v-bind:key="sub.type">
                  <app-doc-list
                    v-bind:id="sub.type"
                    v-bind:titre="sub.label"
                    v-bind:documents="sub.articles"
                    v-bind:max_item_to_display="current_max_item_nb"
                    class="mt-8" />
                </template>
              </template>
              <template v-else>
                <app-rubrique-label
                  v-bind:id="rubrique.type"
                  v-bind:label="rubrique.label"
                  class="lg:!text-3xl" />
                <app-doc-list
                  v-bind:documents="rubrique.articles"
                  v-bind:max_item_to_display="current_max_item_nb" />
              </template>
            </template>
            <template v-else>
              <!-- Provide shim component to display empty app-doc-list view -->
              <app-doc-list v-bind:documents="[]" />
            </template>
          </template>

          <app-doc-list
            v-else-if="list_type === 'default_list'"
            v-bind:documents="rubrique_list"
            v-bind:max_item_to_display="current_max_item_nb" />
        </template>
        <div
          class="h-1 w-full"
          v-intersection-observer="onIntersectionObserver"></div>
      </section>
    </div>
  </div>
</template>

<script setup>
  import IconListcheck from '@/assets/img/icon-listcheck.svg?skipsvgo';
  import IconRemove from '@/assets/img/icon-remove.svg?skipsvgo';
  import IconSearch from '@/assets/img/icon-search.svg?skipsvgo';

  import AppDocList from '@/js/web-component/app-doc-list.vue';
  import AppRubriqueLabel from '@/js/web-component/app-rubrique-label.vue';
  import AppRubriqueScrollto from '@/js/web-component/app-rubrique-scrollto.vue';
  import AppRubriqueTitre from '@/js/web-component/app-rubrique-titre.vue';
  import AppSearchAccueil from '@/js/web-component/app-search-accueil.vue';
  import AppSpinner from '@/js/web-component/app-spinner.vue';

  import {
    computed,
    nextTick,
    onBeforeMount,
    onMounted,
    ref,
    watch,
    watchEffect
  } from 'vue';

  import IconVectorDown from '@/assets/img/icon-vector-down.svg';
  import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions
  } from '@headlessui/vue';

  import { compareAsc, compareDesc, parse } from 'date-fns';

  import get from 'lodash.get';

  import { vIntersectionObserver } from '@vueuse/components';
  import { useElementBounding, watchThrottled } from '@vueuse/core';

  import Mark from 'mark.js/dist/mark.es6';

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { is_mobile_viewport, is_debug_on } = storeToRefs(store);

  const local_searchbar = ref(null);
  // Declaration de la directive v-focus
  const vFocus = {
    mounted: async (el, binding, vnode, prevVnode) => {
      if (!store.is_mobile_viewport) {
        el.focus();
        await nextTick();
        el.select();
      }
    }
  };

  const props = defineProps({
    list: Object,
    label: String,
    display_choices_list: Boolean,
    getting_data: Boolean,
    no_aside: Boolean,
    no_full_page: Boolean,
    no_searchbar: Boolean
  });

  // TODO: mieux factoriser l'ensemble des watchers et des appels de sorts
  function parseToFrDate(date) {
    return parse(date, 'yyyy-MM-dd', new Date());
  }
  function sortByDatesAsc(path) {
    return function (a, b) {
      const match_a = get(a, path, false);
      const match_b = get(b, path, false);
      // console.log(match_a, path, 'ASC');
      return compareAsc(
        match_a && parseToFrDate(match_a),
        match_b && parseToFrDate(match_b)
      );
    };
  }
  function sortByDatesDesc(path) {
    return function (a, b) {
      const match_a = get(a, path, false);
      const match_b = get(b, path, false);
      // console.log(match_a, path, 'DESC');
      return compareDesc(
        match_a && parseToFrDate(match_a),
        match_b && parseToFrDate(match_b)
      );
    };
  }

  function romanToInt(s) {
    const sym = {
      I: 1,
      V: 5,
      X: 10,
      L: 50,
      C: 100,
      D: 500,
      M: 1000
    };

    let result = 0;

    for (let i = 0; i < s.length; i++) {
      const cur = sym[s[i]];
      const next = sym[s[i + 1]];

      if (cur < next) {
        result += next - cur; // IV -> 5 - 1 = 4
        i++;
      } else {
        result += cur;
      }
    }

    return result;
  }

  function sortByNames(path) {
    return function (a, b) {
      const match_a = get(a, path, false);
      const match_b = get(b, path, false);
      // console.log(match_a, path, 'ALPHA');

      if (route.params?.doc_id || route.name === 'notes-information') {
        const regex = /NI\s(?<roman_numeral>.+?)\s?[.|-]/g;

        let roman_a = false;
        let roman_b = false;

        const matches_a = match_a.matchAll(regex);
        for (const match of matches_a) {
          roman_a = match.groups.roman_numeral;
          // console.log('A', roman_a, romanToInt(roman_a));
        }
        const matches_b = match_b.matchAll(regex);
        for (const match of matches_b) {
          roman_b = match.groups.roman_numeral;
          // console.log('B', roman_b, romanToInt(roman_b));
        }
        return roman_a && roman_b && romanToInt(roman_a) > romanToInt(roman_b);
      }

      return (
        match_a &&
        match_b &&
        match_a.localeCompare(match_b, 'fr', {
          numeric: true
        })
      );
    };
  }
  function sortByReverseNames(path) {
    return function (a, b) {
      const match_a = get(a, path, false);
      const match_b = get(b, path, false);
      // console.log(match_a, path, 'OMEGA');
      if (route.params?.doc_id || route.name === 'notes-information') {
        const regex = /NI\s(?<roman_numeral>.+?)\s?[.|-]/g;

        let roman_a = false;
        let roman_b = false;

        const matches_a = match_a.matchAll(regex);
        for (const match of matches_a) {
          roman_a = match.groups.roman_numeral;
          // console.log('A', roman_a, romanToInt(roman_a));
        }
        const matches_b = match_b.matchAll(regex);
        for (const match of matches_b) {
          roman_b = match.groups.roman_numeral;
          // console.log('B', roman_b, romanToInt(roman_b));
        }
        return roman_a && roman_b && romanToInt(roman_a) < romanToInt(roman_b);
      }

      return (
        match_a &&
        match_b &&
        match_b.localeCompare(match_a, 'fr', {
          numeric: true
        })
      );
    };
  }

  const route_includes = (array_list) => {
    return array_list.includes(route.params?.doc_id || route.name);
  };
  const activeFilter = (sort_key) => {
    return filters.filter((f) => f.value === sort_key)[0];
  };

  let labelSorterFilter = {
    DATE_DESC: 'Du plus récent au plus ancien',
    DATE_ASC: 'Du plus ancien au plus récent',
    ALPHA: 'Par ordre alphabétique (A > Z)',
    OMEGA: 'Par ordre alphabétique inversé (Z > A)'
  };
  if (route_includes(['referentiel-ifrs'])) {
    labelSorterFilter = Object.assign(
      {},
      { DEFAULT: 'Ordre par défaut' },
      labelSorterFilter
    );
  }
  const filters = Object.entries(labelSorterFilter).map((entry) => {
    return { value: entry[0], name: entry[1] };
  });

  // console.log(route.params?.doc_id || route.name);
  const filter_per_page = route_includes(['notes-information', 'fondamentaux'])
    ? activeFilter('ALPHA').value
    : route_includes(['referentiel-ifrs'])
      ? activeFilter('DEFAULT').value
      : activeFilter('DATE_DESC').value;
  const sort_value = ref(filter_per_page);

  const sorter = (sort_type, path_type = 'default') => {
    // console.log(path_type);
    const path_map = {
      DEFAULT: {},
      ALPHA: {
        bulletins: 'label',
        ouvrage: 'label',
        default: 'meta.titre'
      },
      OMEGA: {
        bulletins: 'label',
        ouvrage: 'label',
        default: 'meta.titre'
      },
      DATE_ASC: {
        bulletins: 'date_rubrique',
        ouvrage: 'meta.date_publication',
        default: 'meta.date_publication'
      },
      DATE_DESC: {
        bulletins: 'date_rubrique',
        ouvrage: 'meta.date_publication',
        default: 'meta.date_publication'
      }
    };
    const sort_list = {
      DEFAULT: (a, b) => 0,
      ALPHA: sortByNames(path_map[sort_type][path_type]),
      OMEGA: sortByReverseNames(path_map[sort_type][path_type]),
      DATE_ASC: sortByDatesAsc(path_map[sort_type][path_type]),
      DATE_DESC: sortByDatesDesc(path_map[sort_type][path_type])
    };

    return sort_list[sort_type];
  };

  const expand_list = ref(!is_mobile_viewport.value);
  watch(
    () => is_mobile_viewport.value,
    (is_mobile) => {
      expand_list.value = !is_mobile;
    }
  );

  const current_list = ref(null);
  const rubrique_list = ref(null);
  const scrollable_rubriques = computed(() => {
    const scrollable_list =
      list_type.value === 'multiple_sublist' &&
      rubrique_list.value.map((r) => {
        if ('sub_list' in r) {
          const sub_refs = r.sub_list.map((sub) => {
            return {
              target_id: sub.type,
              label: sub.label
            };
          });
          return { target_id: r.type, label: r.label, sub_list: sub_refs };
        } else {
          return { target_id: r.type, label: r.label };
        }
      });
    return scrollable_list;
  });

  const is_entry_page = computed(() => {
    return ['types', 'secteurs'].includes(route.name);
  });

  function fetchRubriqueList() {
    rubrique_list.value =
      list_type.value && list_type.value === 'single_sublist'
        ? current_list.value[0].articles
        : current_list.value;
  }

  watch([sort_value, rubrique_list], () => {
    list_type.value === 'multiple_sublist'
      ? rubrique_list.value
          .sort(
            sorter(
              sort_value.value,
              ['bulletins'].includes(route.name) ? route.name : 'ouvrage'
            )
          )
          .map((r) => {
            if ('sub_list' in r) {
              return r.sub_list.map((sub) =>
                sub.articles.sort(sorter(sort_value.value))
              );
            } else {
              if (r?.articles) r.articles.sort(sorter(sort_value.value));
              return r;
            }
          })
      : rubrique_list.value.sort(sorter(sort_value.value));
  });
  const list_count = computed(() => {
    return list_type.value === 'multiple_sublist'
      ? rubrique_list.value.reduce((acc, curr) => {
          if ('sub_list' in curr) {
            return (
              acc + curr.sub_list.reduce((a, c) => a + c.articles.length, 0)
            );
          } else {
            return acc + curr.articles.length;
          }
        }, 0)
      : rubrique_list.value?.length || 0;
  });

  watchEffect(() => {
    current_list.value = props.list;
  });

  watch(current_list, () => {
    fetchListType();
    fetchRubriqueList();
  });

  onBeforeMount(() => {
    current_list.value = props.list;
    fetchListType();
    fetchRubriqueList();
  });

  const list_type = ref(null);
  function fetchListType() {
    switch (true) {
      case current_list?.value.length === 1 &&
        'articles' in current_list.value[0]:
        list_type.value = 'single_sublist';
        break;

      case current_list?.value.length > 1 &&
        ('articles' in current_list.value[0] ||
          'sub_list' in current_list.value[0]):
        list_type.value = 'multiple_sublist';
        break;

      default:
        list_type.value = 'default_list';
        break;
    }

    current_max_item_nb.value =
      list_type.value !== 'multiple_sublist'
        ? DEFAULT_MAX_ITEM_VALUE
        : Infinity;
  }

  const filtering_search = ref('');
  function resetList() {
    current_list.value = [...props.list];
    filtering_search.value = '';
    local_searchbar?.value.focus();
  }

  const instance = ref(null);
  const content_list = ref(null);
  onMounted(() => {
    instance.value = new Mark(content_list.value);
  });

  watchThrottled(
    () => filtering_search?.value,
    async (mark) => {
      instance.value.unmark();

      await nextTick();
      if (mark?.length > 1) {
        instance.value.mark(mark, {
          separateWordSearch: false,
          debug: is_debug_on.value
        });
      }
    },
    { throttle: 300 }
  );
  watchThrottled(
    () => list_count.value,
    async (newCount, oldCount) => {
      const trigger = newCount > 0 && oldCount === 0;

      if (trigger && filtering_search?.value.length > 1) {
        await setTimeout(() => {
          // On attend la ré-apparation du contenu avant de lancer le process de highlighting sur le DOM
          instance.value.mark(filtering_search.value, {
            separateWordSearch: false,
            debug: is_debug_on.value
          });
        }, 500);
      }
    },
    { throttle: 300 }
  );

  const comparator_list = computed(() => {
    return list_type.value === 'single_sublist'
      ? props.list[0].articles
      : props.list;
  });

  const escapeRegExp = (str) => str.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

  // Determines if a haystack contains a needle.  Case and accent insensitive.
  // Example: normalizedContains('Le Samouraï', 'OuRàI') -> true
  //cf. https://www.davidbcalhoun.com/2019/matching-accented-strings-in-javascript/
  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const normalizedContains = (haystack, needle) => {
    const regExp = new RegExp(removeDiacritics(escapeRegExp(needle)), 'gi');
    return regExp.test(removeDiacritics(haystack));
  };
  const filterRubrique = (rubrique, filter) => {
    return rubrique.filter((doc) => {
      const titre = doc.meta.titre || doc.meta.name;
      const desc = doc.meta.description;
      const attachments_titre = (doc.meta?.attachments || [])
        .map((pj) => pj.titre)
        .join(' ');
      const outils_lies_titre = (doc.meta?.['liens-outils'] || [])
        .map((pj) => pj.titre)
        .join(' ');
      const content = titre + desc + attachments_titre + outils_lies_titre;
      return normalizedContains(content, filter);
    });
  };
  watchThrottled(
    filtering_search,
    (filtering_value) => {
      if (filtering_value && filtering_value.length > 0) {
        if (list_type.value === 'multiple_sublist') {
          rubrique_list.value = [...comparator_list.value]
            .map((rubrique) => {
              if ('sub_list' in rubrique) {
                return {
                  ...rubrique,
                  sub_list: rubrique.sub_list
                    .map((r) => {
                      return {
                        ...r,
                        articles: filterRubrique(r.articles, filtering_value)
                      };
                    })
                    .filter((sub) => sub.articles.length)
                };
              } else {
                return {
                  ...rubrique,
                  articles: filterRubrique(rubrique.articles, filtering_value)
                };
              }
            })
            .filter((r) => r.sub_list?.length || r.articles?.length);
        } else {
          rubrique_list.value = filterRubrique(
            comparator_list.value,
            filtering_value
          );
        }
      } else {
        resetList();
      }
    },
    { throttle: 300 }
  );

  const DEFAULT_MAX_ITEM_VALUE = Number(10);
  const current_max_item_nb = ref(Infinity);
  const isVisible = ref(true);
  watch(
    () => isVisible.value,
    (newVisibility) => {
      if (newVisibility) {
        if (current_max_item_nb.value <= rubrique_list.value.length) {
          current_max_item_nb.value += DEFAULT_MAX_ITEM_VALUE;
        }
      }
    }
  );
  function onIntersectionObserver([{ isIntersecting }]) {
    isVisible.value = isIntersecting;
  }

  const filters_overflow = ref(null);
  const rubrique_scrollto_overflow = ref(null);
  const overflow_element = computed(() => {
    if (filters_overflow.value) {
      return filters_overflow.value;
    } else {
      return rubrique_scrollto_overflow.value;
    }
  });

  // eslint-disable-next-line no-shadow
  const { top } = useElementBounding(
    // eslint-disable-next-line vue/no-ref-as-operand
    overflow_element
  );
  const offset_height = computed(() => {
    const top_offset = Math.sign(top.value) > -1 ? Math.floor(top.value) : 0;

    return `97vh - ${top_offset}px - ${isVisible.value ? '96px' : '0px'}`;
  });
</script>

<style lang="scss" scoped>
  :deep(mark) {
    @apply bg-[#D5E0ED]/70 #{!important};
  }

  :deep(.v-bind--max-height) {
    transition: max-height 0.1s ease-in-out;
    max-height: calc(v-bind(offset_height));
  }
</style>
