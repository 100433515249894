<template>
  <div
    ref="doc_header_container"
    v-bind:class="{ 'is-archive': is_archive }"
    class="group relative flex w-full bg-[#004787] lg:min-h-[320px] [&.is-archive]:bg-[#eeeade]">
    <div class="container flex">
      <div
        class="z-10 flex w-full flex-wrap gap-5 text-white group-[.is-archive]:!text-[#D66C54] lg:w-4/5 xl:w-3/5">
        <!-- Ce wrapper supplémentaire est nécessaire pour ne pas casser l'affichage de l'arrière plan avec le padding -->
        <div class="flex h-full w-full flex-col py-5">
          <div class="mb-5 space-y-5">
            <div>
              <app-link
                v-if="route.query?.q"
                v-bind:to="{ name: 'search-result', query: route.query }"
                class="inline-flex cursor-pointer items-center gap-2.5 rounded bg-[#D5E0ED] px-4 py-2 text-[#004787] print:hidden">
                <font-awesome-icon
                  icon="arrow-left"
                  fixed-width
                  class="mt-0.5" />
                <span>Retour aux résultats de recherche</span>
              </app-link>
            </div>
            <div
              v-if="is_archive"
              class="inline-flex bg-[#D66C54] px-2 py-1 text-sm font-bold text-white sm:px-5 sm:py-2 sm:text-base sm:tracking-[1px]">
              Archive
            </div>
            <div class="space-y-0.5">
              <app-doc-breadcrumbs v-bind:meta="meta" />

              <div class="flex w-full items-center">
                <!-- <router-link
                  v-if="has_newer_version"
                  v-bind:to="`/docs/${meta['liens-remplace-par'][0].nom}`"
                  class="truncate rounded bg-[#4060AF] px-2 py-1 text-sm font-bold text-white sm:px-5 sm:py-2 sm:text-base sm:tracking-[1px]">
                  Accéder à la version la plus récente
                </router-link> -->
              </div>
            </div>
          </div>

          <div class="mb-12 mt-6 flex h-full w-full flex-col justify-center">
            <div
              class="flex pl-1 text-base/snug font-bold lg:text-[26px] lg:text-lg lg:leading-7">
              <span v-html="wrapped_titre" />
            </div>
            <div
              v-if="
                has_newer_version ||
                has_old_version ||
                ouvrages_links?.length ||
                meta.ouvrage?.nom
              "
              class="ml-2.5 mt-2.5 flex flex-col border-l border-slate-100/30 pl-3 !text-sm/snug group-[.is-archive]:!border-[#D66C54]/30">
              <div
                v-if="ouvrages_links?.length"
                class="flex items-center gap-2">
                <div>Ouvrage :</div>
                <template
                  v-for="(o, i) in ouvrages_links"
                  v-bind:key="o.nom">
                  <app-link
                    v-bind:to="`/docs/${o.nom}`"
                    class="underline hover:text-gray-100 group-[.is-archive]:hover:text-[#004787]">
                    {{ o.titre }} {{ o.page ? `( page ${o.page} )` : '' }}
                  </app-link>
                  <div v-if="i >= 0 && i < ouvrages_links.length - 1">|</div>
                </template>
              </div>
              <div v-else-if="meta.ouvrage?.nom">
                {{ meta.ouvrage.nom }} (p. {{ meta.ouvrage.page }}) - Bulletin
                au format papier
                {{
                  meta.ouvrage?.date_publication &&
                  useToFrDate(meta.ouvrage?.date_publication, 'publié le')
                }}
              </div>

              <div
                v-if="has_newer_version"
                class="flex items-center gap-x-1">
                <div>Ce document est remplacé par&nbsp;:</div>
                <app-link
                  v-if="has_newer_version === 1"
                  v-bind:to="`/docs/${meta['liens-remplace-par'][0].nom}`"
                  class="underline hover:text-gray-100 group-[.is-archive]:hover:text-[#004787]">
                  <span
                    v-tooltip="{
                      content: `<div class='max-w-screen-md'>${meta['liens-remplace-par'][0].titre}</div>`,
                      distance: 10,
                      html: true,
                      placement: 'right'
                    }">
                    {{ has_newer_version }}
                    {{ isPlural('document', has_newer_version) }}
                  </span>
                </app-link>

                <Dropdown
                  v-else-if="has_newer_version > 1"
                  v-bind:distance="10"
                  placement="right"
                  theme="tooltip"
                  v-bind:triggers="['click']"
                  auto-hide>
                  <!-- This will be the popover reference (for the events and position) -->
                  <button
                    class="underline hover:text-gray-100 group-[.is-archive]:hover:text-[#004787]">
                    {{ has_newer_version }}
                    {{ isPlural('document', has_newer_version) }}
                  </button>

                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <ul
                      class="max-w-screen-md list-[square] space-y-1.5 pb-2 pl-3 pt-1">
                      <li
                        v-for="lr in meta['liens-remplace-par']"
                        v-bind:key="lr.nom">
                        <app-link
                          v-bind:to="`/docs/${lr.nom}`"
                          class="text-slate-100 underline hover:text-white"
                          >{{ capitalizeFirstLetter(lr.titre) }}
                        </app-link>
                      </li>
                    </ul>
                  </template>
                </Dropdown>
              </div>
              <div
                v-if="has_old_version"
                class="flex items-center gap-x-1">
                <div>Ce document remplace&nbsp;:</div>
                <app-link
                  v-if="has_old_version === 1"
                  v-bind:to="`/docs/${meta['liens-remplace'][0].nom}`"
                  class="underline hover:text-gray-100 group-[.is-archive]:hover:text-[#004787]">
                  <span
                    v-tooltip="{
                      content: `<div class='max-w-screen-md'>${meta['liens-remplace'][0].titre}</div>`,
                      distance: 10,
                      html: true,
                      placement: 'right'
                    }"
                    >{{ has_old_version }}
                    {{ isPlural('document', has_old_version) }}
                  </span>
                </app-link>
                <Dropdown
                  v-else-if="has_old_version > 1"
                  v-bind:distance="10"
                  placement="right"
                  theme="tooltip"
                  v-bind:triggers="['click']"
                  auto-hide>
                  <!-- This will be the popover reference (for the events and position) -->
                  <button
                    class="underline hover:text-gray-100 group-[.is-archive]:hover:text-[#004787]">
                    {{ has_old_version }}
                    {{ isPlural('document', has_old_version) }}
                  </button>

                  <!-- This will be the content of the popover -->
                  <template v-slot:popper>
                    <ul
                      class="max-w-screen-md list-[square] space-y-1.5 pb-2 pl-3 pt-1">
                      <li
                        v-for="lr in meta['liens-remplace']"
                        v-bind:key="lr.nom">
                        <app-link
                          v-bind:to="`/docs/${lr.nom}`"
                          class="text-slate-100 underline hover:text-white"
                          >{{ capitalizeFirstLetter(lr.titre) }}</app-link
                        >
                      </li>
                    </ul>
                  </template>
                </Dropdown>
              </div>
            </div>
          </div>

          <div class="flex w-full flex-wrap items-end lg:mb-4">
            <div class="flex w-full flex-wrap">
              <div class="mb-5 w-full">
                <div
                  v-if="meta.date_publication && meta.classe !== 'infocom'"
                  class="text-base font-normal">
                  {{ useToFrDate(meta.date_publication, 'Publié le ') }}
                </div>
              </div>
              <div class="flex w-full flex-wrap gap-x-1.5 gap-y-1">
                <app-doc-statut v-bind:is_public="is_public" />
                <template
                  v-for="key in ['type', 'secteur', 'outils']"
                  v-bind:key="key">
                  <template
                    v-for="type_key in meta[key]"
                    v-bind:key="type_key">
                    <app-label
                      v-bind:item="type_key"
                      v-bind:categorie="key"
                      is_border />
                  </template>
                </template>
              </div>
            </div>
            <app-doc-header-share-and-print v-if="is_mobile_viewport" />
          </div>
        </div>
      </div>

      <div class="relative flex h-full items-end lg:w-1/5 xl:w-2/5">
        <div class="hidden h-full w-full min-[360px]:flex">
          <IconApostrophe
            v-bind:preserveAspectRatio="svg_ratio"
            class="fill-apostrophe absolute right-[0%] top-0 h-full lg:right-[5%] xl:right-[15%] 2xl:right-[20%]" />
        </div>

        <app-doc-header-share-and-print v-if="!is_mobile_viewport" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import sync from '@/js/lib/sync.js';

  import { computed, onBeforeMount, ref } from 'vue';

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';

  const store = useStore();
  const { is_mobile_viewport } = storeToRefs(store);

  import { useToFrDate } from '@/js/composable/date_and_time_helpers.js';
  import { useHighlightWrap } from '@/js/composable/highlight_wrapping';

  import { useResizeObserver } from '@vueuse/core';

  import AppDocBreadcrumbs from './app-doc-breadcrumbs.vue';
  import AppDocHeaderShareAndPrint from './app-doc-header-share-and-print.vue';
  import AppDocStatut from './app-doc-statut.vue';
  import AppLabel from './app-label.vue';
  import AppLink from './app-link.vue';

  import IconApostrophe from '@/assets/img/icon-apostrophe.svg?component';

  import { Dropdown } from 'floating-vue';
  import { capitalizeFirstLetter } from '../composable/label_getter';

  const props = defineProps({
    nom: String,
    meta: Object
  });

  const svg_ratio = ref('none');
  const doc_header_container = ref(null);

  const ouvrages_links = ref(null);
  onBeforeMount(async () => {
    try {
      ouvrages_links.value = await sync.fetchHasOuvrage(props.nom);
    } catch (err) {
      if (err.response.status === 404) {
        // eslint-disable-next-line no-console
        console.info(
          `Aucun "ouvrage parent" correspondant pour ce document`,
          err.response.status
        );
      } else {
        // eslint-disable-next-line no-console
        console.error(
          `${err.response.status}`,
          err.response.statusText,
          err.stack
        );
      }
    }
  });

  useResizeObserver(doc_header_container, (entries) => {
    const entry = entries[0];
    const { height } = entry.contentRect;
    if (svg_ratio.value !== 'auto' && height < 428) {
      svg_ratio.value = 'xMidYMid meet';
      // console.log(height, svg_ratio.value, 'HAPPEN');
    } else if (svg_ratio.value !== 'none' && height >= 428) {
      svg_ratio.value = 'none';
      // console.log(height, svg_ratio.value, 'DEFAULT');
    }
  });

  const has_old_version = computed(() => {
    return props.meta['liens-remplace']?.length;
  });
  const has_newer_version = computed(() => {
    return props.meta['liens-remplace-par']?.length;
  });

  const is_public = computed(() => {
    return props.meta.diffusion === 'public';
  });

  const is_archive = computed(() => {
    return props.meta.statut === 'archive';
  });

  const wrapped_titre = useHighlightWrap(props.meta.titre);

  const isPlural = (str, num) => (num > 1 ? str + 's' : str);
</script>

<style lang="scss" scoped>
  :deep(hit) {
    //   background-color: red !important;
    @apply bg-[#D5E0ED]/50;
  }

  :deep(.fill-home path) {
    fill: white !important;
  }
  :deep(.fill-apostrophe path) {
    fill: #2964a6 !important;
  }
  .is-archive {
    &:deep(.fill-home path) {
      fill: #004787 !important;
    }
    &:deep(.fill-apostrophe path) {
      fill: #e9e4d6 !important;
    }
  }
</style>
