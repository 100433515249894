import { routes } from '@/js/router/navigation_tree.js';
import { createRouter, createWebHistory } from 'vue-router';

import qs from 'qs';

const parse_opts = { comma: true };
const parse = (str) => {
    return qs.parse(str, parse_opts);
};

const stringify_opts = { arrayFormat: 'comma', encode: false };
const stringify = (str) => {
    return qs.stringify(str, stringify_opts);
};

const router = createRouter({
    history: createWebHistory(),
    parseQuery: parse,
    stringifyQuery: stringify,
    scrollBehavior(to, from, savedPosition) {
        if (
            to.matched[0] &&
            ['types', 'secteurs'].includes(to.matched[0]?.name)
        ) {
            return { top: 0 };
        }
    },
    routes
});

export default router;
