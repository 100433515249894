<template>
  <div
    class="flex h-full w-full flex-wrap gap-16 lg:mt-12 lg:flex-nowrap lg:gap-24">
    <div
      class="flex w-full flex-col space-y-8 lg:w-auto lg:min-w-[405px] lg:px-2">
      <app-rubrique-bloc
        v-for="bloc in documentation_menu"
        v-bind:key="bloc.label"
        v-bind:titre="bloc.label"
        v-bind:list="bloc.links" />
    </div>
    <div class="flex w-full flex-col space-y-24">
      <app-spinner v-if="getting_data_docs || getting_data_outils" />
      <app-doc-list
        v-else
        v-bind:rubrique_path="
          store.home_doclist_active_tab === 'outils' ? '/outils' : '/docs'
        "
        v-bind:documents="
          store.home_doclist_active_tab === 'outils' ? outils : documents
        ">
        <template v-slot:titre>
          <div
            class="flex cursor-pointer space-x-3 text-slate-600 sm:space-x-5 xl:space-x-8">
            <app-rubrique-titre
              v-on:click="store.home_doclist_active_tab = 'documents'"
              titre="Derniers documents"
              class="underline-offset-8 transition-colors hover:text-slate-800 hover:underline max-2xl:!text-2xl max-sm:!text-lg max-[360px]:!text-sm"
              v-bind:class="{
                'text-slate-800 underline underline-offset-8':
                  store.home_doclist_active_tab === 'documents'
              }" />
            <div class="inline-flex border-l"></div>
            <app-rubrique-titre
              v-on:click="store.home_doclist_active_tab = 'outils'"
              titre="Derniers outils"
              class="underline-offset-8 transition-colors hover:text-slate-800 hover:underline max-2xl:!text-2xl max-sm:!text-lg max-[360px]:!text-sm"
              v-bind:class="{
                'text-slate-800 underline underline-offset-8':
                  store.home_doclist_active_tab === 'outils'
              }" />
          </div>
        </template>
      </app-doc-list>
    </div>
  </div>
</template>

<script setup>
  import AppDocList from './app-doc-list.vue';
  import AppRubriqueBloc from './app-rubrique-bloc.vue';
  import AppRubriqueTitre from './app-rubrique-titre.vue';
  import AppSpinner from './app-spinner.vue';

  import { onBeforeMount, ref } from 'vue';

  import sync from '../lib/sync';

  import { documentation_menu } from '@/js/data/menu.js';

  import { useRouter } from 'vue-router';
  const router = useRouter();

  import { useStore } from '@/js/store';
  const store = useStore();

  const getting_data_docs = ref(true);
  const getting_data_outils = ref(true);
  const documents = ref([]);
  const outils = ref([]);
  const error = ref(null);

  onBeforeMount(async () => {
    await fetchData();
  });

  async function fetchData() {
    const docs_criteria = [];
    docs_criteria.push({ name: 'classe', val: 'document' });
    docs_criteria.push({ name: 'limit', val: 5 });

    const outils_criteria = [];
    outils_criteria.push({ name: 'classe', val: 'outil' });
    outils_criteria.push({ name: 'limit', val: 5 });

    try {
      const res_docs = await sync.fetchDocDefs(docs_criteria);
      documents.value = res_docs;

      getting_data_docs.value = false;
    } catch (err) {
      //logger.error('Unexpected promise error:', err, err.stack);
      error.value = 'Problème de communication avec le serveur';

      getting_data_docs.value = false;
      router.replace('/error/' + err.response.status);
    }

    try {
      const res_outils = await sync.fetchDocDefs(outils_criteria);
      outils.value = res_outils;

      getting_data_outils.value = false;
    } catch (err) {
      //logger.error('Unexpected promise error:', err, err.stack);
      error.value = 'Problème de communication avec le serveur';
      getting_data_outils.value = false;
      router.replace('/error/' + err.response.status);
    }
  }
</script>
