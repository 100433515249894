<template>
  <div
    class="flex w-full"
    v-on:keyup.enter="processSearch">
    <div class="flex h-full w-full flex-wrap">
      <div
        class="flex h-full w-full items-start justify-start gap-2.5 lg:flex-nowrap">
        <!-- TODO: remettre ce composant lorsque la GED aura mieux qualifié l'ensemble des documents -->
        <!-- <app-type-list
          v-model="store.search_selected_type"
          class="h-14 w-full flex-1 lg:min-w-[250px]" /> -->
        <div
          class="flex h-14 w-full items-center justify-start rounded-md border border-slate-200 bg-white">
          <app-search-autocomplete
            ref="autocomplete_ref"
            class="autocomplete w-full text-base font-normal leading-7 text-slate-500"
            placeholder="Vos mots-clés"
            v-model="search_value" />
          <div class="flex items-center pr-1.5 lg:space-x-4 lg:pl-3 lg:pr-6">
            <app-search-advanced v-on:validate="transformSearchString" />
            <app-search-saved
              v-if="store.authenticated"
              v-bind:query_string="route.query.q" />
          </div>
        </div>
        <button
          ref="search_btn"
          v-on:click.prevent="processSearch"
          v-bind:disabled="!is_search_allowed"
          v-tooltip="{
            disabled: is_search_allowed,
            shown: show_tooltip,
            content:
              'Veuillez renseigner un terme de recherche avec minimum 3 caractères pour commencer'
          }"
          class="flex h-14 max-w-full flex-col items-center justify-start gap-3.5 rounded-md bg-blue-700 p-4 focus:outline-none focus:ring-4 focus:ring-blue-500 disabled:cursor-not-allowed disabled:hover:bg-blue-700/50 sm:w-96 sm:px-7">
          <div class="flex items-center justify-start gap-3">
            <font-awesome-icon
              icon="magnifying-glass"
              fixed-width
              size="xl"
              class="text-white" />
            <div class="hidden items-center justify-start py-px sm:flex">
              <div class="text-base font-bold leading-tight text-white">
                Rechercher
              </div>
            </div>
          </div>
        </button>
      </div>
      <div
        v-if="route.name === 'welcome'"
        class="z-1 mt-1 flex w-full max-w-full flex-wrap gap-3 px-2.5 text-slate-500"
        v-bind:class="{ '!text-red-500': autocomplete_ref?.max_lenght_error }">
        <div class="flex-grow text-sm/none">
          <p class="my-1">
            <strong
              >Quelques astuces pour optimiser votre recherche&nbsp;:</strong
            >
          </p>
          <ul class="list-disc pl-5 leading-tight">
            <li>
              Taper les mots clés recherchés dans la limite de
              <strong>{{ autocomplete_ref?.MAX_INPUT_LENGTH }}</strong>
              caractères.
            </li>
            <li>Éviter les phrases.</li>
            <li>
              Pour rechercher une expression <strong>exacte</strong>, utiliser
              des guillemets (ex : "EJ 2023-11")
            </li>
            <li>
              <span>Pour plus d’astuces :</span>
              <a
                target="_blank"
                class="px-1 underline hover:text-[#004787]"
                href="https://bit.ly/FAQsearchSv2"
                >cliquer ici</a
              >
            </li>
          </ul>
        </div>
        <div
          class="flex flex-shrink flex-wrap space-x-2 text-right text-sm font-semibold lg:text-base">
          <span class="text-slate-600">Une question ? Une suggestion ?</span>
          <a
            href="mailto:sidoni.v2@cncc.fr"
            class="text-[#1d4ed8] hover:underline"
            >Écrivez-nous</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import AppSearchSaved from './app-search-saved.vue';

  import AppSearchAdvanced from './app-search-advanced.vue';
  import AppSearchAutocomplete from './app-search-autocomplete.vue';

  import { computed, nextTick, ref, watch } from 'vue';

  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import { useStore } from '@/js/store';
  const store = useStore();

  import {
    isSameArray,
    query_object_to_flat_array
  } from '../composable/helper';

  const props = defineProps(['init_query']);
  const emit = defineEmits(['reload_search']);

  const autocomplete_ref = ref(null);

  const search_value = ref(props.init_query);
  const is_search_allowed = computed(
    () => search_value.value?.trim()?.length >= 3
  );
  const show_tooltip = ref(null);
  watch(
    () => search_value.value,
    () => {
      show_tooltip.value = false;
    }
  );

  async function processSearch() {
    if (is_search_allowed.value) {
      if (store.search_selected_type && store.search_query_params_facets) {
        store.search_query_params_facets.type = [
          ...new Set(store.search_query_params_facets.type).add(
            store.search_selected_type
          )
        ];
        await nextTick();
      }

      if (
        route.name === 'search-result' &&
        route.query?.q === search_value.value &&
        route.query?.f &&
        isSameArray(
          query_object_to_flat_array(store.search_query_params_facets),
          query_object_to_flat_array(route.query.f)
        )
      ) {
        emit('reload_search');
      } else {
        await store.actionResetFacets();

        const send_query = {
          q: search_value.value,
          f: store.DEFAULT_SEARCH_FACETS,
          sort: store.DEFAULT_SORT_FILTER
        };

        router.push({
          name: 'search-result',
          query: send_query
        });
      }
    } else {
      show_tooltip.value = true;
    }
  }

  async function transformSearchString(val) {
    store.search_current_value = val;
    search_value.value = store.search_current_value;
    await nextTick();
    processSearch();
  }
</script>

<style lang="scss" scoped>
  :deep(.autocomplete) {
    [placeholder] {
      text-overflow: ellipsis;
    }
  }
</style>
