export const documentation_menu = {
    list_fondamentaux: {
        label: 'Les fondamentaux',
        links: [
            { id: 1, label: 'Normes', path: '/normes-exercice-professionnel' },
            { id: 2, label: 'Code de commerce', path: '/code-de-commerce' },
            {
                id: 3,
                label: 'Code et normes de déontologie',
                path: '/code-et-normes-de-deontologie'
            },
            {
                id: 4,
                label: 'Nomination obligatoire',
                path: '/nomination-obligatoire'
            },
            {
                id: 5,
                label: 'Secret professionnel',
                path: '/secret-professionnel'
            },
            {
                id: 6,
                label: 'Principales infractions au droit des sociétés',
                path: '/principales-infractions-au-droit-des-societes'
            },
            { id: 7, label: 'Notes d’information', path: '/notes-information' }
        ]
    },
    list_reponses: {
        label: 'Les réponses',
        links: [
            { id: 1, label: 'Chroniques CEJ', path: '/types/chronique_cej' },
            { id: 2, label: 'Chroniques CEC', path: '/types/chronique_cec' },
            { id: 3, label: 'Chroniques CNP', path: '/types/chronique_cnp' },
            { id: 4, label: 'Chroniques CEP', path: '/types/chronique_cep' }
        ]
    },

    list_liens_rapides: {
        label: 'Liens rapides',
        links: [
            { id: 1, label: 'Bulletins', path: '/bulletins' },
            { id: 2, label: 'Département EIP', path: '/eip' },
            {
                id: 3,
                label: 'Tous les secteurs',
                path: '/secteurs'
            },
            { id: 4, label: 'Tous les types de documents', path: '/types' },
            {
                id: 5,
                label: 'Référentiel IFRS',
                path: '/docs/referentiel-ifrs'
            },
            { id: 6, label: 'Infocom', path: '/infocom' }
        ]
    }
};
