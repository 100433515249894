/** @type {import('tailwindcss').Config} */
import defaultTheme from 'tailwindcss/defaultTheme';

export default {
    plugins: [],
    content: ['./src/index.html', './src/**/*.{js,vue}'],
    theme: {
        extend: {
            fontFamily: {
                sans: ['Lato', ...defaultTheme.fontFamily.sans],
                'alt-sans': ['Inter', ...defaultTheme.fontFamily.sans],
                serif: ['Bodoni Moda', ...defaultTheme.fontFamily.serif],
            },
            container: {
                center: true,
                padding: {
                    DEFAULT: '1rem'
                },
            },
            flex: {
                0 : '0 0 auto'
            },
        }
    }
};