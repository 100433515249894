<template>
  <div class="inline-flex items-baseline justify-start gap-[9px]">
    <div class="border-l pl-4 text-xl">Suggestion de recherche&nbsp;:</div>
    <button
      v-for="suggest in spellcheck_suggestions"
      v-bind:key="suggest.query"
      class="flex items-center justify-start gap-1 rounded-[18px] bg-slate-100 px-4 py-1 hover:bg-slate-200/70"
      v-on:click="router.push({ query: { q: suggest.query } })">
      <div class="space-x-2 font-semibold leading-normal">
        <span class="text-slate-500">{{ suggest.query }}</span>
        <span class="text-xs text-slate-400"
          >( {{ suggest.nbResults }} résultats )</span
        >
      </div>
    </button>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  const router = useRouter();

  defineProps({
    spellcheck_suggestions: Object
  });
</script>

<style lang="scss" scoped></style>
