<template>
  <header
    class="fixed top-0 z-[9999] w-full border-b bg-white transition-shadow"
    v-bind:class="[
      is_navbar_sticky || is_mobile_viewport
        ? 'border-none bg-opacity-50 shadow-md shadow-slate-500/10 backdrop-blur'
        : 'shadow-sm'
    ]">
    <div
      class="container relative flex max-h-full w-full items-center justify-between gap-8 transition-[height] delay-75 duration-300"
      v-bind:class="[
        is_navbar_sticky && !is_mobile_viewport ? 'h-[70px]' : 'h-[120px]',
        is_mobile_viewport ? 'h-[50px]' : 'h-[120px]'
      ]">
      <div class="flex flex-grow items-center space-x-5 lg:space-x-10">
        <router-link to="/">
          <app-logo
            class="max-w-[2.5rem] fill-[#4060AF] transition-[height]"
            v-bind:class="[
              is_navbar_sticky || is_mobile_viewport ? 'h-8' : 'h-14'
            ]" />
        </router-link>

        <div class="max-h-screen flex-col lg:flex">
          <div class="text-3xl/tight font-bold">Sidoni</div>
          <Transition
            appear
            name="nav-slide-down">
            <div
              v-if="!is_navbar_sticky && !is_mobile_viewport"
              class="max-h-screen text-lg/tight">
              La base documentaire du commissariat aux comptes
            </div>
          </Transition>
        </div>
      </div>
      <template v-if="!is_mobile_viewport">
        <div class="flex flex-shrink items-center justify-end gap-8">
          <app-link
            to="/"
            class="Accueil"
            >Accueil</app-link
          >
          <app-link
            v-bind:to="portail_cncc_url"
            class="Documentation">
            Portail CNCC
          </app-link>
          <app-nav-top-menu>
            <span>Plus</span>
            <span class="flex h-3 w-3 items-center justify-center">
              <font-awesome-icon
                size="lg"
                icon="caret-down" />
            </span>
          </app-nav-top-menu>
        </div>
        <app-account-menu />
      </template>

      <div
        v-if="is_mobile_viewport"
        class="flex h-full justify-end">
        <app-nav-top-sidebar class="px-2 hover:bg-slate-100">
          <IconMenu />
        </app-nav-top-sidebar>
      </div>
    </div>
  </header>
</template>

<script setup>
  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { is_navbar_sticky, is_mobile_viewport } = storeToRefs(store);

  import { useGetUrl } from '@/js/composable/helper';

  import AppAccountMenu from '@/js/web-component/app-account-menu.vue';
  import AppLink from '@/js/web-component/app-link.vue';
  import AppLogo from '@/js/web-component/app-logo.vue';
  import AppNavTopMenu from '@/js/web-component/app-nav-top-menu.vue';
  import AppNavTopSidebar from '@/js/web-component/app-nav-top-sidebar.vue';

  import IconMenu from '@/assets/img/menu.svg';

  const portail_cncc_url = useGetUrl('public');
</script>
