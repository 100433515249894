<template>
  <component
    v-bind:is="is"
    as="div"
    v-bind="$attrs">
    <template v-for="{ id, link, label, icon, visible } in button_list">
      <app-link
        v-bind:key="id"
        v-if="link && visible"
        v-bind:to="link"
        v-on:click.stop="emit('click_triggered')"
        class="flex w-full items-center gap-4 rounded-md px-3 py-2.5 text-gray-900"
        v-bind:class="hover_class">
        <font-awesome-icon
          v-bind:icon="icon"
          fixed-width />
        <span>{{ label }}</span>
      </app-link>
    </template>
  </component>
</template>

<script setup>
  import AppLink from '@/js/web-component/app-link.vue';

  import { useBuildUrl, useGetUrl } from '@/js/composable/helper';
  import { ref, watchEffect } from 'vue';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { privileged, referrer, granter, is_mobile_viewport } =
    storeToRefs(store);

  const emit = defineEmits(['click_triggered']);
  defineProps(['is', 'active', 'hover_class']);

  const button_list = ref([]);
  watchEffect(() => {
    button_list.value = [
      {
        id: 0,
        link: useGetUrl('public'),
        label: 'Portail CNCC',
        icon: 'home',
        visible: is_mobile_viewport
      },
      {
        id: 1,
        link: useGetUrl('account'),
        label: 'Mon compte',
        icon: 'gear',
        visible: true
      },
      {
        id: 2,
        link:
          useGetUrl('account-profiles') + (referrer.value ? '/validate' : ''),
        label: referrer.value
          ? "Gérer les droits d'accès"
          : privileged.value
            ? "Gérer mon droit d'accès"
            : "Demande de droit d'accès",
        icon: 'user-plus',
        visible: true
      },
      granter.value
        ? {
            id: 3,
            link: '/acces-vip',
            label: 'Gestion des accès VIP',
            icon: 'star',
            visible: true
          }
        : {},
      {
        id: 4,
        link: '/search-queries',
        label: 'Mes recherches mémorisées',
        icon: 'list-check',
        visible: true
      },
      {
        id: 5,
        link: useBuildUrl('/user/logout'),
        label: 'Se déconnecter',
        icon: 'ban',
        visible: true
      }
    ];
  });
</script>
