<template>
  <app-attachments-viewer
    v-bind:attachments="ref_outils"
    v-if="ref_outils.length" />
  <app-spinner v-else />
</template>

<script setup>
  import AppAttachmentsViewer from '@/js/web-component/app-attachments-viewer.vue';
  import AppSpinner from '../../web-component/app-spinner.vue';

  import sync from '@/js/lib/sync';

  import { inject, onBeforeMount, ref } from 'vue';

  import { capitalizeFirstLetter } from '@/js/composable/label_getter';

  import { compareDesc, parse } from 'date-fns';

  const { outils } = inject('tabs_content');

  function parseToFrDate(date) {
    return parse(date, 'yyyy-MM-dd', new Date());
  }
  function sortByDatesDesc(a, b) {
    const match_a = a.date_publication;
    const match_b = b.date_publication;
    // console.log(match_a, path, 'ASC');
    return compareDesc(
      match_a && parseToFrDate(match_a),
      match_b && parseToFrDate(match_b)
    );
  }

  const ref_outils = ref([]);
  onBeforeMount(async () => {
    const fetcher = await Promise.all(
      await Object.values(outils.value).map(async (outil) => {
        try {
          const fetch_outil = await sync.fetchDoc(outil.nom);

          if (fetch_outil.meta?.attachments[0]) {
            const new_outil = fetch_outil.meta?.attachments[0];
            new_outil.label = capitalizeFirstLetter(outil.titre);
            new_outil.diffusion = fetch_outil.meta.diffusion;
            return new_outil;
          } else {
            const alt_outil = outil;
            alt_outil.label = outil.titre;
            alt_outil.diffusion = fetch_outil.meta.diffusion;
            return alt_outil;
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err.response.status, outil);

          return outil;
        }
      })
    );
    ref_outils.value = fetcher.sort(sortByDatesDesc);
  });
</script>
