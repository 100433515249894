<template>
  <div v-if="rubriques_list">
    <div
      v-for="rubrique in rubriques_list()"
      v-bind:key="rubrique"
      class="mb-14 flex flex-col gap-7">
      <div
        v-if="rubrique !== 'no_type'"
        class="w-full text-xl/tight font-bold text-[#D66C54]">
        {{ labelTypeDoc[rubrique] }}
      </div>
      <div
        v-else
        class="w-full text-xl/tight font-bold text-[#D66C54]">
        Document(s) sans catégorie
      </div>
      <div class="grid w-full gap-8 lg:grid-cols-3">
        <router-link
          v-bind:to="`/docs/${lien.nom}`"
          v-for="lien in rubrique_liens(rubrique)"
          v-bind:key="lien.nom">
          <div
            class="flex h-full min-h-[12rem] flex-wrap gap-5 rounded-lg border border-zinc-500 border-opacity-20 bg-white px-4 py-4">
            <div class="flex w-full items-start justify-between gap-4">
              <div
                class="flex flex-wrap gap-2.5 text-sm font-normal leading-normal text-stone-900">
                <div
                  v-if="lien.statut === 'archive'"
                  class="inline-flex items-center rounded-sm bg-[#D66C54] px-2.5 py-1.5 text-xs font-bold leading-none text-white">
                  Archive
                </div>
                <div class="leading-snug">{{ lien.titre }}</div>
              </div>
              <div
                class="flex h-12 w-12 flex-grow items-center justify-center rounded-full border border-black border-opacity-10"
                style="flex: 0 0 auto">
                <font-awesome-icon
                  icon="paperclip"
                  fixed-width />
              </div>
            </div>
            <div class="flex w-full flex-wrap gap-2 self-end">
              <template
                v-for="type_key in lien.type"
                v-bind:key="type_key">
                <app-label
                  v-bind:item="type_key"
                  categorie="type" />
              </template>
            </div>
            <div
              class="flex w-full items-end justify-end text-right text-xs text-slate-500">
              {{ useToFrDate(lien.date_publication, '', 'd MMM yyyy') }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
  import AppLabel from '@/js/web-component/app-label.vue';

  import { inject } from 'vue';

  import { useToFrDate } from '@/js/composable/date_and_time_helpers.js';

  import { labelTypeDoc } from '@/js/data/labels';
  import { compareDesc, parse } from 'date-fns';

  const { liens } = inject('tabs_content');

  function parseToFrDate(date) {
    return parse(date, 'yyyy-MM-dd', new Date());
  }

  const customSortSousRubrique = (a, b) => {
    const a_date =
      a?.date_publication !== '' ? a.date_publication : '1970-01-01';
    const b_date =
      b?.date_publication !== '' ? b.date_publication : '1970-01-01';

    if (a_date === b_date) {
      return a.titre
        ?.replace('-', ' ')
        .localeCompare(b.titre?.replace('-', ' '));
    }
    return compareDesc(parseToFrDate(a_date), parseToFrDate(b_date));
  };

  const rubriques_list = () => {
    const set = new Set();

    Object.values(liens.value).forEach((lien) => {
      if (lien.type.length) {
        lien.type.forEach((type) => {
          set.add(type);
        });
      } else {
        set.add('no_type');
      }
    });

    return [...set].sort((a, b) => a.localeCompare(b));
  };

  function rubrique_liens(r) {
    if (r === 'no_type') {
      return [
        ...Object.values(liens.value).filter((lien) => {
          return Array.isArray(lien.type) && !lien.type.length;
        })
      ].sort(customSortSousRubrique);
    } else {
      return [
        ...Object.values(liens.value).filter((lien) => {
          return lien.type.includes(r);
        })
      ].sort(customSortSousRubrique);
    }
  }
</script>
