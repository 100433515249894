<template>
  <div class="flex flex-col gap-12">
    <div
      v-if="wrapped_content"
      id="content-html"
      class="content-body"
      v-bind:class="`content-body--${current_doc.meta?.classe}`"
      v-html="wrapped_content" />
    <div
      v-if="composition_json"
      class="space-y-12 pb-12">
      <app-page-list-wrapper
        v-bind:list="composition_json"
        no_full_page
        no_searchbar />
    </div>
    <app-attachments-viewer
      v-if="attachments?.length"
      v-bind:attachments="attachments" />
    <div
      v-if="
        !wrapped_content &&
        !attachments?.length &&
        current_doc.meta.classe !== 'ouvrage'
      ">
      Aucun contenu disponible
    </div>
    <app-spinner
      v-else-if="current_doc.meta.classe === 'ouvrage' && !composition_json" />
  </div>
</template>

<script setup>
  import sync from '@/js/lib/sync';

  import { inject, onBeforeMount, ref } from 'vue';

  import { useHighlightWrap } from '@/js/composable/highlight_wrapping';

  import AppAttachmentsViewer from '@/js/web-component/app-attachments-viewer.vue';
  import AppPageListWrapper from '@/js/web-component/app-page-list-wrapper.vue';
  import AppSpinner from '@/js/web-component/app-spinner.vue';

  const { doctrine, current_doc } = inject('tabs_content');
  const raw_content = ref(doctrine.value?.html_content);
  const wrapped_content = useHighlightWrap(raw_content);

  const composition_json = ref(null);
  onBeforeMount(async () => {
    const composition_source = doctrine.value.composition;

    // On fetch les docs un par un si il existe un attr. composition
    if (composition_source) {
      composition_json.value = await Promise.all(
        composition_source.map(async (comp) => {
          comp.articles = await Promise.all(
            comp.articles.map(async (doc) => {
              const fetch_doc = await sync.fetchDoc(doc.nom);
              // On repasse l'attribut "nom" parceque le serveur le renvoie vide après le fetch du doc individuel...
              // FIXME : corriger ce bug côté sidoni-server
              return { ...fetch_doc, nom: doc.nom };
            })
          );
          return comp;
        })
      );
    }
  });

  const attachments = ref(doctrine.value?.attachments.sort());
  if (current_doc?.value.meta?.classe === 'outil') {
    attachments.value[0].label = current_doc.value.meta.titre;
  }
</script>

<style lang="scss" scoped>
  :deep(.content-body--infocom) {
    @apply gap-0.5 #{!important};
    .level-1 {
      @apply mt-12 text-2xl underline first-of-type:mt-4;
    }
    .level-2 {
      @apply mb-2 mt-4 text-xl;
    }
    .level-3 {
      @apply list-item list-inside list-[square];
    }

    @for $i from 2 through 9 {
      $v: $i + 'em';
      .level-#{$i} {
        padding-left: #{$v};
      }
    }
  }
</style>
