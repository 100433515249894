<template>
  <Menu
    as="div"
    class="relative h-full w-auto flex-col items-center justify-center bg-[#D66C54] text-white hover:bg-[#ed6445] lg:flex">
    <app-link
      v-if="!authenticated"
      login_external
      v-bind:to="login_url"
      class="flex h-full flex-col justify-center px-8 py-2 text-center font-semibold transition-[line-height]">
      <span>Connexion</span>
    </app-link>

    <MenuButton
      v-else
      class="flex h-full flex-col items-center justify-center px-8 py-2 text-center text-base/tight font-semibold transition-[line-height]">
      <span v-html="fullname" />
      <font-awesome-icon icon="caret-down" />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        class="absolute right-0 w-[280px] max-w-screen-sm rounded-md bg-white shadow-lg ring-1 ring-black/5 transition-[top] focus:outline-none"
        v-bind:class="[is_navbar_sticky ? 'top-[75px]' : 'top-[125px]']">
        <div class="px-1.5 py-3">
          <app-account-menu-list
            v-bind:is="MenuItem"
            hover_class="hover:bg-[#ed6445] hover:text-white" />
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
  import AppAccountMenuList from '@/js/web-component/app-account-menu-list.vue';
  import AppLink from '@/js/web-component/app-link.vue';

  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { authenticated, fullname, is_navbar_sticky } = storeToRefs(store);

  import { useLoginUrl } from '@/js/composable/helper';
  const login_url = useLoginUrl();
</script>
