<template>
  <transition name="fade">
    <button
      v-if="is_visible"
      v-on:click="scrollTop"
      class="fixed bottom-8 right-8 z-[999] flex h-12 w-12 items-center justify-center rounded-full bg-slate-300/50 p-4 transition-colors hover:bg-slate-500">
      <font-awesome-icon
        icon="chevron-up"
        size="lg"
        class="mt-0.5 text-white" />
    </button>
  </transition>
</template>

<script setup>
  import { useIsWindowScrolled } from '@/js/composable/helper';

  const is_visible = useIsWindowScrolled();

  function scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
</script>

<style lang="scss" scoped></style>
