import axios from 'axios';

import router from '@/js/router';

import Welcome from '@/js/page/welcome.vue';

import Doctrine from '@/js/page/_doc/doc-tab-doctrine.vue';
import DocDocumentsLies from '@/js/page/_doc/doc-tab-documents-lies.vue';
import DocOutils from '@/js/page/_doc/doc-tab-outils.vue';
import Doc from '@/js/page/_doc/index.vue';

import SearchQueries from '@/js/page/search_queries.vue';
import SearchResult from '@/js/page/search_result.vue';

import PageList from '@/js/page/page-list.vue';

import Infocom from '@/js/page/infocom.vue';

import ErrorPage from '@/js/page/error.vue';
import Support from '@/js/page/support.vue';

import AccesVip from '@/js/page/acces-vip.vue';

// Create Router and describe application's routes
const routes = [
    {
        name: 'welcome',
        path: '/',
        component: Welcome,
        meta: { title: 'Documentation', auth_required: false }
    },
    {
        name: 'acces-vip',
        path: '/acces-vip',
        component: AccesVip,
        meta: { title: 'Accès VIP', auth_required: true }
    },
    {
        name: 'support',
        path: '/support',
        component: Support,
        meta: { title: 'Support', auth_required: true }
    },
    {
        name: 'search-result',
        path: '/search-result',
        component: SearchResult,
        meta: { title: 'Résultat de la recherche', auth_required: false }
    },
    {
        name: 'search-queries',
        path: '/search-queries',
        component: SearchQueries,
        meta: { title: 'Mes recherches', auth_required: true }
    },
    {
        path: '/docs/:doc_id',
        component: Doc,
        name: 'doc',
        meta: { auth_required: false },
        children: [
            {
                // Doctrine will be rendered inside Doc's <router-view>
                // when /docs/:doc_id/ is matched
                path: '',
                name: 'doctrine',
                component: Doctrine
            },
            {
                // DocOutils will be rendered inside Doc's <router-view>
                // when /docs/:doc_id/outils-lies is matched
                path: 'outils-lies',
                name: 'outils-lies',
                component: DocOutils
            },
            {
                // DocDocumentsLies will be rendered inside Doc's <router-view>
                // when /docs/:doc_id/documents-lies is matched
                path: 'documents-lies',
                name: 'documents-lies',
                component: DocDocumentsLies
            }
        ]
    },
    {
        name: 'docs',
        path: '/docs/',
        component: PageList,
        meta: {
            title: 'Derniers documents',
            auth_required: true
        }
    },
    {
        name: 'outils',
        path: '/outils/',
        component: PageList,
        meta: {
            title: 'Derniers outils',
            auth_required: true
        }
    },
    {
        name: 'types',
        path: '/types/',
        component: PageList,
        meta: { title: 'Tous les types de document', auth_required: true }
    },
    {
        name: 'type',
        path: '/types/:page_id',
        component: PageList,
        meta: { auth_required: true, title: 'Types' }
    },
    {
        name: 'secteurs',
        path: '/secteurs/',
        component: PageList,
        meta: { title: 'Tous les secteurs', auth_required: true }
    },
    {
        name: 'secteur',
        path: '/secteurs/:page_id',
        component: PageList,
        meta: { auth_required: true, title: 'Secteurs' }
    },
    {
        name: 'notes-information',
        path: '/notes-information',
        component: PageList,
        meta: {
            title: 'Notes d’information',
            sublist_from_ouvrage: 'notes-information',
            auth_required: true
        }
    },
    {
        name: 'fondamentaux',
        path: '/fondamentaux',
        component: PageList,
        meta: {
            title: 'Les fondamentaux',
            sublist_from_ouvrage: 'fondamentaux',
            auth_required: false
        }
    },
    {
        name: 'code-de-deontologie-et-normes-de-deontologie',
        path: '/code-et-normes-de-deontologie',
        component: PageList,
        meta: {
            title: 'Code et normes de déontologie',
            sublist_from_ouvrage: 'fondamentaux',
            auth_required: false
        }
    },
    {
        name: 'code-de-commerce',
        path: '/code-de-commerce',
        component: PageList,
        meta: {
            title: 'Code de commerce',
            sublist_from_ouvrage: 'fondamentaux',
            auth_required: false
        }
    },
    {
        name: 'nomination-obligatoire',
        path: '/nomination-obligatoire',
        component: PageList,
        meta: {
            title: 'Nomination obligatoire',
            sublist_from_ouvrage: 'fondamentaux',
            auth_required: true
        }
    },
    {
        name: 'secret-professionnel',
        path: '/secret-professionnel',
        component: PageList,
        meta: {
            title: 'Secret professionnel',
            sublist_from_ouvrage: 'fondamentaux',
            auth_required: true
        }
    },
    {
        name: 'principales-infractions-au-droit-des-societes',
        path: '/principales-infractions-au-droit-des-societes',
        component: PageList,
        meta: {
            title: 'Principales infractions au droit des sociétés',
            sublist_from_ouvrage: 'fondamentaux',
            auth_required: true
        }
    },
    {
        name: 'normes-exercice-professionnel',
        path: '/normes-exercice-professionnel',
        component: PageList,
        meta: {
            title: 'Normes d’exercice professionnel',
            auth_required: false,
            custom_pagelist_data_format: true
        }
    },
    {
        name: 'eip',
        path: '/eip',
        component: PageList,
        meta: {
            title: 'Département EIP',
            auth_required: true,
            custom_pagelist_data_format: true
        }
    },
    {
        name: 'bulletins',
        path: '/bulletins',
        component: PageList,
        meta: {
            title: 'Bulletins trimestriels',
            auth_required: true,
            custom_pagelist_data_format: true
        }
    },
    {
        name: 'infocom',
        path: '/infocom',
        component: Infocom,
        meta: { title: 'Infocom (avant 2003)', auth_required: true }
    },
    {
        name: 'error',
        path: '/error/:error_id',
        component: ErrorPage,
        meta: { auth_required: false }
    },
    {
        name: 'redirect-default',
        path: '/:pathMatch(.*)*',
        beforeEnter: [matchErrorNumber]
    }
];

async function matchErrorNumber(to) {
    if (to.params.pathMatch.includes('attachments')) {
        try {
            // We test wether sidoni-server allows access to the attachment
            await axios.get(to.fullPath);
        } catch (err) {
            const status_code = err.response.status;
            const from = to.fullPath.split('/attachments')[0];

            // We push as a query param the path value "from" that represent the url of the parent document for this attachment in order to perform a redirect later in the error page (only to overcome a temporary lack of user's privileges)
            // Example : direct link to a protected attachment while not being authenticated yet will redirect to parent's doc page.
            router.push(`/error/${status_code}?from=${from}`);
        }
    } else {
        router.push(`/error/404`);
    }
}

export { routes };
