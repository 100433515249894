import {
    ALTER_DOUBLE_CHEVRONS,
    ALTER_ESPACE_INSECABLE,
    ALTER_NOTE_ANCHOR_REPLACEMENTS,
    ALTER_SEPARATOR_REPLACEMENTS
} from '@/js/data/alters_tags_def';

export const useAlterOfficeconversionHtml = (html) => {
    // suppression des eventuelles directives dir="ltr"
    // simplifie les regexp suivantes
    html = html.replace(/ dir="ltr">/g, '>');

    // remplacement des espaces insecables
    // simplifie les regexp suivantes (eg. double chevron)
    html = html.replace(ALTER_ESPACE_INSECABLE.in, ALTER_ESPACE_INSECABLE.out);

    // ajoute classe aux separateurs *** pour centrage
    for (const alter of ALTER_SEPARATOR_REPLACEMENTS) {
        html = html.replace(alter.in, alter.out);
    }

    // renome les anchor des notes et appels
    for (const alter of ALTER_NOTE_ANCHOR_REPLACEMENTS) {
        html = html.replace(alter.in, alter.out);
    }

    // supprime doublons surnumeraires - issue #65
    for (const alter of ALTER_DOUBLE_CHEVRONS) {
        html = html.replace(alter.in, alter.out);
    }

    return html;
};
