<template>
  <app-modal max_width="max-w-4xl">
    <template v-slot:button>
      <div
        class="rounded px-4 py-2 font-bold text-[#004787] transition hover:bg-slate-50">
        <font-awesome-icon
          size="sm"
          icon="eye"
          fixed-width />
        Prévisualiser
      </div>
    </template>
    <template v-slot:header>
      <div class="flex w-full flex-wrap items-center">
        <div class="w-full">Prévisualisation du document&nbsp;:</div>
        <div
          class="my-1 w-full border-l-2 pl-4 text-sm font-normal leading-tight text-slate-500 lg:mb-4 lg:mt-1.5 lg:text-base/tight">
          {{ current_attachment.label || current_attachment.titre }}
        </div>
      </div>
    </template>
    <template v-slot:main>
      <app-docx-to-html v-bind:file_link="current_attachment.url" />
    </template>
  </app-modal>
</template>

<script setup>
  import { defineAsyncComponent } from 'vue';

  import AppModal from '@/js/web-component/app-modal.vue';
  const AppDocxToHtml = defineAsyncComponent(
    () => import('@/js/web-component/app-docx-to-html.vue')
  );

  defineProps({
    current_attachment: Object
  });
</script>
