<template>
  <div
    class="flex h-full w-full flex-col items-center justify-center gap-8 px-2 py-6"
    v-bind:class="{ 'border border-[#004787]': is_bordered_box }">
    <div
      class="text-center text-base/tight font-medium text-[#004787] lg:text-xl">
      <div class="mb-2">
        L'accès aux contenus de cette page est réservé aux professionnels de
        l'audit.
      </div>
      <div class="">
        {{
          !authenticated
            ? 'Identifiez-vous pour en consulter l’intégralité.'
            : `Veuillez effectuer une demande de droit d'accès auprès d'un utilisateur autorisé`
        }}
      </div>
    </div>
    <app-link
      v-if="!authenticated"
      login_external
      v-bind:to="login_url"
      class="flex h-12 w-auto items-center justify-center rounded border border-[#004787] px-8 text-[#004787] transition-colors hover:bg-[#004787] hover:text-white lg:h-14">
      <span class="text-center text-lg font-bold lg:text-xl">Se connecter</span>
    </app-link>
    <app-link
      v-else
      login_external
      v-bind:to="useGetUrl('account-profiles')"
      class="flex h-12 w-auto items-center justify-center rounded border border-[#004787] px-8 text-[#004787] transition-colors hover:bg-[#004787] hover:text-white lg:h-14">
      <span class="text-center text-lg font-bold lg:text-xl">
        Demande de droit d'accès
      </span>
    </app-link>
  </div>
</template>

<script setup>
  import AppLink from './app-link.vue';

  import { useGetUrl, useLoginUrl } from '@/js/composable/helper';
  const login_url = useLoginUrl();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';

  const store = useStore();
  const { authenticated } = storeToRefs(store);

  defineProps({
    is_bordered_box: Boolean
  });
</script>
