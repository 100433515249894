<template>
  <div
    v-if="attachments.length"
    v-bind:class="[
      is_upscale
        ? 'fixed inset-0 z-[9999] overflow-auto p-0'
        : `flex flex-wrap py-12 lg:flex-wrap-reverse ${
            has_toc && !is_mobile_viewport ? 'xl:flex-nowrap' : 'lg:flex-nowrap'
          } `
    ]">
    <!-- Left Side View -->
    <template v-if="current_attachment">
      <div id="attachments"></div>
      <div
        v-if="is_mobile_viewport"
        class="mb-4 space-y-6">
        <Transition
          name="slide-up"
          mode="out-in">
          <div
            class="lg:text-[22px]"
            v-bind:class="
              text_has_no_space(
                current_attachment.label || current_attachment.titre
              )
            "
            v-bind:key="current_attachment.label || current_attachment.titre">
            {{ current_attachment.label || current_attachment.titre }}
          </div>
        </Transition>
        <div>
          <app-download-link
            v-bind:downloadeable="is_attachment_accessible(current_attachment)"
            v-bind:url="current_attachment.url"
            v-bind:ext="current_attachment.titre.split('.').pop()" />
        </div>
      </div>

      <transition
        name="slide-right"
        mode="out-in">
        <div
          v-bind:key="current_attachment.url"
          class="flex h-full w-full flex-shrink flex-wrap items-stretch rounded bg-white shadow-lg"
          v-bind:class="[
            is_upscale
              ? 'fixed inset-0 overflow-auto !bg-slate-50'
              : 'mb-8 py-2 lg:mb-0 lg:min-w-[560px] lg:max-w-[560px] lg:py-8'
          ]">
          <div
            v-if="
              is_attachment_accessible(current_attachment) &&
              [
                'application-vnd-openxmlformats-officedocument-wordprocessingml-document',
                'docx'
              ].includes(current_attachment.type)
            "
            class="flex w-full flex-wrap justify-end px-6">
            <app-docx-preview v-bind:current_attachment="current_attachment" />
            <app-display-outil-link
              v-bind:link="{ mimeType: current_attachment.type }"
              icon_size="w-full lg:w-56 mx-auto" />
          </div>

          <app-pdf-webview
            v-else-if="
              ['pdf', 'application-pdf'].includes(current_attachment.type)
            "
            v-bind:is_upscale="is_upscale"
            v-bind:file_link="current_attachment.url"
            v-on:change_upscale="toggleIsUpscale" />

          <div
            v-else-if="
              ['image-png', 'image-jpeg'].includes(current_attachment.type)
            "
            class="mx-8 w-full bg-slate-50">
            <img
              v-bind:src="current_attachment.url"
              class="mx-auto object-contain" />
          </div>
          <app-display-outil-link
            v-else
            v-bind:link="{ mimeType: current_attachment.type }"
            icon_size="w-56"
            class="justify-center" />
        </div>
      </transition>
    </template>

    <!-- Right Side View -->
    <div
      v-if="!is_upscale"
      class="mb-8 flex w-full flex-grow flex-col gap-5 lg:pl-8"
      v-bind:class="{ 'lg:!pl-0 xl:!pl-8': has_toc }">
      <template v-if="!is_mobile_viewport">
        <Transition
          name="slide-up"
          mode="out-in">
          <div
            class="space-y-4 leading-tight"
            v-bind:key="current_attachment.label || current_attachment.titre">
            <div
              v-if="current_attachment.statut === 'archive'"
              class="inline-flex rounded-sm bg-[#D66C54] px-2.5 py-1.5 text-xs font-bold leading-none text-white">
              Archive
            </div>
            <div
              class="text-[20px]"
              v-bind:class="
                text_has_no_space(
                  current_attachment.label || current_attachment.titre
                )
              ">
              {{ current_attachment.label || current_attachment.titre }}
            </div>
            <div
              class="text-balance text-slate-500"
              v-if="
                current_attachment.description?.toLowerCase() !==
                (current_attachment.label?.toLowerCase() ||
                  current_attachment.titre?.toLowerCase())
              ">
              {{ current_attachment.description }}
            </div>
          </div>
        </Transition>
        <div>
          <app-download-link
            v-bind:downloadeable="is_attachment_accessible(current_attachment)"
            v-bind:url="current_attachment.url"
            v-bind:ext="current_attachment.titre.split('.').pop()" />
        </div>
        <div class="text-slate-500">
          {{ useToFrDate(current_attachment.date_publication, 'Publié le') }}
        </div>
      </template>

      <template v-if="attachments.length > 1">
        <div class="flex w-full flex-wrap">
          <div class="my-4 w-full text-sm text-[#1D1D1BB2]">
            Dans la même catégorie
          </div>
          <div
            class="grid w-full gap-2 overflow-auto overscroll-contain md:grid-cols-2 md:p-2 lg:grid-cols-1 xl:grid-cols-2 2xl:max-h-[50vh]"
            v-bind:class="{ 'lg:!grid-cols-2 xl:!grid-cols-1': has_toc }">
            <template
              v-for="(attachment, index) in attachments"
              v-bind:key="attachment.url">
              <div
                v-scroll-to="scrollToCloserAnchor()"
                v-on:click="current_attachment_index = index"
                class="flex cursor-pointer flex-wrap items-start gap-6 rounded-lg border border-black border-opacity-10 bg-white p-5 transition-shadow hover:shadow"
                v-bind:class="{
                  '!cursor-not-allowed !bg-gray-100 ring-2 !ring-slate-200':
                    index === current_attachment_index
                }">
                <div class="flex w-full flex-wrap gap-3">
                  <div class="flex w-full justify-between">
                    <div
                      v-if="attachment.statut === 'archive'"
                      class="inline-flex rounded-sm bg-[#D66C54] px-2.5 py-1.5 text-xs font-bold leading-none text-white">
                      Archive
                    </div>
                    <div class="w-full text-right text-xs text-slate-500">
                      {{
                        useToFrDate(
                          attachment.date_publication,
                          '',
                          'd MMM yyyy'
                        )
                      }}
                    </div>
                  </div>
                  <app-display-outil-link
                    v-bind:link="{
                      mimeType: attachment.type,
                      name: attachment.label || attachment.titre
                    }"
                    icon_size="w-12"
                    text_style="font-semibold text-sm"
                    class="flex gap-5" />
                </div>

                <div class="flex w-full items-center justify-between gap-4">
                  <app-download-link
                    v-bind:downloadeable="is_attachment_accessible(attachment)"
                    v-bind:url="attachment.url"
                    v-bind:ext="attachment.titre.split('.').pop()"
                    v-bind:is_public="attachment.diffusion !== 'restreint'" />
                  <div
                    v-if="route.name === 'outils-lies'"
                    v-tooltip="{
                      content: `<span class='text-sm'>Consulter la page de cet outil</span>`,
                      html: true,
                      distance: 10
                    }">
                    <app-link
                      v-bind:to="`/docs/${attachment.nom}`"
                      class="flex h-10 w-10 items-center justify-center rounded-full border p-3 shadow-sm hover:bg-slate-100"
                      v-bind:class="{
                        'hover:!bg-white': index === current_attachment_index
                      }">
                      <font-awesome-icon
                        v-bind:icon="['fas', 'arrow-up-right-from-square']"
                        class="h-full w-full text-slate-500" />
                    </app-link>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
  import AppDisplayOutilLink from '@/js/web-component/app-display-outil-link.vue';
  import AppDocxPreview from '@/js/web-component/app-docx-preview.vue';
  import AppDownloadLink from '@/js/web-component/app-download-link.vue';
  import AppLink from '@/js/web-component/app-link.vue';

  const AppPdfWebview = defineAsyncComponent(
    () => import('@/js/web-component/app-pdf-webview.vue')
  );

  import {
    computed,
    defineAsyncComponent,
    inject,
    onMounted,
    ref,
    toRefs
  } from 'vue';

  import { useToFrDate } from '@/js/composable/date_and_time_helpers.js';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { privileged, is_mobile_viewport } = storeToRefs(store);

  import { useVueScrollTo } from '@/js/composable/vscrollto';
  const { vScrollTo, vscroll_param } = useVueScrollTo();

  import { useRoute } from 'vue-router';
  const route = useRoute();

  const { has_toc } = inject('tabs_content');
  const props = defineProps({
    attachments: Array
  });
  const { attachments } = toRefs(props);

  const current_attachment_index = ref(0);

  const current_attachment = computed(() => {
    return attachments.value[current_attachment_index.value];
  });

  onMounted(async () => {
    await setTimeout(() => {
      evaluateAnchorDistance();
    }, 500);
  });

  const is_attachment_accessible = (value) => {
    return value &&
      (privileged.value ||
        (!privileged.value && value.diffusion !== 'restreint'))
      ? true
      : false;
  };

  const is_upscale = ref(false);
  function toggleIsUpscale() {
    is_upscale.value = !is_upscale.value;
    store.toggleFreezeBodyScroll(is_upscale.value);
  }

  const has_wide_content_distance = ref(false);
  function evaluateAnchorDistance() {
    const content_elem = document.getElementById('content');
    const attachments_elem = document.getElementById('attachments');

    if (attachments_elem) {
      has_wide_content_distance.value =
        attachments_elem.offsetTop - content_elem.offsetTop >
        window.innerHeight * 0.25;
    }
  }

  function scrollToCloserAnchor() {
    return vscroll_param(
      has_wide_content_distance.value || is_mobile_viewport.value
        ? '#attachments'
        : '#content'
    );
  }

  const text_has_no_space = (str) => {
    const regex = /\s+/g;
    const split = str.split(regex).length - 1;

    return !split ? 'break-all' : 'text-balance';
  };
</script>
