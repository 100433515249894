<template>
  <div
    class="flex flex-wrap items-center gap-x-1.5 leading-tight group-[.is-archive]:!text-[#004787]">
    <app-link
      to="/"
      class="flex flex-0 items-center">
      <IconHome class="fill-home mb-[5px]" />
    </app-link>
    <template v-if="formated_meta.types.length">
      <div class="mb-0.5 flex flex-0 items-center">></div>
      <template
        v-for="(t, i) in formated_meta.types"
        v-bind:key="t.type">
        <component
          v-bind:is="t.url ? AppLink : 'span'"
          v-bind:to="t.url"
          class="flex flex-0 items-center space-x-1 text-center text-sm font-semibold">
          <span v-bind:class="{ 'hover:underline': t.url }">
            {{ custom_meta[t.type]?.type_label || useLabelGetter(t.type) }}
          </span>
          <span v-if="i >= 0 && i < formated_meta.types.length - 1">|</span>
        </component>
      </template>
    </template>
    <div class="mb-0.5 flex flex-0 items-center">></div>
    <div
      class="flex max-w-[90%] flex-0 items-center text-center text-sm font-bold text-[#D66C54] lg:max-w-xs">
      <span class="truncate">{{ formated_meta.label }}</span>
    </div>
  </div>
</template>

<script setup>
  import IconHome from '@/assets/img/icon-home.svg';
  import AppLink from './app-link.vue';

  import { useLabelGetter } from '@/js/composable/label_getter';
  import { computed } from 'vue';

  const props = defineProps({
    meta: Object
  });

  const custom_meta = {
    ni: {
      url: '/notes-information',
      label: props.meta.titre.split('.')[0]
    },
    dmf: {
      url: '/eip',
      type_label: 'Département EIP'
    },
    norme_exe_pro: {
      url: '/normes-exercice-professionnel',
      label: props.meta.titre.split('.')[0]
    },
    bulletin: {
      url: '/bulletins',
      type_label: 'Bulletins'
    },
    ouvrage_dynamique: {
      type_label: 'Ouvrages'
    },
    infocom: {
      url: '/infocom'
    }
  };

  const formated_meta = computed(() => {
    const default_type = {
      type: props.meta.classe,
      url: custom_meta[props.meta.classe]?.url || null,
      key: null
    };
    const source_eip =
      props.meta?.source && props.meta?.source.includes('dmf') ? ['dmf'] : [];
    const types = [
      ...props.meta.type,
      ...props.meta.secteur,
      props.meta.ouvrage_type,
      ...source_eip
    ]
      .filter((t) => t && t !== 'undefined')
      .map((type) => {
        const key = props.meta.secteur.includes(type) ? 'secteurs' : 'types';
        const url =
          type in custom_meta ? custom_meta[type].url : `/${key}/${type}`;

        return { url, type, key };
      });
    const trigger_type = types
      .filter((t) => t.type in custom_meta)
      .map((t) => t.type)[0];

    const label = custom_meta[trigger_type]?.label || props.meta.titre;

    if (!types.length) {
      types.push(default_type);
    }
    return { types, label };
  });
</script>
