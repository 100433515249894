<template>
  <div class="relative flex flex-0 gap-1">
    <Listbox v-model="store.search_current_sort">
      <ListboxButton class="flex items-baseline gap-2 text-[#787E8E]">
        <span>Trier&nbsp;: </span>
        <span v-html="`${activeFilter(store.search_current_sort).name}`"></span>
        <IconVectorDown />
      </ListboxButton>

      <ListboxOptions
        class="absolute right-0 top-8 z-50 w-64 cursor-pointer overflow-hidden rounded-md bg-white shadow-md">
        <ListboxOption
          v-for="filter in filters"
          v-bind:key="filter.id"
          v-bind:value="filter.value"
          as="template"
          v-slot:default="{ active }">
          <li
            v-bind:class="{
              'bg-neutral-100': active,
              'bg-white text-[#3F4350]': !active
            }"
            class="p-3"
            v-html="filter.name" />
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
  </div>
</template>

<script setup>
  import { useStore } from '@/js/store';
  const store = useStore();

  import IconVectorDown from '@/assets/img/icon-vector-down.svg';
  import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions
  } from '@headlessui/vue';

  import { labelSorterFilter } from '@/js/data/labels';

  const filters = Object.entries(labelSorterFilter).map((entry) => {
    return { value: entry[0], name: entry[1] };
  });

  function activeFilter(sort_key) {
    return filters.filter((f) => f.value === sort_key)[0];
  }
</script>
