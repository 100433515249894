<template>
  <Popover
    v-slot:default="{ open }"
    class="h-full lg:relative"
    v-on:keyup.enter.stop.prevent>
    <div
      class="flex h-full items-center justify-center"
      v-tooltip="{ content: label, distance: 15 }">
      <PopoverButton
        class="focus-rounded-full group inline-flex items-stretch p-1 outline-none focus:ring-2 disabled:cursor-not-allowed"
        v-bind:disabled="route.name === 'search-queries'">
        <IconBook
          class="h-6 w-7 !stroke-[0.5px] group-disabled:!fill-[#004787]/30"
          v-bind:class="open ? 'fill-[#D66C54]' : 'fill-[#004787]'" />
      </PopoverButton>
    </div>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel
        class="absolute left-0 z-50 mt-[14px] flex w-screen flex-col items-start gap-2 rounded-lg border border-gray-700 border-opacity-20 bg-white px-3 py-5 shadow-lg lg:-right-10 lg:left-auto lg:max-w-[720px]"
        v-slot:default="{ close }">
        <div class="flex w-full items-baseline justify-between gap-5">
          <div
            class="flex text-balance text-lg font-bold leading-none text-gray-700">
            {{ label }}
          </div>
          <app-link to="/search-queries">
            <h3
              class="text-balance text-right font-semibold leading-none text-[#004787] hover:text-[#1d4ed8] hover:underline">
              Voir toutes mes recherches mémorisées
            </h3>
          </app-link>
        </div>

        <div class="w-full">
          <div class="h-full rounded-lg border border-slate-200">
            <div v-if="error_message">
              {{ error_message }}
            </div>
            <div
              v-else-if="!error_message && !saved_searches.length"
              class="p-4 font-semibold text-gray-400">
              Aucune recherche mémorisée
            </div>
            <template
              v-for="item in saved_searches"
              v-else
              v-bind:key="item">
              <app-link
                v-bind:to="`/search-result?${item.url}`"
                v-on:click="close"
                class="flex w-full flex-wrap items-baseline justify-between gap-4 px-4 py-2.5 outline-none hover:bg-slate-100 focus:bg-slate-100">
                <div class="flex-auto">
                  <p
                    v-html="item.title"
                    class="text-sm/tight font-semibold first-letter:uppercase"></p>
                  <p class="text-xs font-semibold text-slate-400">
                    {{ item.date_modification }}
                  </p>
                </div>

                <div class="flex flex-wrap justify-end gap-1">
                  <template
                    v-for="facet in useExtractFacetList(item.url)"
                    v-bind:key="facet.name">
                    <app-label
                      v-bind:item="facet.name"
                      v-bind:categorie="facet.type"
                      class="!h-5 !min-w-0 !text-[11px]"
                      v-bind:is_border="facet.type === 'sort'"
                      no_target />
                  </template>
                </div>
              </app-link>
            </template>
          </div>
        </div>

        <div class="flex h-10 w-full">
          <div
            v-if="message_success || message_fail"
            role="alert"
            class="flex w-full items-center gap-5 p-2 leading-none text-green-100 lg:inline-flex lg:rounded-full"
            v-bind:class="[message_success ? 'bg-green-800' : 'bg-red-800']">
            <span
              class="flex rounded-full p-2 text-xs font-bold uppercase"
              v-bind:class="[message_success ? 'bg-green-500' : 'bg-red-500']">
              <component
                v-bind:is="message_success ? IconCheck : IconRemove"
                class="h-3 w-3 fill-white" />
            </span>
            <span class="mr-2 flex-auto font-semibold text-white">
              <template v-if="message_success">
                La recherche a été enregistrée avec succès
              </template>
              <template v-if="message_fail">
                La recherche en cours a déjà été enregistrée
              </template></span
            >
          </div>
        </div>

        <div class="flex w-full items-center justify-between gap-4">
          <button
            v-on:click.prevent="close()"
            class="flex h-14 w-1/3 flex-col items-center gap-3.5 rounded-md bg-[#004787] px-7 py-4 text-white">
            Fermer
          </button>
          <button
            v-if="is_search_result"
            v-on:click.prevent="saveSearch()"
            class="flex h-14 w-2/3 items-center justify-center gap-3.5 rounded-md border border-[#004787] px-7 py-4 text-[#004787]">
            <div class="w-4">
              <IconPlus class="fill-[#004787]" />
            </div>
            <div class="text-balance leading-none">
              Mémoriser la recherche en cours
            </div>
          </button>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
  import IconBook from '@/assets/img/book.svg';
  import IconCheck from '@/assets/img/icon-check.svg';
  import IconPlus from '@/assets/img/icon-plus.svg';
  import IconRemove from '@/assets/img/icon-remove.svg?skipsvgo';

  import AppLabel from '@/js/web-component/app-label.vue';

  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

  import { computed, onBeforeMount, ref } from 'vue';

  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import { useBuildUrl } from '@/js/composable/helper';
  import { useExtractFacetList } from '@/js/composable/label_getter';

  import logger from 'loglevel';
  import sync from '../lib/sync';
  import AppLink from './app-link.vue';

  const props = defineProps(['query_string']);

  const label = 'Mémoriser la recherche';

  const is_search_result = computed(() => route.name == 'search-result');
  const saved_searches = ref([]);
  const error_message = ref(null);
  const message_success = ref(false);
  const message_fail = ref(false);

  onBeforeMount(() => {
    // Fetch the data when the view is created and the data is
    // already being observed.
    fetchData();
  });

  function saveSearch() {
    const new_item = {
      title: props.query_string,
      url: useBuildUrl(route.fullPath)
    };

    sync
      .saveSearchQuery(new_item)
      .then(() => {
        saved_searches.value.unshift(new_item);

        if (saved_searches.value.length > 10) {
          saved_searches.value.pop();
        }

        message_success.value = true;
        setTimeout(() => {
          message_success.value = false;
        }, 3000);

        fetchData();
      })
      .catch((err) => {
        if (err.response.status == 409) {
          // duplicate
          message_fail.value = true;
          setTimeout(() => {
            message_fail.value = false;
          }, 3000);
        } else {
          logger.error(err);
          router.replace('/error/' + err.response.status);
        }
      });
  }

  function fetchData() {
    saved_searches.value = [];

    const criteria = [];
    criteria.push({ name: 'limit', val: 10 });

    sync
      .fetchSearchQueries(criteria)
      .then((searches) => {
        if (searches.search_queries) {
          saved_searches.value = searches.search_queries;
        }
      })
      .catch((err) => {
        logger.error('Unexpected promise error:', err, err.stack);
        error_message.value = 'Problème de communication avec le serveur';
      });
  }
</script>
