<template>
  <div
    class="flex h-full w-full items-center justify-center bg-[#D5E0ED] py-6 lg:min-h-[450px] lg:py-12">
    <div
      class="container flex w-full flex-col items-start justify-start gap-5 xl:max-w-[1091px]">
      <div class="lg:mb-6">
        <h1
          class="w-full text-balance text-2xl/snug font-bold text-[#3F3F3D] lg:text-4xl"
          v-bind:class="{ 'mb-3': !authenticated }">
          Bienvenue sur la plateforme documentaire de la CNCC.
        </h1>
        <p
          v-if="!authenticated"
          class="w-full max-w-full text-balance pl-0.5 text-xl">
          Professionnel(le)s de l’audit,
          <app-link
            v-bind:to="login_url"
            class="underline hover:text-[#004787]"
            >identifiez-vous</app-link
          >
          pour accéder à l’ensemble de la documentation.
        </p>
      </div>

      <app-search-accueil />
    </div>
  </div>
</template>

<script setup>
  import AppLink from './app-link.vue';
  import AppSearchAccueil from './app-search-accueil.vue';

  import { useLoginUrl } from '@/js/composable/helper';
  const login_url = useLoginUrl();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';

  const store = useStore();
  const { authenticated } = storeToRefs(store);
</script>

<style lang="scss" scoped></style>
