<template>
  <div class="flex w-full flex-wrap gap-1.5">
    <div class="flex w-full items-center gap-3">
      <app-link
        v-if="route_to_doc"
        v-bind:to="format_path"
        class="flex flex-0 text-sm text-slate-500 hover:underline">
        {{ label }}&nbsp;:
      </app-link>
      <div
        v-else
        class="flex flex-0 text-sm font-semibold text-slate-400">
        {{ label }}&nbsp;:
      </div>
      <div
        class="inline-flex w-full text-xs text-slate-500"
        v-if="links.length > MAX_NUMBER_ITEMS">
        <button
          v-on:click.stop.prevent="expanded = !expanded"
          class="flex items-center gap-2 rounded-md border border-slate-200 px-3 py-0.5 pt-[3px] font-semibold hover:shadow-sm">
          <div class="flex items-center">
            <template v-if="expanded">
              <span>Voir moins</span>
              <font-awesome-icon
                icon="caret-down"
                fixed-width />
            </template>
            <template v-else>
              <span>Voir plus</span>
              <font-awesome-icon
                icon="caret-right"
                fixed-width
                class="mt-[1px]" />
            </template>
          </div>
        </button>
      </div>
    </div>
    <template
      v-for="(link, index) in links"
      v-bind:key="link.url">
      <Transition name="swift-slide-down">
        <template v-if="expanded">
          <app-doc-attachments-list-link
            v-bind:key="`${label}_${index}`"
            v-bind:link="link"
            v-bind:is_blur="is_blur"
            v-bind:route_to_doc="route_to_doc"
            v-bind:format_func="format_func" />
        </template>
        <template v-else-if="!expanded && index <= MAX_NUMBER_ITEMS - 1">
          <app-doc-attachments-list-link
            v-bind:key="`${label}_${index}`"
            v-bind:link="link"
            v-bind:is_blur="is_blur"
            v-bind:route_to_doc="route_to_doc"
            v-bind:format_func="format_func" />
        </template>
      </Transition>
    </template>
    <div
      v-if="!expanded && links.length > MAX_NUMBER_ITEMS"
      class="w-full pt-0.5 text-xs text-slate-500">
      <div class="inline-flex items-center gap-1">
        <span>
          et {{ links.length - MAX_NUMBER_ITEMS }} autre{{
            links.length - MAX_NUMBER_ITEMS > 1 ? 's' : ''
          }}...
        </span>

        <font-awesome-icon
          v-if="
            !expanded && links.slice(MAX_NUMBER_ITEMS).some((l) => l.highlight)
          "
          icon="magnifying-glass-plus"
          fixed-width
          class="text-[#004787]"
          size="sm" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';

  import AppDocAttachmentsListLink from '@/js/web-component/app-doc-attachments-list-link.vue';
  import AppLink from './app-link.vue';

  const MAX_NUMBER_ITEMS = 2;
  const expanded = ref(false);

  const props = defineProps({
    format_func: Function,
    label: String,
    label_path: { type: String, default: '' },
    route_to_doc: [String, Object],
    links: Array,
    is_blur: Boolean
  });

  const format_path = computed(() => {
    let route_path =
      typeof props.route_to_doc === 'object'
        ? { ...props.route_to_doc }
        : props.route_to_doc;

    if (route_path?.path) {
      route_path.path = route_path.path + props.label_path;
    } else {
      route_path = route_path + props.label_path;
    }

    return route_path;
  });
</script>

<style lang="scss" scoped></style>
