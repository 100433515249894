<template>
  <button
    v-if="!no_trigger_button"
    v-on:click.prevent.stop="open_modal"
    v-bind:class="$attrs.class">
    <slot name="button">Open Modal</slot>
  </button>

  <Teleport to="body">
    <Transition name="fade">
      <template v-if="show_modal">
        <div
          class="modal-wrapper-overlay"
          v-on:click="close_modal">
          <div
            v-on:click.stop
            class="modal"
            v-bind:class="max_width ? max_width : ' max-w-2xl'">
            <header class="modal-header">
              <div class="inline-flex font-semibold lg:text-xl">
                <slot name="header">Modal par défaut</slot>
              </div>
              <button
                class="modal-header--close-btn"
                v-on:click="close_modal">
                <font-awesome-icon icon="xmark" />
              </button>
            </header>
            <main class="modal-main">
              <slot
                name="main"
                v-bind:close="close_modal">
                Contenu par défaut
              </slot>
            </main>
            <footer class="modal-footer">
              <slot name="footer"></slot>
            </footer>
          </div>
        </div>
      </template>
    </Transition>
  </Teleport>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue';

  import { useStore } from '@/js/store';
  const store = useStore();

  import { useKeyboardShortcut } from '@/js/composable/keyboard_shortcuts';
  const is_keymatch = useKeyboardShortcut(['Escape']);
  watch(is_keymatch, (is_triggered_key) => {
    if (is_triggered_key) close_modal();
  });

  const props = defineProps({
    max_width: String,
    no_trigger_button: Boolean,
    static_display: Boolean
  });
  const emit = defineEmits(['close']);

  const show_modal = ref(props.static_display || false);

  const open_modal = () => (show_modal.value = true);
  const close_modal = () => {
    show_modal.value = false;
    emit('close', show_modal.value);
  };

  onMounted(() => {
    if (props.static_display) {
      store.toggleFreezeBodyScroll(true);
    }
  });

  watch(
    () => props.static_display,
    (is_static) => {
      show_modal.value = is_static;
    }
  );

  watch(
    () => show_modal.value,
    (is_show) => {
      if (is_show) {
        store.toggleFreezeBodyScroll(true);
      } else {
        setTimeout(() => {
          const list_open_modals = document.querySelectorAll('.modal');
          if (list_open_modals.length === 0)
            store.toggleFreezeBodyScroll(false);
        }, 300);
      }
    }
  );
</script>

<style lang="scss" scoped>
  .modal-wrapper-overlay {
    @apply fixed inset-0 z-[99999] flex items-center justify-center bg-slate-600 bg-opacity-75;

    .modal {
      @apply z-[999] flex h-screen w-full flex-col overflow-hidden bg-white shadow-xl sm:h-auto sm:max-h-[90vh] sm:rounded-lg;

      transition: all 0.1s cubic-bezier(0.57, 0.47, 0.72, 1.59);
      @keyframes inflate {
        from {
          transform: scale(0.85);
          opacity: 0;
        }

        75% {
          transform: scale(1.02);
          opacity: 1;
        }

        to {
          transform: scale(1);
        }
      }
      animation-duration: 0.3s;
      animation-name: inflate;

      .modal-header {
        @apply flex w-full items-center justify-between gap-1 border-b px-4 pb-4 pt-3 text-[#004787] sm:px-7 sm:pt-6 lg:gap-24;

        .modal-header--close-btn {
          @apply inline-flex h-8 w-8 flex-0 items-center justify-center rounded-full border p-1 font-bold hover:bg-slate-50 lg:h-10 lg:w-10;
        }
      }

      .modal-main {
        @apply overflow-y-auto scroll-smooth py-8;
      }
    }
  }
</style>
