<template>
  <div
    class="mt-2 flex w-full items-center justify-end gap-3 lg:absolute lg:mb-8 lg:mt-0 lg:gap-5">
    <a
      target="_blank"
      v-bind:href="sharePage"
      class="flex h-12 w-12 items-center justify-center bg-[#D66C54] text-center font-semibold text-white sm:h-16 sm:w-16 xl:w-52 xl:text-xl">
      <span class="hidden xl:flex">Partager</span>
      <font-awesome-icon
        icon="share"
        size="lg"
        class="xl:hidden" />
    </a>
    <button
      v-on:click="printPage"
      class="flex h-12 w-12 items-center justify-center bg-[#D66C54] text-center font-semibold text-white sm:h-16 sm:w-16 xl:w-52 xl:text-xl">
      <span class="hidden xl:flex">Imprimer</span>
      <font-awesome-icon
        icon="print"
        size="lg"
        class="xl:hidden" />
    </button>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  function printPage() {
    window.print();
  }
  const sharePage = computed(() => {
    const url = window.location.href;
    const subject = encodeURIComponent(`Partage de document SIDONI : ${url}`);
    const body = encodeURIComponent(
      `Document partagé depuis la plateforme documentaire de la CNCC (SIDONI):\n\n ${document.title} \n\n Lien d'accès :\n${url}`
    );
    return `mailto:?subject=${subject}&body=${body}`;
  });
</script>
