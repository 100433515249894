<template>
  <div class="container my-16">
    <article class="flex flex-wrap-reverse gap-12 lg:flex-nowrap lg:gap-24">
      <section class="flex flex-grow flex-col gap-4">
        <app-rubrique-titre
          v-bind:titre="route.meta.title"></app-rubrique-titre>
        <div class="flex w-full justify-end">
          <button
            class="pr-1.5 text-sm font-bold hover:text-slate-500"
            v-on:click="refreshData()">
            Rafraîchir la liste
          </button>
        </div>
        <div
          v-if="!getting_data && accesses.done_granted?.length"
          v-for="granted_user in accesses.done_granted"
          v-bind:key="granted_user.request_id"
          class="flex w-full flex-wrap items-center gap-8 border border-slate-200 px-4 py-4 text-sm lg:px-8 xl:flex-nowrap">
          <div class="w-52 max-w-full flex-shrink-0">
            <div>
              {{ granted_user.requester_given_name }}
              {{ granted_user.requester_family_name }}
            </div>
            <div class="text-slate-500">{{ granted_user.requester_email }}</div>
          </div>
          <div class="w-full flex-grow space-y-3">
            <div class="mb-3 space-y-1.5">
              <div class="text-gray-500">Justificatif d'autorisation :</div>
              <div
                class="ml-0.5 border-l-2 border-slate-300 bg-slate-50 px-4 py-1">
                {{ granted_user.reason }}
              </div>
            </div>
            <div class="pr-1.5 text-right text-gray-500">
              Autorisation active depuis le
              {{ useToFrDate(granted_user.date_granted) }}
            </div>
            <div
              v-if="store.is_debug_on"
              class="text-blue-700">
              ( Debug => Expire le
              {{ useToFrDate(granted_user.date_expiration) }} )
            </div>
          </div>
          <div class="flex w-full justify-end lg:w-auto">
            <button
              class="bg-slate-100 p-3 transition hover:ring-2 hover:ring-red-500"
              v-on:click="confirmDelete(granted_user.request_id)">
              Révoquer
            </button>
          </div>
        </div>
        <div
          class="flex h-24 w-full items-center gap-12 bg-slate-100 px-8 py-4 font-bold text-slate-500"
          v-else-if="getting_data">
          Chargement en cours...
        </div>
        <div
          class="flex h-24 w-full items-center gap-12 bg-slate-100 px-8 py-4 font-bold text-slate-500"
          v-else>
          Aucune demande d'accès VIP
        </div>
      </section>
      <aside class="flex w-full flex-col gap-3 lg:max-w-md">
        <app-rubrique-titre titre="Ajouter VIP"></app-rubrique-titre>
        <form
          class="sticky top-24 flex flex-wrap gap-8"
          v-on:submit.stop.prevent="grantUser">
          <fieldset class="flex w-full flex-wrap gap-1">
            <label
              for="email"
              class="w-full text-xs font-bold lg:text-base">
              Email de la personne devant recevoir un droit d'accès VIP<br />
              <span class="font-normal text-slate-500 lg:text-sm">
                (&nbsp;Cette adresse email doit correspondre à un compte CNCC
                existant&nbsp;)
              </span>
            </label>
            <input
              required
              id="email"
              name="email"
              type="email"
              v-model="email_list"
              placeholder="Renseigner un email"
              class="w-full border px-4 py-2" />
          </fieldset>
          <fieldset class="flex w-full flex-wrap gap-1">
            <label
              for="reason"
              class="w-full text-xs font-bold lg:text-base">
              Préciser la raison relative à l'attribution d'un droit d'accès VIP
            </label>
            <input
              required
              name="reason"
              type="text"
              v-model="reason"
              placeholder="Décrire la raison"
              class="w-full border px-4 py-2"
              maxlength="300" />
          </fieldset>

          <fieldset class="flex w-full flex-wrap gap-1">
            <input
              type="submit"
              value="Enregistrement de l'accès"
              class="w-full cursor-pointer border px-4 py-2 font-bold hover:bg-slate-50" />
          </fieldset>
        </form>

        <div
          v-if="messages?.length"
          class="w-full list-disc border border-red-600 p-4 pl-10 text-sm text-red-700">
          <template
            v-for="(content, i) in messages"
            v-bind:key="content.message">
            <span>
              <template v-if="i === 0">{{ content.message }}</template>
              <template v-else>
                <template
                  v-if="
                    i > 0 && content.message != 'Aucun utilisateur éligible'
                  "
                  >{{ content.message }}</template
                >
              </template>
            </span>
          </template>
        </div>
      </aside>
    </article>
    <app-modal
      no_trigger_button
      v-on:close="confirm_delete_modal = false"
      v-bind:static_display="confirm_delete_modal">
      <template v-slot:header
        ><span class="text-red-500">Confirmer la révocation</span></template
      >
      <template v-slot:main="{ close }">
        <div class="flex flex-col gap-8 px-8">
          <div class="text-lg">
            Souhaitez-vous vraiment révoquer cet utilisateur ?
          </div>

          <div class="flex w-full justify-center gap-8">
            <button
              class="w-40 bg-red-500 py-3 text-white hover:bg-red-600"
              v-on:click="deleteUser(current_user_request_id)">
              Révoquer
            </button>
            <button
              class="w-40 bg-slate-100 py-3 hover:bg-slate-200"
              v-on:click="close">
              Annuler
            </button>
          </div>
        </div>
      </template>
    </app-modal>
  </div>
</template>

<script setup>
  import sync from '../lib/sync';

  import { onBeforeMount, ref } from 'vue';

  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import { useToFrDate } from '../composable/date_and_time_helpers';

  import AppModal from '@/js/web-component/app-modal.vue';
  import AppRubriqueTitre from '../web-component/app-rubrique-titre.vue';

  const getting_data = ref(false);

  const messages = ref([]);
  const accesses = ref({});

  const help_email = ref('');
  const page_title = ref('');

  const email_list = ref('');
  const reason = ref('');
  const date_expiration = ref('');

  const back_to_the_future = new Date('01-01-2099');

  onBeforeMount(async () => {
    await fetchData();
  });

  async function refreshData($event, silent = false) {
    getting_data.value = true;

    await setTimeout(async () => {
      await fetchData({ silent });
    }, 100);
  }
  async function fetchData(options) {
    getting_data.value = true;
    await sync
      .fetchAccessGranter()
      .then((response) => {
        if (!options?.silent) messages.value = response.messages;
        page_title.value = response.data.page_title;
        help_email.value = response.data.help_email;

        accesses.value = response.data.accesses;
        date_expiration.value = back_to_the_future;

        getting_data.value = false;
      })
      .catch((err) => {
        getting_data.value = false;
        router.push('/error/' + err.response?.status);
      });
  }

  async function grantUser() {
    const body = {};
    body.action = 'grant';
    body.email_list = email_list.value;
    body.reason = reason.value;
    body.date_expiration = date_expiration.value;

    await sync.postAccessGranter(body).then((response) => {
      messages.value = response.messages;
      accesses.value = response.accesses;

      if (messages.value.length === 0) {
        email_list.value = '';
        reason.value = '';
      }
    });
  }

  const confirm_delete_modal = ref(false);
  const current_user_request_id = ref(null);
  function confirmDelete(user_id) {
    current_user_request_id.value = user_id;
    confirm_delete_modal.value = true;
  }

  async function deleteUser(request_id) {
    const body = {};
    body.action = 'revoke';
    body.email_list = email_list.value;
    body.reason = reason.value;
    body.date_expiration = date_expiration.value;
    body.request_ids = [request_id];

    await sync.deleteAccessGranter(body).then((response) => {
      messages.value = response.messages;
      accesses.value = response.accesses;

      if (messages.value.length === 0) {
        email_list.value = '';
        reason.value = '';
      }
    });
    confirm_delete_modal.value = false;
  }
</script>
