<template>
  <div
    v-if="needs_update"
    class="fixed bottom-0 left-0 right-0 z-[9999] flex items-center justify-center gap-5 bg-[#1d4ed8] py-6 text-white">
    <div>Une nouvelle version de l’application est disponible.</div>
    <button
      id="update-button"
      v-on:click.prevent="update"
      class="rounded bg-white px-3 py-1.5 text-[#333]">
      Mettre à jour
    </button>
  </div>
</template>

<script setup>
  import { onBeforeMount, ref } from 'vue';
  import version_json from '../../version.json';
  import sync from '../lib/sync.js';

  const needs_update = ref(false);
  const check_updates_initial_delay = undefined;

  onBeforeMount(() => {
    checkUpdate();
  });

  function checkUpdate() {
    // mecanisme update
    sync.checkUpdate(
      version_json.version,
      () => {
        needs_update.value = true;
      },
      check_updates_initial_delay
    );
  }

  function update() {
    window.location.reload(true);
  }
</script>
