<template>
  <div class="container mt-16 bg-slate-100 p-16">
    <div class="flex flex-col gap-6">
      <h1 class="text-2xl font-bold">Support</h1>
      <div class="space-y-2">
        <div>
          <span>Version du Reader&nbsp;:&nbsp;&nbsp;</span>
          <code class="rounded border bg-gray-50 px-2.5 py-0.5">
            {{ reader_info }}
          </code>
        </div>
        <div>
          <span>Version du Sidoni Server&nbsp;:&nbsp;&nbsp;</span>
          <code
            id="server-info"
            class="rounded border bg-gray-50 px-2.5 py-0.5">
            {{ server_info }}
          </code>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="inline-flex items-center gap-2">
          <input
            id="checkbox"
            v-model="store.is_debug_on"
            type="checkbox"
            class="h-4 w-4 cursor-pointer accent-[#004787]" />
          <label
            for="checkbox"
            class="cursor-pointer hover:text-[#004787]"
            >Debug</label
          >
        </div>
        <div class="inline-flex items-center gap-2">
          <input
            id="checkbox_analytics"
            v-model="allow_analytics"
            type="checkbox"
            class="h-4 w-4 cursor-pointer accent-[#004787]" />
          <label
            for="checkbox_analytics"
            class="cursor-pointer hover:text-[#004787]"
            >Force tracking</label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import logger from 'loglevel';

  import { onBeforeMount, ref, watch } from 'vue';

  import version_json from '../../version.json';
  import sync from '../lib/sync';

  import { useRouter } from 'vue-router';
  const router = useRouter();

  import { useStore } from '@/js/store';
  const store = useStore();

  const reader_info = ref(version_json.version);
  const server_info = ref('Information non disponible');

  const allow_analytics = ref(localStorage.getItem('track_dev') == 'true');

  watch(
    () => store.is_debug_on,
    (is_debug) => {
      if (is_debug) {
        logger.enableAll();
        logger.warn('Debug ON');
      } else {
        logger.warn('Debug OFF');
        logger.disableAll();
      }
    }
  );
  watch(
    () => allow_analytics.value,
    () => {
      setAnalytics();
    }
  );

  onBeforeMount(() => {
    fetchData();
  });

  function fetchData() {
    sync
      .fetchInfo()
      .then((res) => {
        server_info.value = res.data;
      })
      .catch((err) => {
        // on ne redirige pas vers route erreur pour afficher les autres informations
        server_info.value = err.message;
      });
  }

  function setAnalytics() {
    logger.warn(`Analytics ${allow_analytics.value ? 'ON' : 'OFF'}`);
    localStorage.setItem('track_dev', allow_analytics.value);
    // force page reload tu update analytics plugins
    router.go(router.currentRoute);
  }
</script>
