import { watchOnce } from '@vueuse/core';
import { computed, toRef } from 'vue';
import { useRoute } from 'vue-router';
import sync from '../lib/sync';

export const useHighlightWrap = (source_content) => {
    const route = useRoute();
    const content = toRef(source_content);
    const wrapped_content = content;

    const has_query = computed(() => {
        return route?.query?.q;
    });

    watchOnce(
        content,
        async () => {
            if (has_query.value && content.value) {
                const res = await sync.fetchDocHighlight({
                    q: has_query.value,
                    items: {
                        doctrine_highlight: content.value
                    }
                });
                wrapped_content.value = res.data.doctrine_highlight;
            }
        },
        { immediate: true }
    );

    return wrapped_content;
};
