/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import {
    faArrowDown,
    faArrowLeft,
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faBan,
    faCaretDown,
    faCaretRight,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCircleCheck,
    faCircleInfo,
    faCircleMinus,
    faCirclePlus,
    faCircleXmark,
    faClock,
    faEye,
    faGear,
    faHome,
    faLink,
    faListCheck,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faPaperclip,
    faPrint,
    faRightFromBracket,
    faShare,
    faStar,
    faTrash,
    faUser,
    faUserCircle,
    faUserPlus,
    faXmark
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(
    faCheck,
    faTrash,
    faClock,
    faLink,
    faCirclePlus,
    faCircleMinus,
    faArrowLeft,
    faArrowDown,
    faXmark,
    faCircleXmark,
    faCaretDown,
    faCaretRight,
    faMagnifyingGlass,
    faPaperclip,
    faCircleInfo,
    faEye,
    faUser,
    faUserCircle,
    faBan,
    faUserPlus,
    faListCheck,
    faGear,
    faArrowsRotate,
    faTrash,
    faPrint,
    faShare,
    faCircleCheck,
    faLock,
    faLockOpen,
    faHome,
    faStar,
    faRightFromBracket,
    faArrowUpRightFromSquare,
    faMagnifyingGlassPlus,
    faChevronUp,
    faChevronDown
);

const VueFontAwesome = ['font-awesome-icon', FontAwesomeIcon];
export default VueFontAwesome;
