<template>
  <a
    v-if="isExternalLink"
    v-bind="$attrs"
    v-bind:href="to"
    v-on:click.stop>
    <slot />
  </a>
  <router-link
    v-else-if="login_external"
    v-bind="$props"
    custom
    v-slot:default="{ isActive, href }">
    <a
      v-bind="$attrs"
      v-bind:href="href"
      v-bind:class="isActive ? exactActiveClass || activeClass : inactiveClass">
      <slot />
    </a>
  </router-link>
  <router-link
    v-else
    v-bind="$props"
    custom
    v-slot:default="{ isActive, href, navigate }">
    <a
      v-bind="$attrs"
      v-bind:href="href"
      v-on:click="navigate"
      v-bind:class="isActive ? exactActiveClass || activeClass : inactiveClass">
      <slot />
    </a>
  </router-link>
</template>

<script setup>
  import { computed } from 'vue';
  import { RouterLink } from 'vue-router';

  defineOptions({
    inheritAttrs: false
  });

  const props = defineProps({
    ...RouterLink.props,
    // eslint-disable-next-line vue/prop-name-casing
    inactiveClass: String,
    login_external: Boolean
  });

  const isExternalLink = computed(() => {
    return typeof props.to === 'string' && props.to.startsWith('http');
  });
</script>
