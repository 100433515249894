/**
 * This module is to store a canonical_urls object singleton.
 *
 * It is best to only have a singleton for the canonical_urls object in memory,
 * otherwise it loses space for nothing.
 */

import canonical_urls from 'canonical-urls';

const urls = canonical_urls();

export default urls;
