<template>
  <div class="container py-8">
    <app-card-connect
      v-if="!privileged"
      is_bordered_box />
    <app-documentation />
  </div>
</template>

<script setup>
  import AppCardConnect from '@/js/web-component/app-card-connect.vue';
  import AppDocumentation from '@/js/web-component/app-documentation.vue';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';

  const store = useStore();
  const { privileged } = storeToRefs(store);
</script>
