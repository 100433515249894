<template>
  <Popover
    v-slot:default="{ open }"
    class="h-full md:relative"
    v-on:keyup.enter.stop.prevent>
    <div
      class="flex h-full items-center justify-center"
      v-tooltip="{ content: label, distance: 15 }">
      <PopoverButton
        class="focus-rounded-full group inline-flex items-stretch p-1 outline-none focus:ring-2 disabled:cursor-not-allowed">
        <IconFilter
          class="h-6 w-6 !stroke-[0.5px]"
          v-bind:class="
            open
              ? 'fill-[#D66C54] stroke-[#D66C54]'
              : 'fill-[#004787] stroke-[#004787]'
          " />
      </PopoverButton>
    </div>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0">
      <PopoverPanel
        ref="popover_panel"
        class="absolute left-0 z-50 mt-[14px] flex w-screen flex-col items-start gap-2 rounded-lg border border-gray-700 border-opacity-20 bg-white px-3 py-5 shadow-lg md:-right-10 md:left-auto md:max-w-screen-sm"
        v-slot:default="{ close }">
        <div class="flex w-full flex-col items-baseline justify-between gap-5">
          <div class="flex text-lg font-bold leading-normal text-gray-700">
            {{ label }}
          </div>
          <div
            class="w-full text-xs font-semibold uppercase leading-none tracking-tight text-slate-400">
            Conditions
          </div>

          <template
            v-for="row in advanced_search_container"
            v-bind:key="row.id">
            <app-search-advanced-form-row
              v-bind:row="row"
              v-on:change="(val) => updateRowValue(val, row.id)"
              v-on:delete="deleteRow(row.id)"
              v-bind:no_combo="row.condition === 'SEARCH'"
              v-bind:is_error="error_container[index(row.id)]" />
          </template>

          <div class="flex w-full justify-between">
            <button
              v-on:click="addRow"
              class="mt-3 flex h-14 items-center justify-center gap-3.5 self-end rounded-md border border-[#004787] px-7 py-4 text-[#004787]">
              <div class="w-4">
                <IconPlus class="fill-[#004787]" />
              </div>
              <div>Ajouter un critère</div>
            </button>
            <button
              v-on:click="replaceSearchString(close)"
              class="mt-3 flex h-14 flex-col items-center justify-start gap-3.5 rounded-md bg-[#004787] px-7 py-4 text-white">
              Confirmer
            </button>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup>
  import IconFilter from '@/assets/img/filter.svg?skipsvgo';
  import IconPlus from '@/assets/img/icon-plus.svg';

  import AppSearchAdvancedFormRow from './app-search-advanced-form-row.vue';

  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

  import { computed, ref, watchEffect } from 'vue';

  import { useStore } from '@/js/store';
  const store = useStore();

  const emit = defineEmits(['validate']);

  const popover_panel = ref(null);
  const is_visible = computed(() => {
    return popover_panel.value?.el ? true : false;
  });
  const label = 'Recherche avancée';
  const error_container = ref({});

  function generateID() {
    // Generate timestamp and random number:
    const time = Date.now();
    const randomNumber = Math.floor(Math.random() * 1000000001);

    // Merge both with string underscore (forces string)
    const uniqueId = time + '_' + randomNumber;

    // Make function return the result
    return uniqueId;
  }

  const index = (id, log = false) =>
    advanced_search_container.value.findIndex((row) => {
      // log && console.log(row.id, id);
      return row.id === id;
    });

  const generate_row = (row) => {
    return { id: generateID(), ...row };
  };

  const clean_string = (val) => val.trim().replace(/\s+/g, ' ');

  const breakpoints = /ET|OU|SAUF/g;
  const catching = /(?=^|ET|OU|SAUF).+?(?=ET|OU|SAUF|$)/g;

  const advanced_search_container = ref([
    generate_row({ condition: 'SEARCH', text: '' })
  ]);
  watchEffect(() => {
    if (is_visible.value && store.search_current_value) {
      advanced_search_container.value = extractAdvancedSearchFromString();
    } else if (!is_visible.value) {
      error_container.value = {};
    }
  });

  function extractAdvancedSearchFromString() {
    const splitted = clean_string(store.search_current_value).match(catching);

    const advanced_array = splitted.flatMap((chunk, i) => {
      const condition = chunk.match(breakpoints)
        ? chunk.match(breakpoints)[0]
        : 'SEARCH';
      const text = chunk.replace(condition, '');

      return generate_row({
        condition: i !== 0 ? condition : 'SEARCH',
        text
      });
    });

    return advanced_array;
  }

  function replaceSearchString(close_panel) {
    const advanced_search_string = advanced_search_container.value
      .map((item, i) =>
        item.text && i !== 0
          ? `${item.condition.trim()} ${item.text.trim()}`
          : item.text && item.text.trim()
      )
      .join(' ');

    advanced_search_container.value.forEach((item) => {
      const text = item.text?.trim();
      error_container.value[index(item.id)] = !text;
    });

    if (Object.values(error_container.value).every((is_error) => !is_error)) {
      emit('validate', `${advanced_search_string}`);
      close_panel();
    }
  }

  function addRow() {
    advanced_search_container.value.push(
      generate_row({ condition: 'ET', text: null })
    );
  }

  function updateRowValue(row_val, id) {
    advanced_search_container.value[index(id)] = row_val;
  }
  function deleteRow(id) {
    advanced_search_container.value.splice(index(id), 1);
  }
</script>
