<template>
  <app-page-list-wrapper
    v-bind:label="
      typeRefs && page_type_id
        ? useLabelGetter(page_type_id) || `Tous les ${page_name}`
        : route.meta.title
    "
    v-bind:list="doc_defs"
    v-bind:getting_data="getting_data"
    v-bind:display_choices_list="typeRefs && Object.keys(typeRefs)?.length > 0">
    <template
      v-if="route.name === 'normes-exercice-professionnel'"
      v-slot:extra-aside>
      <app-link
        to="/docs/recueil-des-normes-dexercice-pro-65e9a5b1bc182"
        class="inline-flex rounded bg-[#004787] px-6 py-2 text-center text-white shadow">
        Accéder au recueil des normes d’exercice professionnel
      </app-link>
    </template>

    <template
      v-if="typeRefs"
      v-slot:aside="{ is_expand }">
      <div class="mb-8">
        <Transition
          name="slide-up"
          mode="out-in">
          <div
            v-if="is_expand"
            class="flex w-full flex-col gap-0.5 pr-3">
            <template
              v-for="type_key in Object.entries(typeRefs).sort((a, b) =>
                a[1].localeCompare(b[1])
              )"
              v-bind:key="type_key[0]">
              <app-link
                v-if="type_key[0] !== 'null'"
                v-bind:to="`/${type_name}/${type_key[0]}`"
                class="text-[#3F43508F] hover:text-slate-500"
                v-bind:class="{
                  '!text-[#004787]': type_key[0] === page_type_id
                }">
                {{ type_key[1] }}
              </app-link>
            </template>
          </div>
        </Transition>
      </div>
    </template>
  </app-page-list-wrapper>
</template>

<script setup>
  import { useLabelGetter } from '@/js/composable/label_getter';
  import { labelTypeDoc, labelTypeSecteur } from '@/js/data/labels.js';

  import logger from 'loglevel';

  import sync from '@/js/lib/sync';

  import { computed, onBeforeMount, ref, watch } from 'vue';

  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import {
    useBulletinsFormater,
    useEipFormater,
    useNormesFormater
  } from '@/js/composable/pagelist_custom_formater.js';

  import AppLink from '@/js/web-component/app-link.vue';
  import AppPageListWrapper from '@/js/web-component/app-page-list-wrapper.vue';

  const has_sublist = computed(() => route.meta.sublist_from_ouvrage);
  const custom_formater = computed(
    () => route.meta.custom_pagelist_data_format
  );
  const page_name = computed(() => has_sublist.value || route.name);
  const type_name = ref(null);
  const page_type_id = computed(() =>
    has_sublist.value ? route.name : route.params.page_id
  );
  const typeRefs = ref(null);
  if (page_name.value === 'type' || page_name.value === 'types') {
    type_name.value = 'types';
    typeRefs.value = labelTypeDoc;
  } else if (page_name.value === 'secteur' || page_name.value === 'secteurs') {
    type_name.value = 'secteurs';
    typeRefs.value = labelTypeSecteur;
  }

  const getting_data = ref(true);
  const doc_defs = ref([]);

  watch(route, async () => {
    await fetchData();
  });

  onBeforeMount(async () => {
    await fetchData();
  });

  async function fetchData() {
    getting_data.value = true;

    try {
      if (custom_formater.value) {
        if (route.name === 'bulletins') {
          doc_defs.value = await useBulletinsFormater();
        }

        if (route.name === 'eip') {
          doc_defs.value = await useEipFormater();
        }

        if (route.name === 'normes-exercice-professionnel') {
          doc_defs.value = await useNormesFormater();
        }
      } else if (has_sublist.value) {
        const response = await sync.fetchDoc(page_name.value);

        const filter_res = response.meta.composition.filter((elem) =>
          page_type_id.value !== page_name.value
            ? elem.type === page_type_id.value
            : true
        );

        if (filter_res.some((comp) => comp.articles.length)) {
          await Promise.all(
            filter_res.map(async (comp) => {
              comp.articles = await Promise.all(
                comp.articles.map(async (doc) => {
                  const fetch_doc = await sync.fetchDoc(doc.nom);
                  // On repasse l'attribut "nom" parceque le serveur le renvoie vide après le fetch du doc individuel...
                  // FIXME : corriger ce bug côté sidoni-server
                  return { ...fetch_doc, nom: doc.nom };
                })
              );
            })
          );
        }
        doc_defs.value = filter_res;
      } else {
        // url options
        const criteria = [];

        if (page_type_id.value) {
          criteria.push({ name: page_name.value, val: page_type_id.value });
        } else {
          if (route.name === 'docs') {
            criteria.push({ name: 'classe', val: 'document' });
          }
          if (route.name === 'outils') {
            criteria.push({ name: 'classe', val: 'outil' });
          }
          criteria.push({ name: 'limit', val: '50' });
        }

        const response = await sync.fetchDocDefs(criteria);
        doc_defs.value = response;
      }

      getting_data.value = false;
    } catch (err) {
      logger.error('Unexpected promise error:', err.response.status, err.stack);
      getting_data.value = false;
      router.replace('/error/' + err.response.status);
    }
  }
</script>
