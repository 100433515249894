import VueScrollTo from 'vue-scrollto';

export function useVueScrollTo() {
    const vScrollTo = VueScrollTo;

    function vscroll_param(target, duration, offset) {
        const param = {
            el: target ? target : false,
            duration: duration ? duration : 300,
            easing: 'linear',
            offset: -90,
            cancelable: false,
            x: false,
            y: true
        };
        return param;
    }

    function triggerScrollTo(target) {
        VueScrollTo.scrollTo(target);
    }

    function eventScrollTo(click_event) {
        const target = click_event.target.hash;
        triggerScrollTo(target);
    }

    return { vScrollTo, vscroll_param, triggerScrollTo, eventScrollTo };
}
