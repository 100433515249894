import { format, parseISO } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const useToFrDate = (
    value,
    prefix = '',
    custom_format = 'EEEE d MMMM yyyy'
) => {
    if (value) {
        const parsed_date = parseISO(value);
        const format_date = format(parsed_date, custom_format, { locale: fr });
        return `${prefix} ${format_date}`;
    } else {
        return 'Date indisponible';
    }
};
