<template>
  <Combobox
    as="div"
    v-bind:by="compareById"
    class="relative h-full w-full pr-3"
    v-bind:class="{ 'ring-2 ring-red-500': max_lenght_error }"
    v-model="selectedSuggest">
    <div class="flex h-full items-center gap-2">
      <ComboboxInput
        as="input"
        ref="input"
        class="h-full w-full bg-transparent pl-6 outline-none"
        v-focus
        v-bind:maxlength="MAX_INPUT_LENGTH + 1"
        v-bind:placeholder="placeholder"
        v-on:change="updateSuggestions" />
      <button
        v-if="selectedSuggest?.length"
        v-on:click="cleanSearch"
        class="focus-rounded-full group inline-flex h-auto cursor-pointer items-center justify-center p-1 text-xl font-bold outline-none hover:bg-neutral-50 focus:ring-2 disabled:cursor-not-allowed">
        <IconRemove class="h-5 w-5 fill-[#A1B4CA]" />
      </button>
    </div>

    <ComboboxOptions
      class="absolute top-12 z-50 w-full rounded-b-lg bg-white shadow-lg">
      <ComboboxOption
        v-for="(entry, index) in suggestionList"
        as="template"
        v-bind:key="entry.id"
        v-bind:value="entry.value"
        v-slot:default="{ selected, active }">
        <li v-if="index === 0"></li>
        <li
          v-else
          class="relative flex cursor-default select-none items-center gap-3 py-2.5 pl-4 pr-4"
          v-bind:class="{
            'bg-slate-100 font-bold': !selected && active,
            'bg-slate-50 font-bold': selected
          }">
          <IconSearch class="fill-[#A1B4CA]" />
          <span
            v-html="entry.label"
            class="suggestion"></span>
        </li>
      </ComboboxOption>
    </ComboboxOptions>
  </Combobox>
</template>

<script setup>
  import logger from 'loglevel';

  import IconRemove from '@/assets/img/icon-remove.svg?skipsvgo';
  import IconSearch from '@/assets/img/icon-search.svg?skipsvgo';

  import {
    Combobox,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions
  } from '@headlessui/vue';

  import { useRoute } from 'vue-router';
  const route = useRoute();

  import { useStore } from '@/js/store';
  const store = useStore();

  import urls from '../lib/urls';

  import { useKeyboardShortcut } from '@/js/composable/keyboard_shortcuts';

  import { computed, nextTick, ref, toRaw, watch } from 'vue';

  const input = ref(null);
  // Declaration de la directive v-focus
  const vFocus = {
    mounted: async (el, binding, vnode, prevVnode) => {
      if (!store.is_mobile_viewport) {
        await triggerFocus(el);
      }
    }
  };
  async function triggerFocus(el) {
    if (!el) return;
    el.focus();
    await nextTick();
    el.select();
  }

  const is_keymatch = useKeyboardShortcut(['Control', 'Alt', 'k']);
  watch(is_keymatch, async (is_triggered_key) => {
    if (is_triggered_key) await triggerFocus(input.value.el);
  });

  // eslint-disable-next-line vue/prop-name-casing
  defineProps(['modelValue', 'placeholder']);
  const emit = defineEmits(['update:modelValue']);

  function compareById(a, b) {
    return a && b ? a.id - b.id : 0;
  }

  const query = ref('');
  const queryCustom = computed(() => {
    return query.value === ''
      ? null
      : { id: 0, value: query.value, label: query.value };
  });

  const sugestions = ref([]);
  const current_search = computed(() => {
    return route.query.q;
  });
  const selectedSuggest = ref(route.query.q);

  function freezeSearch() {
    selectedSuggest.value = input.value.el.value;
  }
  function freezeInputSearch() {
    if (route.query.q) input.value.el.value = route.query.q;
  }
  async function cleanSearch() {
    selectedSuggest.value = '';
    max_lenght_error.value = false;
    await triggerFocus(input.value.el);
  }
  watch(
    () => route.query,
    () => {
      freezeInputSearch();
    }
  );
  watch(
    [current_search, selectedSuggest],
    ([newA, newB], [prevA, prevB]) => {
      // console.log(prevA !== newA, 'Current');
      // console.log(prevB !== newB, 'selectedSuggest');

      if (prevB !== newB) {
        // console.log(prevB !== newB, 'selectedSuggest');
        emit('update:modelValue', newB);
        selectedSuggest.value = newB;
        store.search_current_value = newB;
      }
      if (prevA !== newA) {
        // console.log(prevA !== newA, 'Current');
        emit('update:modelValue', newA);
        selectedSuggest.value = newA;
        store.search_current_value = newA;
      }
    },
    { immediate: true }
  );
  const suggestionList = computed(() => {
    const list = [...toRaw(sugestions.value)];
    if (queryCustom.value) {
      list.unshift(queryCustom.value);
    }
    const indexed_list = list.map((item, index) => {
      return { id: index, ...item };
    });
    return indexed_list;
  });

  const SUGGEST_LIMIT = 10;
  const MAX_INPUT_LENGTH = 150;
  const max_lenght_error = ref(false);
  async function updateSuggestions(e) {
    query.value = e.target.value;
    emit('update:modelValue', input.value.el.value);

    // !important to keep in Sync selectedSuggest and input's value
    freezeSearch();
    setMaxLengthError(query.value);
    if (query.value.length >= 3) {
      try {
        const config = {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        };

        const base = urls.buildUrl('/suggest');
        const response = await fetch(
          `${base}?q=${query.value}&limit=${SUGGEST_LIMIT}`,
          config
        );
        const json = await response.json();

        if (response.ok) {
          sugestions.value = json.suggests;
        } else {
          sugestions.value = [];
        }
      } catch (error) {
        logger.error(error);
      }
    } else {
      sugestions.value = [];
    }
  }

  watch(
    () => input.value?.el.value,
    (input_val) => {
      setMaxLengthError(input_val);
    }
  );
  function setMaxLengthError(val) {
    if (val.length > MAX_INPUT_LENGTH) {
      max_lenght_error.value = true;
    } else {
      max_lenght_error.value = false;
    }
  }
  defineExpose({
    selectedSuggest,
    MAX_INPUT_LENGTH,
    max_lenght_error
  });
</script>

<style lang="scss" scoped>
  :deep(.suggestion) {
    hit {
      @apply bg-transparent px-0 font-semibold not-italic text-[#004787] #{!important};
    }
  }
</style>
