const char_insecable = String.fromCharCode(160);

const ALTER_SEPARATOR_REPLACEMENTS = [
    {
        in: /<p class="officeconversion-textbody">(\s*)(\*+)(\s*)<\/p>/g,
        out: '<p class="officeconversion-textbody officeconversion-alter-separator">$2</p>'
    },
    {
        // docs/ej2014-08ep2013-12
        in: /<p class="officeconversion-textbody">(\s*)<span>(\*+)<\/span>(\s*)<\/p>/g,
        out: '<p class="officeconversion-textbody officeconversion-alter-separator">$2</p>'
    },
    {
        in: /<p class="officeconversion-textbody"><em><span>(\*+)<\/span><\/em><\/p>/g,
        out: '<p class="officeconversion-textbody officeconversion-alter-separator">$1</p>'
    },
    {
        // docs/sas-sasu-comptes-conso-defa
        in: /<p class="officeconversion-normalweb">(\*+)<\/p>/g,
        out: '<p class="officeconversion-normalweb officeconversion-alter-separator">$1</p>'
    },
    {
        in: /<p class="officeconversion-normalweb"><span>(\*+)<\/span><\/p>/g,
        out: '<p class="officeconversion-normalweb officeconversion-alter-separator">$1</p>'
    },
    {
        in: /<p class="officeconversion-standard"><span>(\*+)<\/span><\/p>/g,
        out: '<p class="officeconversion-standard officeconversion-alter-separator">$1</p>'
    },
    {
        in: /<p class="officeconversion-standard">(\*+)<\/p>/g,
        out: '<p class="officeconversion-standard officeconversion-alter-separator">$1</p>'
    },
    {
        in: /<p class="officeconversion-textbodyuser">(\*+)<\/p>/g,
        out: '<p class="officeconversion-textbodyuser officeconversion-alter-separator">$1</p>'
    },
    {
        // docs/confidentialite-compte-de-result
        in: /<p class="officeconversion-quote">(\s*)(\*+)(\s*)<\/p>/g,
        out: '<p class="officeconversion-textbody officeconversion-alter-separator">$2</p>'
    }
];

const ALTER_NOTE_ANCHOR_REPLACEMENTS = [
    {
        in: /("|#)ftn(\d*)"/g,
        out: '$1note_$2"'
    },
    {
        in: /("|#)bodyftn(\d*)"/g,
        out: '$1appel_note_$2"'
    }
];

const ALTER_ESPACE_INSECABLE = {
    in: new RegExp(char_insecable, 'g'),
    out: '&nbsp;'
};

const ALTER_DOUBLE_CHEVRONS = [
    {
        in: /<q>«(&nbsp;| )*/g,
        out: '<q>'
    },
    {
        in: /«(&nbsp;| )*<q>/g,
        out: '<q>'
    },
    {
        in: /(&nbsp;| )*»<\/q>/g,
        out: '</q>'
    },
    {
        in: /<\/q>(&nbsp;| )*»/g,
        out: '</q>'
    }
];

export {
    ALTER_DOUBLE_CHEVRONS,
    ALTER_ESPACE_INSECABLE,
    ALTER_NOTE_ANCHOR_REPLACEMENTS,
    ALTER_SEPARATOR_REPLACEMENTS
};
