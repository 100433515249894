<template>
  <button
    v-on:click.prevent.stop="open_modal = !open_modal"
    v-bind:class="$attrs.class">
    <slot>Open Modal</slot>
  </button>
  <Teleport to="body">
    <Transition name="slide-right">
      <div
        v-if="open_modal"
        v-bind:key="open_modal"
        class="fixed bottom-0 left-0 top-0 z-[9999] max-w-[320px] overflow-auto bg-white">
        <div class="flex h-24 items-center bg-[#D66C54] text-white">
          <font-awesome-icon
            icon="user-circle"
            size="3x"
            class="px-4" />

          <app-link
            v-if="!authenticated"
            login_external
            v-bind:to="login_url"
            class="flex h-full w-full flex-col justify-center self-stretch px-2 font-semibold transition-[line-height]">
            <span>Se connecter</span>
          </app-link>
          <span
            v-else
            class="font-semibold"
            v-html="fullname" />
        </div>
        <app-account-menu-list
          v-if="authenticated"
          v-bind:is="'div'"
          hover_class="hover:text-black"
          class="!bg-slate-50 p-3"
          v-on:click_triggered="open_modal = false" />
        <div class="p-4">
          <template
            v-for="bloc in documentation_menu"
            v-bind:key="bloc.label">
            <div class="mb-3 mt-6 text-xl font-semibold text-slate-500">
              {{ bloc.label }}
            </div>
            <div class="flex w-full flex-col gap-1">
              <app-link
                v-for="link in bloc.links"
                v-bind:key="link.id"
                v-bind:to="{ path: link.path }"
                v-on:click="open_modal = false"
                class="rounded-lg p-2.5 hover:bg-slate-50"
                v-bind:active-class="'bg-slate-200'">
                {{ link.label }}
              </app-link>
            </div>
          </template>
        </div>
      </div>
    </Transition>
    <transition name="fade">
      <div
        v-if="open_modal"
        v-on:click="open_modal = false"
        class="fixed inset-0 z-[999] bg-black/75"></div>
    </transition>
  </Teleport>
</template>

<script setup>
  import AppAccountMenuList from '@/js/web-component/app-account-menu-list.vue';
  import AppLink from '@/js/web-component/app-link.vue';

  import { ref } from 'vue';

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { authenticated, fullname } = storeToRefs(store);

  import { documentation_menu } from '@/js/data/menu.js';

  import { useLoginUrl } from '@/js/composable/helper';
  const login_url = useLoginUrl();

  const open_modal = ref(false);
</script>
