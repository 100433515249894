import logger from 'loglevel';

import sync from '../lib/sync.js';

function checkAuth(store) {
    return fetchAuthData(store);
}

function fetchAuthData(store) {
    // TODO : polling 'du pauvre'
    // refactorer avec un socket.io ?

    return sync
        .fetchUserSelf()
        .then((res) => {
            const account_info = res.data?.user_profile;
            if (account_info) {
                store.oidc_mode = !!account_info.oidc_mode;
                store.fullname = `${
                    account_info.given_name
                }<br> ${account_info.family_name.toUpperCase()}`;
                store.is_aglae = account_info.app_aglae;
                store.is_agenor = account_info.app_agenor;
                store.needs_training = account_info.cac_needs_personal_training;
            }

            store.authenticated = res.data.authenticated || store.viewer_mode;
            store.privileged = res.data.privileged || store.viewer_mode;
            store.referrer = res.data.referrer;
            store.granter = res.data.granter;

            if (res.data.accesses) {
                if (res.data.accesses.responses) {
                    store.responses_todo = res.data.accesses.responses.todo;
                }
                store.requests_pending = res.data.accesses.pending;
                store.requests_refused = res.data.accesses.refused;
                store.requests_granted = res.data.accesses.granted;
                store.requests_expired = res.data.accesses.expired;
                store.requests_revoked = res.data.accesses.revoked;
            } else {
                // on supprime les droits puisque plus aucun retourné
                store.responses_todo = null;
                store.requests_pending = null;
                store.requests_refused = null;
                store.requests_granted = null;
                store.requests_expired = null;
                store.requests_revoked = null;
            }
            return res.status;
        })
        .catch((err) => {
            store.authenticated = false || store.viewer_mode;
            logger.error('Unexpected promise error:', err, err.stack);
            return err.response.status;
        });
}

export default {
    checkAuth
};
