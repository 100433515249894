import { computed, getCurrentInstance } from 'vue';

export async function useMatomo() {
    const { appContext } = getCurrentInstance();

    const globalProps = computed(() => {
        return appContext.config.globalProperties;
    });

    const waitUntil = (condition, checkInterval = 100) => {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (!condition()) return;
                clearInterval(interval);
                resolve();
            }, checkInterval);
        });
    };

    await waitUntil(() => typeof globalProps.value.$matomo !== 'undefined');
    return globalProps.value.$matomo;
}
