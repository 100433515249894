<template>
  <app-modal
    v-if="!store.privileged"
    v-on:close="store.hide_limited_access_message = true"
    no_trigger_button
    v-bind:static_display="store.hide_limited_access_message !== true"
    max_width="max-w-screen-lg">
    <template v-slot:header>Accès limité</template>
    <template v-slot:main>
      <app-card-connect />
    </template>
  </app-modal>
  <app-search-accueil
    v-bind:init_query="route.query.q"
    v-on:reload_search="fetchData"
    class="container mb-8 py-5" />
  <div
    class="container flex h-full w-full flex-wrap gap-12 px-5 lg:flex-nowrap">
    <div
      v-if="!search_api_errors && result.totalElements > 0"
      class="flex w-full flex-1 flex-col lg:min-w-[380px]">
      <div
        class="lg:sticky lg:top-24"
        v-bind:class="{ 'v-bind--max-height': !is_mobile_viewport }">
        <app-rubrique-titre
          v-bind:titre="'Filtres'"
          class="!text-[24px] !text-[#787E8E]" />
        <template v-if="has_active_facets">
          <button
            v-on:click="clearFilters"
            class="inline-flex text-[#787E8E] underline underline-offset-4">
            Supprimer tous les filtres
          </button>
          <div class="my-4 flex w-full flex-wrap gap-1">
            <button
              v-for="active_facet in active_facets_list"
              v-on:click="clearActiveFilter(active_facet)"
              v-bind:key="active_facet.value"
              class="inline-flex items-center justify-center gap-3 truncate rounded bg-[#004787] px-3 py-2.5 text-base leading-none text-white">
              <span
                class="truncate"
                v-if="
                  ['avecPiecesJointes', 'avecOutils'].includes(
                    active_facet.category
                  )
                ">
                {{ useLabelGetter(active_facet.category) }}&nbsp;:
                {{ useLabelGetter(active_facet.value) }}
              </span>
              <span
                class="truncate"
                v-else>
                {{ useLabelGetter(active_facet.value) }}
              </span>
              <font-awesome-icon
                icon="xmark"
                fixed-width
                class="mt-0.5" />
            </button>
          </div>
        </template>
        <div class="my-2 flex w-full items-center justify-between pr-5">
          <div class="flex-grow">
            <button
              v-if="is_mobile_viewport"
              v-scroll-to="vscroll_param('#search-results')"
              class="flex items-end gap-2 text-sm text-slate-500">
              <span>Aller aux résultats de recherche</span>
              <font-awesome-icon
                size="xs"
                icon="arrow-down"
                class="pb-1" />
            </button>
          </div>
          <div class="flex-shrink space-x-1">
            <button
              v-on:click="openAllFacet"
              v-tooltip="{ content: 'Tout déplier', distance: 10 }">
              <font-awesome-icon
                size="lg"
                fixed-width
                icon="circle-plus"
                class="text-slate-300 hover:text-slate-400" />
            </button>
            <button
              v-on:click="closeAllFacet"
              v-tooltip="{ content: 'Tout replier', distance: 10 }">
              <font-awesome-icon
                size="lg"
                fixed-width
                icon="circle-minus"
                class="text-slate-300 hover:text-slate-400" />
            </button>
          </div>
        </div>
        <div
          ref="facet_overflow"
          class="h-inherit relative max-h-full overflow-auto overscroll-contain pr-6">
          <div
            v-if="getting_data"
            class="bg-striped absolute inset-0 cursor-not-allowed opacity-25"></div>
          <template
            v-if="result.facets"
            v-for="facet_category in sorted_facets_list"
            v-bind:key="facet_category.name">
            <template v-if="facet_category.values.length">
              <div
                v-on:click="toggleFacet(facet_category.name)"
                class="flex cursor-pointer items-center justify-between border-b border-[#3F435029] pb-3 pr-1.5 pt-4 uppercase text-[#A1B4CA] hover:text-[#577ca7]">
                <span>{{
                  labels.labelCategoryFacets[facet_category.name] ||
                  facet_category.name
                }}</span>
                <font-awesome-icon
                  v-bind:icon="
                    facet_expander[facet_category.name]
                      ? 'caret-right'
                      : 'caret-down'
                  "
                  fixed-width
                  size="sm" />
              </div>
              <transition name="slide-right">
                <div
                  v-if="!facet_expander[facet_category.name]"
                  class="flex flex-col gap-0.5 py-2.5">
                  <template
                    v-for="facet in facet_category.values"
                    v-bind:key="`${facet_category.name}_${facet.value}`">
                    <app-input-checkbox
                      v-bind:disabled="getting_data"
                      v-bind:name_id="`${facet_category.name}_${facet.value}`"
                      v-bind:label="facet.value"
                      v-bind:extra_info="facet.count"
                      v-bind:value="facet.selected"
                      v-on:input="
                        (is_facet_active) => {
                          if (is_facet_active) {
                            facet.selected = is_facet_active;
                          } else {
                            clearActiveFilter({
                              category: facet_category.name,
                              value: facet.value
                            });
                          }
                        }
                      " />
                  </template>
                </div>
              </transition>
            </template>
          </template>
        </div>
      </div>
    </div>
    <div
      class="flex w-full flex-col space-y-4"
      tabindex="0">
      <transition
        v-if="getting_data"
        name="fade"
        appear>
        <div class="flex flex-wrap justify-center">
          <div class="w-full text-center text-2xl">
            Recherche en cours ... Veuillez patienter ...
          </div>
          <app-spinner />
        </div>
      </transition>
      <div
        v-else-if="search_api_errors"
        class="flex w-full justify-center">
        <div
          class="mt-8 flex min-w-full flex-col items-start gap-16 rounded bg-red-100/50 py-8 pl-10 pr-16 text-lg text-red-700/80">
          <div class="flex items-center gap-5">
            <font-awesome-icon
              icon="circle-xmark"
              class=""
              fixed-width
              size="3x" />
            <div class="text-xl">
              <div class="pl-0.5 font-bold">
                Erreur de saisie dans le champs de recherche
              </div>
              <li class="list-inside list-disc space-x-1">
                <span>{{ search_api_errors.detail }}</span>
                <!-- <span>(erreur {{ search_api_errors.status }}) </span> -->
              </li>
            </div>
          </div>

          <a
            v-on:click="router.go(-1)"
            class="flex w-full cursor-pointer items-center space-x-2 pl-16 hover:underline">
            <font-awesome-icon
              icon="arrow-left"
              class="mt-0.5"
              fixed-width />
            <span>Retour à la page précédente</span>
          </a>
        </div>
      </div>
      <template v-else>
        <app-doc-list
          id="search-results"
          v-bind:titre="`<span class='font-semibold text-[#787E8E]'>${total} résultat${
            total > 1 ? 's' : ''
          } pour <hit class='text-[#004787]'>${route.query.q}</hit></span>`"
          v-bind:documents="result?.content"
          is_search_result>
          <div
            v-if="result?.totalElements === 0"
            class="w-full rounded border border-slate-100 p-8 text-xl/relaxed shadow-md">
            <div
              class="flex flex-wrap items-center justify-start gap-4 text-slate-500">
              <div class="text-2xl font-semibold">
                Aucun résultat ne correspond à vos critères de recherche.
              </div>

              <div
                v-if="result?.spellcheckSuggestions || route.query.f"
                class="mb-6 w-full">
                <!-- <div class="mb-4 border-l pl-4 text-xl">
                  Suggestion de recherche&nbsp;:
                </div> -->
                <div class="flex gap-6 px-3">
                  <app-search-spellcheck
                    v-if="result?.spellcheckSuggestions"
                    v-bind:spellcheck_suggestions="
                      result.spellcheckSuggestions
                    " />
                  <div v-if="result?.spellcheckSuggestions && route.query.f">
                    OU
                  </div>
                  <button
                    v-if="route.query.f"
                    class="inline-flex rounded-[18px] bg-slate-100 px-6 py-1 hover:bg-slate-100/70"
                    v-on:click="router.push({ query: { q: route.query.q } })">
                    <div
                      class="font-['Lato'] font-semibold leading-normal text-slate-500">
                      Relancer la recherche sans aucun filtre
                    </div>
                  </button>
                </div>
              </div>
              <a
                v-on:click="router.go(-1)"
                class="flex w-full cursor-pointer items-center space-x-2 pl-1 hover:underline">
                <font-awesome-icon
                  icon="arrow-left"
                  class="mt-0.5"
                  fixed-width />
                <span>Retour à la page précédente</span>
              </a>
            </div>
          </div>
        </app-doc-list>

        <div
          class="h-1 w-full"
          v-intersection-observer="onIntersectionObserver"></div>
      </template>

      <transition
        name="fade"
        appear>
        <div
          v-if="getting_more_data"
          class="flex flex-wrap justify-center">
          <div class="w-full text-center text-2xl">Chargement en cours ...</div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
  const DEFAULT_RESULT_PAGE_SIZE = 10;

  import AppCardConnect from '@/js/web-component/app-card-connect.vue';
  import AppDocList from '@/js/web-component/app-doc-list.vue';
  import AppInputCheckbox from '@/js/web-component/app-input-checkbox.vue';
  import AppModal from '@/js/web-component/app-modal.vue';
  import AppRubriqueTitre from '@/js/web-component/app-rubrique-titre.vue';
  import AppSearchAccueil from '@/js/web-component/app-search-accueil.vue';
  import AppSearchSpellcheck from '@/js/web-component/app-search-spellcheck.vue';
  import AppSpinner from '@/js/web-component/app-spinner.vue';

  import sync from '@/js/lib/sync';

  import { computed, onBeforeMount, reactive, ref, toValue, watch } from 'vue';

  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { is_mobile_viewport } = storeToRefs(store);

  import { useVueScrollTo } from '@/js/composable/vscrollto';
  const { vScrollTo, vscroll_param } = useVueScrollTo();

  import { vIntersectionObserver } from '@vueuse/components';
  import { useElementBounding, watchOnce, watchThrottled } from '@vueuse/core';
  import { useClamp } from '@vueuse/math';

  import {
    capitalizeFirstLetter,
    useLabelGetter
  } from '@/js/composable/label_getter.js';
  import labels from '@/js/data/labels.js';

  import {
    isSameArray,
    query_object_to_flat_array
  } from '../composable/helper';

  const getting_data = ref(false);
  const getting_more_data = ref(false);
  const search_api_errors = ref(null);

  const facet_expander = reactive({});
  function toggleFacet(key) {
    facet_expander[key] = !facet_expander[key];
  }

  function openAllFacet() {
    Object.keys(facet_expander).map((key) => (facet_expander[key] = false));
  }

  function closeAllFacet() {
    const prevent_list = active_facets_list.value.map((f) => f.category);

    Object.keys(facet_expander).map(
      (key) =>
        (facet_expander[key] = !prevent_list.includes(key) ? true : false)
    );
  }

  const result = ref([]);
  watch(
    () => is_mobile_viewport.value,
    (is_mobile) => {
      is_mobile ? closeAllFacet() : openAllFacet();
    }
  );
  watchOnce(
    () => result.value.facets,
    (result_facets) => {
      result_facets.forEach((f) => {
        facet_expander[f.name] = null;
      });
      is_mobile_viewport.value ? closeAllFacet() : openAllFacet();
    }
  );
  watchThrottled(
    () => result.value.facets,
    (result_facets) => {
      // const current_facets_list = result_facets?.reduce((prev, curr) => {
      //   return [...prev, ...curr.values.map((facet) => facet.value)];
      // }, []);
      // store.search_current_facets_list = current_facets_list;

      const query_facets = result_facets?.reduce((prev, curr) => {
        const current_facet = curr.values
          .filter((f) => f.selected)
          .map((facet) => facet?.value);
        return { ...prev, [curr.name]: current_facet };
      }, {});
      // we reassign store.search_query_params_facets only if its values are different from result.value.facets that is updated after fetchData POST response
      // otherwise the watcher on store.search_query_params_facets value would trigger useless refresh that would call twice fetchData for no reason
      if (query_facets) {
        if (
          (!query_object_to_flat_array(store.search_query_params_facets)
            .length > 0 &&
            query_object_to_flat_array(query_facets) > 0) ||
          !isSameArray(
            query_object_to_flat_array(store.search_query_params_facets),
            query_object_to_flat_array(query_facets)
          )
        ) {
          store.search_query_params_facets = query_facets;

          if (is_mobile_viewport.value) {
            closeAllFacet();
            setTimeout(() => {
              vScrollTo.scrollTo(
                '#search-results',
                vscroll_param('#search-results')
              );
            }, 300);
          }
        }
      }
    },
    {
      deep: true,
      throttle: 500
    }
  );

  const pageSize = DEFAULT_RESULT_PAGE_SIZE;
  const total = computed(() => result.value.totalElements || 0);
  const page = computed(() => result.value.page || 0);

  const pagesCount = computed(() =>
    Math.max(1, Math.floor(toValue(total) / toValue(pageSize)))
  );
  const currentPage = useClamp(page, 1, pagesCount);
  const isLastPage = computed(() => currentPage.value === pagesCount.value);

  onBeforeMount(async () => {
    await fetchData();
  });

  watch(
    () => route.query,
    async () => {
      // console.dir('happen QUERY', newQuery);
      await fetchData();
    },
    { deep: true }
  );
  watch(
    () => store.search_current_sort,
    (newSort) => {
      if (newSort) {
        // console.dir('happen SORT', newSort);
        router.push({ query: { ...route.query, sort: newSort } });
      }
    }
  );
  watchThrottled(
    () => store.search_query_params_facets,
    (newFacets) => {
      if (newFacets && !getting_data.value) {
        router.push({
          query: { ...route.query, f: newFacets }
        });
      }
    },
    {
      deep: true,
      throttle: 500
    }
  );

  function fetchFacetsQuery() {
    const query_facets = [];
    if (route.query.f && Object.values(route.query.f).some((v) => v.length)) {
      for (const [key, value] of Object.entries(route.query.f)) {
        query_facets.push({
          name: key,
          selectedValues: Array.isArray(value) ? value : value.split(',')
        });
      }
      // console.log('FACETS from ROUTE', query_facets);
    }
    return query_facets;
  }

  function setupRequestOptions(page_val) {
    const size = DEFAULT_RESULT_PAGE_SIZE;

    if (route.query.sort) store.search_current_sort = route.query.sort;
    const sort = store.search_current_sort;

    const params = { params: route.query };

    const facets = fetchFacetsQuery();

    const body = {
      facets,
      page: page_val,
      size,
      sort,
      debug: store.is_debug_on
    };

    return { body, params };
  }

  async function fetchData() {
    search_api_errors.value = null;
    getting_data.value = true;

    const { body, params } = setupRequestOptions(0);

    await sync
      .fetchSearchResult(body, params)
      .then((search_res) => {
        if (search_res.data?.status === 400) {
          search_api_errors.value = search_res.data;
        } else {
          result.value = search_res.data;
        }
        getting_data.value = false;
      })
      .catch((err) => {
        getting_data.value = false;
        router.replace('/error/' + err.response.status);
      });
  }

  async function fetchMoreData() {
    if (result.value.currentPage !== null && !isLastPage.value) {
      getting_more_data.value = true;

      const { body, params } = setupRequestOptions(page.value + 1);

      await sync
        .fetchSearchResult(body, params)
        .then((search_res) => {
          const new_content = [
            ...result.value.content,
            ...search_res.data.content
          ];
          result.value = search_res.data;
          result.value.content = new_content;
          getting_more_data.value = false;
        })
        .catch((err) => {
          getting_more_data.value = false;
          router.replace('/error/' + err.response.status);
        });
    }
  }

  const isVisible = ref(false);
  function onIntersectionObserver([{ isIntersecting }]) {
    isVisible.value = isIntersecting;
  }
  watch(
    () => isVisible.value,
    async (newVisibility) => {
      if (newVisibility) {
        await fetchMoreData();
      }
    }
  );

  function clearFilters() {
    result.value.facets.map((c) => {
      c.values.map((f) => {
        f.selected = false;
        return f;
      });
      return c;
    });
  }

  function clearActiveFilter(facet) {
    const cat_index = result.value.facets.findIndex((c) => {
      return c.name == facet.category;
    });

    const facet_index = result.value.facets[cat_index].values.findIndex((f) => {
      return f.value == facet.value;
    });

    if (
      result.value.facets[cat_index].values[facet_index].value ===
      store.search_selected_type
    ) {
      store.search_selected_type = null;
    }

    result.value.facets[cat_index].values[facet_index].selected = false;
  }

  const sorted_facets_list = computed(() => {
    const sortingArr = Object.keys(labels.labelCategoryFacets);

    return result.value.facets
      .map((category) => {
        const yes_no_arr = ['oui', 'non'];
        if (
          category.values.every((v) => {
            return yes_no_arr.includes(v.value);
          })
        ) {
          category.values.sort(
            (a, b) => yes_no_arr.indexOf(a.value) - yes_no_arr.indexOf(b.value)
          );
          return category;
        } else if (['classe', 'statut'].includes(category.name)) {
          const target_name = `label${capitalizeFirstLetter(category.name)}`;
          const target_obj = labels[target_name];

          const statusArr = Object.keys(target_obj);
          category.values.sort((a, b) => {
            return statusArr.indexOf(a.value) - statusArr.indexOf(b.value);
          });
          return category;
        } else {
          category.values.sort((a, b) =>
            useLabelGetter(a.value).localeCompare(useLabelGetter(b.value))
          );
          return category;
        }
      })
      .sort((a, b) => {
        return sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name);
      });
  });

  const active_facets_list = computed(() => {
    const selected_list = [];
    result.value.facets?.forEach((category) => {
      const selected = [];
      const selected_in_category = category.values.filter((f) => f.selected);
      if (selected_in_category.length) {
        selected.push(
          ...selected_in_category.map((s) => {
            return { category: category.name, value: s.value };
          })
        );
        selected_list.push(...selected);
      }
    });

    return selected_list;
  });

  const has_active_facets = computed(() => {
    return active_facets_list.value.length > 0;
  });

  const facet_overflow = ref(null);
  // eslint-disable-next-line no-shadow
  const { top } = useElementBounding(facet_overflow);
  const offset_height = computed(() => {
    return `95vh - ${top.value}px - ${isVisible.value ? '96px' : '0px'}`;
  });
</script>

<style lang="scss" scoped>
  :deep(.v-bind--max-height) {
    transition: max-height 0.1s ease-in-out;
    max-height: calc(v-bind(offset_height));
  }

  .bg-striped {
    background-image: linear-gradient(
      45deg,
      transparent 25%,
      theme('colors.slate.100') 25%,
      theme('colors.slate.100') 50%,
      transparent 50%,
      transparent 75%,
      theme('colors.slate.100') 75%,
      theme('colors.slate.100') 100%
    );
    background-size: 40px 40px;
  }
</style>
