<template>
  <div class="flex h-11 w-full gap-6">
    <app-search-advanced-combobox
      v-if="!no_combo"
      v-model="combobox"
      v-bind:init_num="conditions[props.row.condition]"
      class="flex-0 rounded border-2 border-[#DBE1F2]" />
    <!-- {{ is_error }} -->
    <div
      class="flex w-full flex-grow overflow-hidden rounded border-2 border-[#DBE1F2] bg-white"
      v-bind:class="{ '!border-red-500': is_error }">
      <input
        required
        v-model="input"
        type="text"
        v-bind:placeholder="
          !no_combo ? 'Critère complémentaire...' : 'Critère principal...'
        "
        class="w-full py-3 pl-4 pr-3.5 text-xs font-normal leading-tight text-slate-500 disabled:cursor-not-allowed disabled:bg-slate-100" />
    </div>

    <button
      v-if="!no_combo"
      v-on:click="emit('delete')">
      <font-awesome-icon icon="trash" />
    </button>
  </div>
</template>

<script setup>
  import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
  import { computed, ref, watch } from 'vue';
  import AppSearchAdvancedCombobox from './app-search-advanced-combobox.vue';

  const props = defineProps({
    row: Object,
    no_combo: Boolean,
    is_error: Boolean
  });
  const emit = defineEmits(['change', 'delete']);

  const conditions = {
    ET: 0,
    OU: 1,
    SAUF: 2
  };
  const combobox = ref(null);
  const input = ref(props.row.text);

  const condition = computed(() => {
    return !props.no_combo
      ? combobox.value?.elastic_value
      : props.row.condition;
  });
  const text = computed(() => {
    return input.value;
  });
  watch(
    [condition, text],
    ([newCondition, newText], [oldCondition, oldText]) => {
      if (newCondition !== oldCondition || newText !== oldText) {
        const row_val = {
          id: props.row.id,
          condition: newCondition,
          text: newText
        };
        emit('change', row_val);
      }
    }
  );
</script>
