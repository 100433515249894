import sync from '@/js/lib/sync';

import { ref } from 'vue';

import neps from '../data/neps';

export async function useBulletinsFormater() {
    const bu_defs = ref(null);

    const extractPerDates = ref(null);
    const uniqueDates = ref(null);
    const criteria = [];
    criteria.push({ name: 'classe', val: 'ouvrage' });
    criteria.push({ name: 'ouvrage_type', val: 'bulletin' });

    try {
        bu_defs.value = await sync.fetchDocDefs(criteria);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error while fetching Bulletins"', err);
    }

    extractPerDates.value = Object.values(bu_defs.value)
        .map((def) => {
            const extract_date = (val) => val && val.split(/[., _-]/).pop();
            // TODO : faire un test de récupération plus robuste (contrainte du format année inclus dans un range de 2003 à current year ?)

            const date = extract_date(def.meta?.titre) || null;
            return Number.isInteger(Number(date))
                ? { date, ...def }
                : { date: def.meta.date_publication.split('-')[0], ...def };
        })
        .sort();

    uniqueDates.value = [
        ...new Set(
            extractPerDates.value
                .map((val) => val.date)
                .sort()
                .reverse()
        )
    ];

    return uniqueDates.value.map((date) => {
        return {
            label: date,
            date_rubrique: `${date}-01-01`,
            type: `id_${date}`,
            articles: extractPerDates.value.filter((bu) => bu.date === date)
        };
    });
}

export async function useNormesFormater() {
    const rubriques_refs = [
        {
            type: 'nep_1',
            label: 'I – Audit des comptes mis en oeuvre dans le cadre de la certification des comptes ',
            sub_list: [
                { type: 'nep_1_1', label: 'Aspects généraux' },
                {
                    type: 'nep_1_2',
                    label: `Évaluation du risque d'anomalies significatives et procédures d'audit mises en oeuvres`
                },
                {
                    type: 'nep_1_3',
                    label: `Caractère probant des éléments collectés`
                },
                {
                    type: 'nep_1_4',
                    label: `Utilisation des travaux d'autres professionnels`
                },
                { type: 'nep_1_5', label: 'Rapports' },
                {
                    type: 'nep_1_6',
                    label: 'Audit des comptes mis en oeuvre dans certaines entités'
                }
            ]
        },
        {
            type: 'nep_2',
            label: 'II – Examen limité en application de dispositions légales et réglementaires'
        },
        {
            type: 'nep_3',
            label: 'III – Diligences directement liées à la mission du commissaire aux comptes(normes archivées car devenues caduques le 17 juin 2016'
        },
        {
            type: 'nep_4',
            label: `IV – Interventions en application d'autres dispositions légales ou réglementaire`
        },
        {
            type: 'nep_5',
            label: `V – Autres obligations du commissaire aux comptes en application de dispositions légales ou réglementaires`
        }
    ];

    try {
        const docs_by_name = await sync.fetchDocDefs([
            { name: 'type', val: 'norme_exe_pro' },
            { name: 'sorted_by_name', val: 1 }
        ]);

        const docs_defs = {};

        let docs_defs_per_nep;

        Object.keys(neps).forEach((nep_name) => {
            docs_defs_per_nep = [];

            neps[nep_name].forEach((doc_name) => {
                if (!docs_by_name[doc_name]) {
                    // eslint-disable-next-line no-console
                    console.error('No doc by name', doc_name);
                } else {
                    docs_defs_per_nep.push(docs_by_name[doc_name]);
                }
            });

            docs_defs[nep_name] = docs_defs_per_nep;
        });

        rubriques_refs.map((r) => {
            if (r.type === 'nep_1') {
                return r.sub_list.map((sub) => {
                    return (sub.articles = docs_defs[sub.type]);
                });
            } else {
                return (r.articles = docs_defs[r.type]);
            }
        });
        // console.log(docs_defs, rubriques_refs, 'NEPS ?');
        return rubriques_refs;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error while fetching NEPS', err);
    }
}

export async function useEipFormater() {
    const list_content = {
        ouvrages: { type: 'ouvrages', label: 'Ouvrages', articles: [] },
        outils: { type: 'outils', label: 'Outils', articles: [] },
        communiques: {
            type: 'communiques',
            label: 'Communiqués',
            articles: []
        },
        autres: { type: 'autres', label: 'Autres', articles: [] }
    };

    try {
        await Promise.all(
            // ouvrages
            (list_content.ouvrages.articles = await sync.fetchDocDefs([
                { name: 'classe', val: 'document' },
                { name: 'source', val: 'dmf' },
                { name: 'type', val: 'ouvrage' }
            ])),
            // outils - classe document
            (list_content.outils.articles = [
                ...(await sync.fetchDocDefs([
                    { name: 'classe', val: 'document' },
                    { name: 'source', val: 'dmf' },
                    { name: 'type', val: 'exemple_outil' }
                ])),
                ...(await sync.fetchDocDefs([
                    { name: 'classe', val: 'outil' },
                    { name: 'source', val: 'dmf' }
                ]))
            ]),
            // communiqués
            (list_content.communiques.articles = await sync.fetchDocDefs([
                { name: 'classe', val: 'document' },
                { name: 'source', val: 'dmf' },
                { name: 'type', val: 'communique_cncc' }
            ])),
            // autres
            (list_content.autres.articles = await sync.fetchDocDefs([
                { name: 'classe', val: 'document' },
                { name: 'source', val: 'dmf' },
                { name: 'not:type', val: 'ouvrage' },
                { name: 'not:type', val: 'exemple_outil' },
                { name: 'not:type', val: 'communique_cncc' }
            ]))
        );
        // console.log(list_content);
        return Object.values(list_content);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error while fetching Rubriques EIP', err);
    }
}
