export default {
    nep_1_1: [
        'nep-100-audit-des-comptes-realis-65eec39aca13d',
        'nep-200-principes-applicables-a-p',
        'nep-210-lettre-de-mission-65eed83e693ef',
        'nep-230-documentation-de-laudit-x',
        'nep-240-prise-en-consideration-d-65eedaeca9dfe',
        'nep-250-prise-en-compte-du-risqu',
        'communications-avec-les-organes',
        'communication-des-faiblesses-du'
    ],
    nep_1_2: [
        'nep-300-planification-de-laudit-65eee18d14f3e',
        'nep-315-connaissance-de-lentite',
        'nep-320-application-de-la-notion',
        'nep-330-procedures-daudit-mises',
        'nep-450-evaluation-des-anomalies-65eef929885fb'
    ],
    nep_1_3: [
        'nep-500-caractere-probant-des-el',
        'nep-501-caractere-probant-des-el',
        'nep-505-demandes-de-confirmation',
        'nep-510-controle-du-bilan-douver-65eefe6102fb8',
        'nep-520-procedures-analytiques',
        'nep-530-selection-des-elements-a',
        'nep-540-audit-des-estimations-co-65ef00641c71c',
        'nep-550-relations-et-transaction-65ef016ff2c1f',
        'nep-560-evenements-posterieurs-a-65ef027a21b95',
        'nep-570-continuite-dexploitation-65ef060ab8b95',
        'nep-580-declarations-de-la-direc'
    ],
    nep_1_4: [
        'nep-600-principes-specifiques-ap-65ef0a7b59b83',
        'nep-610-prise-de-connaissance-et',
        'nep-620-intervention-dun-expert-65ef0c04834ba',
        'nep-630-utilisation-des-travaux'
    ],
    nep_1_5: [
        'nep-700-rapports-du-commissaire-x',
        'nep-701-justification-des-apprec-65ef0fb47f533',
        'nep-702-justification-des-apprec-65ef10dd8d685',
        'kk3180',
        'nep-710-informations-relatives-a',
        'nep-730-changements-comptables-65ef152d10b82'
    ],
    nep_1_6: [
        'nep-910-certificati',
        'nep-911-mission-du-commissaire-a-65ef16535784d',
        'nep-912-mission-du-commissaire-a-65ef16f80ec20',
        'nep-920-certification-des-compte-65ef17deb79ca'
    ],
    nep_2: ['nep-2410-examen-limite-de-compte-65ef185b3a592'],
    nep_3: [
        'nep-9010',
        'nep-9020',
        'nep-9030-2',
        'nep-5409',
        'nep-9050-2',
        'nep-9060',
        'nep-9070',
        'nep-9080',
        'nep-9090'
    ],
    nep_4: [
        'nep-9510-diligences-du-commissai-65ef18fd9e1ae',
        'nep-9520-diligences-du-commissai-65ef19c6001f1'
    ],
    nep_5: ['nep-9605-obligations-du-commissa-65ef1ac26b716']
};
