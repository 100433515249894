<template>
  <div>
    <div class="container py-16 text-xl">
      <div
        id="error-content"
        class="space-y-7 rounded-xl border border-slate-200 px-4 py-8 text-center text-slate-500">
        <template v-if="error_id == '400'">
          <h1>Requête invalide</h1>
          <div>La syntaxe de la requête est erronée.</div>
        </template>

        <template v-else-if="error_id == '401'">
          <app-card-connect />
        </template>

        <template v-else-if="error_id == '403'">
          <h1>Accès interdit</h1>
          <div>
            Vous n’avez pas les permissions appropriées pour accéder à cette
            ressource.
          </div>
        </template>

        <template v-else-if="error_id == '404'">
          <h1>Ressource non trouvée</h1>
          <div>Cette ressource n’existe pas ou n’est plus disponible.</div>
        </template>

        <template v-else-if="error_id == '500'">
          <h1>Erreur du serveur</h1>
          <div>Erreur interne du serveur.</div>
        </template>

        <template v-else-if="error_id == '502'">
          <h1>Erreur de passerelle réseau</h1>
        </template>

        <template v-else-if="error_id == '503'">
          <h1>Service indisponible</h1>
          <div>
            Le service est temporairement indisponible ou en maintenance.
          </div>
        </template>

        <template v-else-if="error_id == '504'">
          <h1>Pas de réponse du serveur</h1>
          <div>Le temps d’attente de réponse du serveur est écoulé.</div>
        </template>

        <template v-else>
          <h1>Erreur non gérée</h1>
        </template>

        <div class="inline-flex flex-wrap justify-center space-y-5">
          <template v-if="error_id !== '401'">
            <div
              v-on:click="return_path"
              class="cursor-pointer rounded bg-slate-300 px-6 py-3 text-slate-600 shadow hover:bg-slate-200">
              Retour à la page précédente
            </div>
            <code class="w-full text-xs">Code d'erreur : {{ error_id }}</code>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import AppCardConnect from '@/js/web-component/app-card-connect.vue';

  import { onBeforeMount, ref, watch } from 'vue';

  import { useRoute } from 'vue-router';
  import router from '../router';
  const route = useRoute();

  const error_id = ref('');
  const from_path = ref(null);

  const return_path = () => router.go(-1);

  function fetchData() {
    error_id.value = route.params.error_id;

    from_path.value = route.query.from;
  }

  onBeforeMount(async () => {
    fetchData();
    if (from_path.value && ['401', '403'].includes(error_id.value)) {
      await router.push(from_path.value);
    }
  });

  watch(route, () => {
    fetchData();
  });
</script>

<style lang="scss" scoped>
  #error-content {
    h1 {
      @apply text-2xl lg:text-4xl;
    }
    div {
      @apply lg:text-xl;
    }
  }
</style>
