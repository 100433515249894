// Loading main application's styles at the top before possible re-declarations inside vue components that would override it
import '@/css/index.scss';

import App from '@/js/app.vue';

// Initialize Vue3 App
import { createApp } from 'vue';
const app = createApp(App);
const { globalProperties } = app.config;

// Initialize a Pinia Store instance
import { createPinia } from 'pinia';
const pinia = createPinia();
app.use(pinia);

import { useStore } from '@/js/store';
const store_global = useStore();
// Initialize a minimal "hand made" store as a global prop
globalProperties.store = store_global; // Vue Option API accessible via this.store

// Initialize a global event emitter (manual backport from Vue2 behaviour)
// cf. https://v3-migration.vuejs.org/breaking-changes/events-api.html#event-bus
// can be acessed globally using => this.$options[emmiter_method]
// (ex: this.$options.$on('event_string_name', prop_value))
import emitter from '@/js/eventBus.js';
app.mixin(emitter);

// Initialize VueRouter
import router from '@/js/router';
app.use(router);

import auth from '@/js/lib/auth';
router.beforeEach((to, from, next) => {
    const store = useStore();

    // Forcing authentication if the "auth" query param is present in the
    // request. ATM only used for OIDC. This check MUST be done before
    // auth.checkAuth(store) below, otherwise the old-SSO would be used instead
    // of OIDC.
    if (to.query.auth !== undefined) {
        window.location.href = '/user/login';
        return;
    }

    // la route est bloquée jusqu'au retour de la promesse
    // qui appelle alors next()
    // on assure ainsi que le fetchAuthData est fait
    auth.checkAuth(store).then((res_status) => {
        // les routes peuvent avoir en theroie des children
        // d'où plusieurs reponses pour `to`
        // d'où le some
        if (to.matched.some((record) => record.meta.auth_required)) {
            // cette route demande une autorisation, vérifions si l'utilisateur est logué.
            // sinon, redirigeons le sur la page erreur 403.
            if (!store.authenticated) {
                if (res_status === 200) {
                    next({ path: '/error/401', query: { from: to.fullPath } });
                } else {
                    next({
                        path: `/error/${res_status}`,
                        query: { from: to.fullPath }
                    });
                }
            } else {
                next();
            }
        } else {
            next();
        }
    });
});

// Construction dynamique des titres de page
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title && to.meta.title != 'Documentation') {
        document.title = to.meta.title + ' | Documentation | CNCC';
    } else {
        // on met un titre par defaut, mais sera changé dans le chargement du composant Vue
        document.title = 'Documentation | CNCC';
    }
    next();
});

// Initialize Vue-Floating (V-tooltip)
// cf. https://floating-vue.starpad.dev
import { VTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
app.directive('tooltip', VTooltip);

// Initialize VueMatomo plugin
// cf. https://github.com/AmazingDreams/vue-matomo
import VueMatomo from 'vue-matomo';
app.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: 'https://cncc.matomo.cloud',
    siteId: import.meta.env.VITE_APP_MATOMO_SITE_ID || 5,
    router // This will track the vue-router pages
});

// Initialize VueFontAwesome
import VueFontAwesome from '@/js/vueFontAwesome';
app.component(...VueFontAwesome);

// Lastly mounting the Vue application to the DOM
app.mount('#app');
