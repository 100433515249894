// List of named keys to use as reference https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values

import { onKeyDown, onKeyUp } from '@vueuse/core';
import { nextTick, ref } from 'vue';

export function useKeyboardShortcut(matching_pattern) {
    const is_keys_active = ref(false);
    const active_keys = new Set();

    onKeyDown(matching_pattern, async (e) => {
        active_keys.add(e.key);

        if (
            matching_pattern.every((key) =>
                [...active_keys].some((k) => k === key)
            )
        ) {
            e.preventDefault();
            is_keys_active.value = true;
            await nextTick();
            active_keys.clear();
        }
    });
    onKeyUp(matching_pattern, (e) => {
        is_keys_active.value = false;
        active_keys.delete(e.key);
    });

    return is_keys_active;
}
