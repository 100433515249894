const labelTypeDoc = {
    null: 'Tous les types',
    ancienne_norme: 'Ancienne norme',
    attestation: 'Attestation',
    autre_support_institutionnel: 'Autre support institutionnel',
    avis_technique: 'Avis technique',
    bpp: 'Bonne pratique professionnelle - BPP',
    chronique_cec: 'Chroniques CEC',
    chronique_cej: 'Chroniques CEJ',
    chronique_cep: 'Chroniques CEP',
    chronique_cnp: 'Chroniques CNP',
    communication_recue: 'Communication reçue',
    communique_cncc: 'Communiqué',
    etude_juridique: 'Etude juridique',
    exemple_outil: 'Outil / Exemple',
    exemple_rapport: 'Rapport',
    guide_pro: 'Guide professionnel',
    jurisprudence: 'Jurisprudence',
    legislation: 'Législation',
    lettre_affirmation: "Lettre d'affirmation",
    lettre_mission: 'Lettre de mission',
    lettre_nomination: 'Lettre de nomination',
    ni: "Note d'information",
    norme_exe_pro: 'NEP',
    norme_ifrs: 'Normes IFRS',
    norme_isa: 'Norme ISA',
    outil_procédure_alerte: "Outil procédure d'alerte",
    ouvrage: 'Autre ouvrage',
    pack: 'Pack',
    pratique_pro: 'Pratique professionnelle',
    questionnaire: 'Questionnaire',
    rapport_institutionnel: 'Rapport institutionnel',
    reponse_ministerielle: 'Réponse ministérielle',
    support: 'Support',
    video: 'Vidéo',
    vie_du_conseil: 'Vie du conseil'
};

const labelTypeSecteur = {
    administrateur_mandataire_judiciaire:
        'Administrateurs judiciaires et mandataires judiciaires',
    association_fondation: 'Associations et Fondations',
    assurance: "Entreprises d'assurance",
    banque: 'Banques',
    carpa: 'CARPA',
    reseau_consulaire_sem: 'Réseaux consulaires et SEM',
    cil_hlm: 'CIL – Organismes de logement social',
    club_sportif: 'Clubs sportifs',
    comite_entreprise: "Comités d'entreprise",
    cooperation_agricole: 'Coopération agricole',
    etablissement_public_universite:
        'Etablissements publics nationaux - Universités',
    formation_politique: 'Partis et groupements politiques',
    eps_hopital: 'Etablissements publics de santé - Hôpitaux',
    mutuelle: 'Mutuelles',
    opca: 'OPCA - Organismes  paritaires collecteurs agréés',
    opc: 'OPC - Organismes de placement collectif',
    organisme_formation_cfa: 'CFA - Centres de formation des apprentis',
    retraite: 'Institutions de retraite',
    prevoyance: 'Institutions de prévoyance',
    securite_sociale: 'Organismes de Sécurité sociale',
    syndicat: 'Organisations professionnelles et syndicales',
    collectivites: 'Collectivités'
};

const labelStatut = {
    en_cours: 'Dernière version',
    archive: 'Archive',
    infocom: 'Infocom (antérieur à 2003)'
};

const labelClasse = {
    document: 'Documents',
    ouvrage: 'Ouvrages',
    infocom: 'Infocom (antérieur à 2003)'
};

const labelCategoryFacets = {
    statut: 'Statut de publication',
    type: 'Type de document',
    secteur: "Secteur d'activité",
    diffusion: 'Statut de diffusion',
    avecPiecesJointes: 'Avec pièce(s) jointe(s)',
    avecOutils: 'Avec outil(s) lié(s)',
    classe: 'Nature du document'
};

const labelSorterFilter = {
    RELEVANCE: 'Par pertinence',
    DATE_DESC: 'Du plus récent au plus ancien',
    DATE_ASC: 'Du plus ancien au plus récent'
};

const MIXED_TYPES = Object.assign(
    {},
    labelTypeDoc,
    labelTypeSecteur,
    labelStatut,
    labelCategoryFacets,
    labelClasse
);

export default {
    labelTypeDoc,
    labelTypeSecteur,
    labelStatut,
    labelCategoryFacets,
    labelClasse,
    labelSorterFilter
};
export {
    MIXED_TYPES,
    labelCategoryFacets,
    labelClasse,
    labelSorterFilter,
    labelStatut,
    labelTypeDoc,
    labelTypeSecteur
};
