<template>
  <app-search-accueil
    v-bind:init_query="route.query.q"
    class="container mb-8 py-5 2xl:pl-24" />
  <div
    class="container flex h-full w-full flex-wrap gap-4 lg:flex-nowrap lg:gap-12 2xl:pl-24">
    <div>
      <div
        v-on:click="router.go(-1)"
        class="inline-flex cursor-pointer items-center gap-2.5 rounded bg-[#D5E0ED] px-4 py-2 text-[#004787]">
        <font-awesome-icon
          icon="arrow-left"
          fixed-width
          class="mt-0.5" />
        <span>Retour</span>
      </div>
    </div>
    <app-spinner v-if="getting_data" />
    <div
      class="w-full"
      v-else>
      <app-rubrique-titre titre="Mémorisations de mes recherches" />

      <div class="flex h-10 w-full">
        <div
          v-if="message_success || message_fail"
          role="alert"
          class="flex w-full items-center gap-5 p-2 leading-none text-green-100 lg:inline-flex lg:rounded-full"
          v-bind:class="[message_success ? 'bg-green-800' : 'bg-red-800']">
          <span
            class="flex rounded-full p-2 text-xs font-bold uppercase"
            v-bind:class="[message_success ? 'bg-green-500' : 'bg-red-500']">
            <font-awesome-icon
              v-bind:icon="message_success ? 'check' : 'xmark'"
              fixed-width />
          </span>
          <span class="mr-2 flex-auto font-semibold text-white">
            <template v-if="message_success">
              La recherche a été supprimée avec succès
            </template>
            <template v-if="message_fail">
              Cette recherche n’a pas pu être supprimée
            </template></span
          >
        </div>
      </div>

      <div
        v-if="queries.length > 0"
        class="flex w-full flex-wrap rounded-lg border border-[#EEF1F9]">
        <div
          class="flex h-full w-full flex-wrap items-center justify-between gap-3 border-b border-[#EEF1F9] px-5 py-4">
          <div
            class="mt-0.5 inline-flex h-full items-center text-lg/none font-bold text-[#004787]">
            Mes recherches mémorisées
          </div>
          <div
            class="inline-flex gap-1.5 rounded-full bg-slate-200 px-4 py-1 font-semibold">
            <div>{{ queries.length }}</div>
            <div>enregistrement{{ queries.length > 1 ? 's' : '' }}</div>
          </div>
        </div>

        <div class="w-full">
          <app-link
            v-for="item in queries"
            v-bind:id="item.id"
            v-bind:key="item"
            class="flex min-h-[5rem] w-full items-center justify-between border-b border-[#EEF1F9] hover:bg-slate-50 md:gap-12"
            v-bind:to="`/search-result?${item.url}`">
            <div
              class="flex w-full flex-shrink flex-wrap items-center gap-5 py-4 pl-4">
              <div class="flex items-center gap-6 lg:flex-0">
                <font-awesome-icon
                  icon="search"
                  fixed-width
                  class="text-[#A1B4CA]" />
                <div class="flex-auto">
                  <p
                    v-html="item.title"
                    class="text-sm/tight font-semibold first-letter:uppercase"></p>
                  <p class="text-xs font-semibold text-slate-400">
                    {{ item.date_modification }}
                  </p>
                </div>
              </div>
              <div class="flex flex-wrap items-center gap-0.5 lg:gap-1">
                <template
                  v-for="facet in useExtractFacetList(item.url)"
                  v-bind:key="facet.name">
                  <app-label
                    v-bind:item="facet.name"
                    v-bind:categorie="facet.type"
                    class="!h-4 !min-w-0 !text-[10px] lg:!h-5 lg:!text-[11px]"
                    v-bind:is_border="facet.type === 'sort'"
                    no_target />
                </template>
              </div>
            </div>

            <div class="flex h-[inherit] self-stretch">
              <button
                v-on:click.prevent="deleteQuery(item.id)"
                class="h-full w-full border-l border-[#EEF1F9] text-sm font-bold hover:text-red-500 min-[340px]:px-4 lg:px-8">
                <font-awesome-icon
                  icon="trash"
                  fixed-width />
              </button>
            </div>
          </app-link>
        </div>
      </div>

      <div v-else>Aucune recherche mémorisée pour le moment</div>
    </div>
  </div>
</template>

<script setup>
  import logger from 'loglevel';

  import sync from '../lib/sync';

  import { onBeforeMount, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  const store = useStore();
  const { authenticated } = storeToRefs(store);

  import { useExtractFacetList } from '@/js/composable/label_getter';
  import AppLabel from '@/js/web-component/app-label.vue';
  import AppLink from '@/js/web-component/app-link.vue';
  import AppRubriqueTitre from '@/js/web-component/app-rubrique-titre.vue';
  import AppSearchAccueil from '@/js/web-component/app-search-accueil.vue';
  import AppSpinner from '@/js/web-component/app-spinner.vue';

  const queries = ref([]);
  const getting_data = ref(true);
  const message_success = ref(false);
  const message_fail = ref(false);
  const error_message = ref('');

  onBeforeMount(() => {
    // Fetch the data when the view is created and the data is
    // already being observed.
    if (authenticated.value) {
      fetchData();
    }
  });

  function deleteQuery(delete_id) {
    sync
      .deleteSearchQuery(delete_id)
      .then(() => {
        queries.value = queries.value.filter((query) => query.id != delete_id);
        message_success.value = true;
        setTimeout(() => {
          message_success.value = false;
        }, 3000);
      })
      .catch((jqxhr, textStatus, error) => {
        logger.error(jqxhr, textStatus, error);
        message_fail.value = true;
        setTimeout(() => {
          message_fail.value = false;
        }, 3000);
      });
  }

  function fetchData() {
    getting_data.value = true;

    sync
      .fetchSearchQueries()
      .then((search_res) => {
        queries.value = search_res.search_queries;
        getting_data.value = false;
      })
      .catch((err) => {
        //logger.error('Unexpected promise error:', err, err.stack);
        getting_data.value = false;
        error_message.value = 'Problème de communication avec le serveur';
        router.replace('/error/' + err.response?.status);
      });
  }
</script>
