<template>
  <template v-if="!is_leaving_doc">
    <div class="w-full bg-white print:hidden">
      <div class="container mb-4 py-5">
        <app-search-accueil />
      </div>
    </div>

    <app-spinner v-if="getting_data" />
    <div
      v-else
      v-bind:key="current_doc?.id">
      <app-doc-header
        v-bind:nom="current_doc?.id"
        v-bind:meta="current_doc?.meta" />
      <div
        v-if="current_doc?.meta.diffusion === 'public' || privileged"
        class="container">
        <app-doc-avertissement
          v-bind:source="
            current_doc?.meta.avertissement || current_doc?.meta.description
          " />

        <div
          id="content"
          v-if="tabs.length"
          class="mt-8 flex w-full flex-col items-center justify-center">
          <div
            v-scroll-to="vscroll_param('#content')"
            class="inline-flex items-start justify-start gap-7 self-stretch border-b">
            <app-link
              v-for="tab in tabs"
              v-bind:to="{ ...route, name: tab.route_name }"
              v-bind:key="tab.id"
              class="inline-flex cursor-pointer flex-col items-center justify-center border-b-4 border-transparent text-center text-[0.9rem] font-semibold transition min-[370px]:text-lg/tight lg:text-xl lg:leading-10"
              v-bind:class="{
                '!border-[#004787] text-[#004787]':
                  route.name === tab.route_name
              }">
              {{ tab.label }}
            </app-link>
          </div>
        </div>

        <div class="flex w-full flex-wrap gap-4 lg:flex-nowrap lg:gap-16">
          <app-rubrique-scrollto
            v-if="
              (current_tab_index === 0 && has_headers && toc.length > 1) ||
              (toc.length === 1 && toc[0].sub_list)
            "
            ref="filters_overflow"
            class="w-full !px-0"
            v-bind:class="[
              toc_type === 'infocom' ? 'lg:max-w-[30%]' : 'lg:max-w-[20%]',
              { 'v-bind--max-height': !is_mobile_viewport }
            ]"
            v-bind:text_class="'-indent-4 text-base pl-4'"
            label="Sommaire"
            v-bind:list_refs="
              toc?.map((t) => ({
                target_id: t.link?.replace('#', ''),
                label: t.title,
                sub_list: t.sub_list?.map((s) => ({
                  target_id: s.link?.replace('#', ''),
                  label: s.title
                }))
              }))
            " />
          <div
            v-bind:key="current_doc?.id"
            class="h-full min-h-screen w-full flex-grow pt-4 lg:py-8"
            v-bind:class="{ 'lg:max-w-[80%] ': has_headers && toc.length > 1 }">
            <router-view v-slot:default="{ Component }">
              <transition
                name="slide-right"
                mode="out-in">
                <keep-alive>
                  <component v-bind:is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </div>
        </div>
        <div
          class="h-1 w-full"
          v-intersection-observer="onIntersectionObserver"></div>
      </div>
      <app-card-connect
        class="mt-12"
        v-else />
    </div>
  </template>
</template>

<script setup>
  import AppSpinner from '@/js//web-component/app-spinner.vue';
  import AppCardConnect from '@/js/web-component/app-card-connect.vue';
  import AppDocAvertissement from '@/js/web-component/app-doc-avertissement.vue';
  import AppDocHeader from '@/js/web-component/app-doc-header.vue';
  import AppRubriqueScrollto from '@/js/web-component/app-rubrique-scrollto.vue';
  import AppSearchAccueil from '@/js/web-component/app-search-accueil.vue';
  import AppLink from '../../web-component/app-link.vue';

  import sync from '@/js/lib/sync';
  import { useStore } from '@/js/store';
  import { storeToRefs } from 'pinia';
  import {
    computed,
    nextTick,
    onBeforeMount,
    provide,
    ref,
    shallowRef,
    watch
  } from 'vue';

  const store = useStore();
  const { privileged, is_mobile_viewport } = storeToRefs(store);

  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
  const route = useRoute();
  const router = useRouter();

  import { useChangeCase } from '@vueuse/integrations/useChangeCase';

  import { useAlterOfficeconversionHtml } from '@/js/composable/use_alter_office_conversion_html';

  import { useVueScrollTo } from '@/js/composable/vscrollto';
  const { vScrollTo, eventScrollTo, vscroll_param } = useVueScrollTo();

  import { vIntersectionObserver } from '@vueuse/components';
  import { useElementBounding } from '@vueuse/core';

  const TABS_REF = [
    { id: 1, route_name: 'doctrine', label: 'Doctrine' },
    { id: 2, route_name: 'outils-lies', label: 'Outils liés' },
    {
      id: 3,
      route_name: 'documents-lies',
      label: 'Documents Liés'
    }
  ];
  const DEFAULT_TAB = TABS_REF[0];

  const tabs = shallowRef([DEFAULT_TAB]);

  const current_doc = ref(null);
  const current_doc_url = ref(null);
  const current_tab_index = computed(() => {
    return tabs.value.findIndex((tab) => tab.route_name === route.name);
  });

  const has_headers = ref(null);
  const toc = ref([]);

  const error = ref(null);
  const getting_data = ref(false);

  const is_leaving_doc = ref(false);
  onBeforeRouteLeave(() => {
    // on initialise cet variable pour cacher le contenu pendant la transition de route (par exemple sans cela le contenu du doc s'affiche temporairement sur la page d'acceuil au retour en homepage)
    is_leaving_doc.value = true;
  });

  provide('tabs_content', {
    doctrine: computed(() => ({
      attachments: current_doc?.value?.meta?.attachments,
      html_content: current_doc?.value?.html && setHtml(),
      composition: current_doc?.value?.meta?.composition
    })),
    outils: computed(() => ({ ...current_doc?.value.meta?.['liens-outils'] })),
    liens: computed(() => ({
      ...current_doc?.value.meta?.['liens-documents']
    })),
    has_toc: computed(() => toc.value?.length > 1),
    current_doc: computed(() => current_doc?.value)
  });

  watch(route, async (newRoute) => {
    if (newRoute.name === 'doc') await fetchData();
  });
  watch(
    () => has_headers.value,
    () => setToc()
  );

  onBeforeMount(async () => {
    // Fetch the data when the view is created and the data is
    // already being observed.
    await fetchData();
  });

  async function fetchData() {
    getting_data.value = true;

    error.value = null;

    // on reinitialise indicateur de headers pour recalcul TOC si necessaire
    has_headers.value = null;

    // on reinitialise le statut des tabs
    tabs.value = [DEFAULT_TAB];

    try {
      current_doc.value = await sync.fetchDoc(route.params.doc_id);
      current_doc_url.value = '/docs/' + route.params.doc_id;
      if (current_doc.value.meta?.classe !== 'document') {
        if (current_doc.value.meta?.classe === 'outil') {
          tabs.value[0].label = 'Outil';
        }
        if (current_doc.value.meta?.classe === 'ouvrage') {
          tabs.value[0].label = 'Ouvrage';
        }
      }

      if (current_doc.value.meta?.['liens-outils']?.length) {
        tabs.value.push(TABS_REF[1]);
      }

      if (current_doc.value.meta?.['liens-documents']?.length) {
        tabs.value.push(TABS_REF[2]);
        if (current_doc.value.meta?.classe === 'outil') {
          const liens_doc_index = tabs.value.findIndex((tab) => tab.id === 3);
          tabs.value[liens_doc_index].label = 'Doctrine';
        }
      }

      // on met a jour le titre de la fenetre
      document.title = current_doc.value.meta.titre + ' | Documentation | CNCC';

      getting_data.value = false;
    } catch (err) {
      //logger.error('Unexpected promise error:', err, err.stack);
      if (err.response && [401, 404].includes(err.response.status)) {
        router.replace({
          path: '/error/' + err.response.status,
          query: {
            from: '/docs/' + route.params.doc_id
          }
        });
      } else {
        router.replace('/error/' + err.response?.status);
      }
    }
  }

  function setHtml() {
    // le traitement sur le html doit etre effectué une fois mis ne place dans le dom
    // has_headers (et donc calcul TOC) et les events doivent etre dnas $nextTick()
    // sera donc appelé apres le return de cette fonction
    nextTick(() => {
      // prepartion de la TOC
      has_headers.value =
        document.body.querySelectorAll('#content-html h2').length !== 0 ||
        document.body.querySelectorAll('#content-html [class^="level-"]')
          .length !== 0
          ? true
          : false;

      // mise en place de listeners sur les appels de note
      // car on ne peut appeler de directives vuejs sur du v-html
      document.body.querySelectorAll('*[id^="note_"]').forEach((element) => {
        element.addEventListener('click', eventScrollTo);
      });
      document.body
        .querySelectorAll('*[id^="appel_note_"]')
        .forEach((element) => {
          element.addEventListener('click', eventScrollTo);
        });
    });

    const html = useAlterOfficeconversionHtml(current_doc?.value.html);

    return html;
  }

  const toc_type = ref('default');
  function setToc() {
    if (has_headers.value) {
      const default_toc = document.body.querySelectorAll('#content-html h2');
      const infocom_toc = document.body.querySelectorAll(
        '#content-html [class^="level-"]'
      );
      toc_type.value = infocom_toc.length !== 0 ? 'infocom' : 'default';
      const headers = toc_type.value == 'infocom' ? infocom_toc : default_toc;

      if (toc_type.value == 'infocom') {
        for (const header of headers) {
          const id = header.className.split('-').pop();
          if (id && id < 3) {
            const entry = {};
            entry.id = useChangeCase(header.textContent, 'snakeCase').value;
            entry.title = header.textContent;
            entry.link = '#' + entry.id;

            if (id == 1) {
              header.setAttribute('id', entry.id);
              toc.value.push(entry);
            } else if (id == 2) {
              const index = toc.value.length - 1;
              header.setAttribute('id', toc.value[index].id + '_' + entry.id);
              entry.link = toc.value[index].link + '_' + entry.id;

              if ('sub_list' in toc.value[index]) {
                toc.value[index].sub_list.push(entry);
              } else {
                toc.value[index] = Object.assign(toc.value[index], {
                  sub_list: [entry]
                });
              }
            }
          }
        }
      } else if (toc_type.value == 'default') {
        for (const header of headers) {
          const entry = {};
          entry.title = header.textContent;
          entry.link = '#' + header.id;
          toc.value.push(entry);
        }
      }
    } else {
      toc.value = [];
    }
  }

  const isVisible = ref(true);

  function onIntersectionObserver([{ isIntersecting }]) {
    isVisible.value = isIntersecting;
  }
  const filters_overflow = ref(null);
  // eslint-disable-next-line no-shadow
  const { top } = useElementBounding(filters_overflow);
  const offset_height = computed(() => {
    return `95vh - ${top.value}px - ${isVisible.value ? '146px' : '0px'}`;
  });
</script>

<style lang="scss" src="@/css/editorial-content.scss"></style>
<style lang="scss" scoped>
  :deep(.v-bind--max-height) {
    transition: max-height 0.1s ease;
    max-height: calc(v-bind(offset_height));
  }

  :deep(#content-html) {
    @apply flex flex-col gap-4 text-balance break-words px-2;

    a {
      @apply text-blue-600 underline hover:text-blue-900;
    }

    p.officeconversion-avertissement {
      @apply w-full rounded border-2 border-[#d5e0ed] p-5 text-[#004787];
    }

    // h2 {
    //   @apply mt-14 text-[22px] font-bold leading-snug lg:text-[32px] lg:leading-[42px];
    // }

    // p {
    //   @apply text-base;
    // }

    // ul {
    //   @apply ml-8 list-disc;
    //   li {
    //     @apply pl-2;
    //   }
    // }
  }
</style>
